import useActiveProject from 'contexts/project/projectContext';
import { useGetProjectScoutMetrics } from 'lib/scout-metric/scout-metric';
import { useMemo } from 'react';

function useScoutMetrics() {
  const { project } = useActiveProject();
  const { data: scoutMetricsData } = useGetProjectScoutMetrics(project.id!, {
    query: {
      queryKey: ['project', project.id, 'scout-metrics'],
      staleTime: Infinity
    }
  });

  const data = useMemo(() => {
    const items = scoutMetricsData?.objects ?? [];
    const overallMetric =  items.find((metric) => metric.name === 'Overall');
    return {overall: overallMetric}
  }, [scoutMetricsData]);

  return data;
}

export default useScoutMetrics;
