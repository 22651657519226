import { ReportElementTemplateSchema, ReportNestedSchema } from 'lib/model';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Competition, MetricOption, Season, ValueOption } from 'utils/interfaces';
import { competitionToValueOption, seasonToValueOption, stringToValueOption } from 'utils/mappings';
import { getFilteredMatchesOptions } from '../helpers';

interface AggregateOptionsProps {
  report: ReportNestedSchema;
  template: ReportElementTemplateSchema;
  aggregate_by?: boolean;
  aggregate_method?: boolean;
  metrics: MetricOption[];
}

const AggregateOptions = ({
  report,
  template,
  aggregate_by = false,
  aggregate_method = true,
  metrics = []
}: AggregateOptionsProps) => {
  const { control, formState, watch, getValues, setValue } = useFormContext();

  const seasons = watch('seasons') as ValueOption[] | null;
  const competitions = watch('competitions') as ValueOption[] | null;

  const aggregationOptions = useMemo(() => {
    let seasons = new Set<string>(template.attribute_choices!.seasons.map((season: Season) => season.season));
    let competiton_ids = new Set<number>(
      template.attribute_choices!.competitions.map((competition: Competition) => competition.competition_id)
    );
    let recommendedAggregations = new Set<string>(template.attribute_choices!.aggregation_metric);
    for (const metric of metrics) {
      if (metric.seasons_list) {
        seasons = seasons.intersection(new Set(metric.seasons_list));
      }
      if (metric.competition_ids_list) {
        competiton_ids = competiton_ids.intersection(new Set(metric.competition_ids_list));
      }
      if (metric.recommended_aggregations) {
        recommendedAggregations = recommendedAggregations.intersection(new Set(metric.recommended_aggregations));
      }
    }
    return {
      seasons: [...seasons],
      competition_ids: [...competiton_ids],
      recommendedAggregations: [...recommendedAggregations]
    };
  }, [metrics]);

  const seasonOptions: ValueOption[] = useMemo(() => {
    return template
      .attribute_choices!.seasons.filter((season: Season) => aggregationOptions.seasons.includes(season.season))
      .map(seasonToValueOption);
  }, [template.attribute_choices, aggregationOptions.seasons]);

  const competitionOptions: ValueOption[] = useMemo(() => {
    return template
      .attribute_choices!.competitions.filter((competition: Competition) =>
        aggregationOptions.competition_ids.includes(competition.competition_id)
      )
      .map(competitionToValueOption);
  }, [template.attribute_choices, aggregationOptions.competition_ids]);

  const matchOptions = useMemo(() => {
    return getFilteredMatchesOptions(template.attribute_choices!.matches, seasons, competitions);
  }, [seasons, competitions, template.attribute_choices]);

  const methodOptions: ValueOption[] = useMemo(() => {
    return template
      .attribute_choices!.aggregation_metric.filter((method: string) =>
        aggregationOptions.recommendedAggregations.includes(method)
      )
      .map(stringToValueOption);
  }, [template.attribute_choices, aggregationOptions.recommendedAggregations]);

  // Update seasons, competitions, and aggregation method based on selected metric
  useEffect(() => {
    const activeSeasons = getValues('seasons') as ValueOption[] | null;
    const activeCompetitions = getValues('competitions') as ValueOption[] | null;
    const activeMethod = getValues('aggregation_metric') as ValueOption | null;

    if (activeMethod && !aggregationOptions.recommendedAggregations.find((method) => method === activeMethod.id)) {
      setValue('aggregation_metric', null);
    }

    if (activeSeasons) {
      const newSeasons = activeSeasons.filter((season) => aggregationOptions.seasons.includes(season.id as string));
      setValue('seasons', newSeasons);
    }

    if (activeCompetitions) {
      const newCompetitions = activeCompetitions.filter((competition) =>
        aggregationOptions.competition_ids.includes(competition.id as number)
      );
      setValue('competitions', newCompetitions);
    }
  }, [aggregationOptions, getValues, setValue, template.attribute_choices]);

  // Update active matches based on selected seasons and competitions
  useEffect(() => {
    const activeMatches = getValues('matches') as ValueOption[] | null;
    if (activeMatches) {
      const newMatches = activeMatches.filter((match) => matchOptions.some((option) => option.id === match.id));
      setValue('matches', newMatches);
    }
  }, [seasons, competitions, getValues, setValue, template.attribute_choices]);

  return (
    <>
      {(aggregate_by || aggregate_method) && (
        <div className="flex gap-6">
          {aggregate_by && (
            <SelectInput
              formProps={{
                control: control,
                name: 'aggregate_by',
                rules: { required: 'Aggregate type is required!' }
              }}
              label={'Aggregate by'}
              options={template.attribute_choices!.aggregate_by.map(stringToValueOption)}
              error={formState.errors.aggregate_by}
            />
          )}
          {aggregate_method && (
            <SelectInput
              formProps={{
                control: control,
                name: 'aggregation_metric',
                rules: { required: 'Aggregate method is required!' }
              }}
              label={'Aggregate method'}
              options={methodOptions}
              error={formState.errors.aggregation_metric}
            />
          )}
        </div>
      )}
      <div className="flex flex-col">
        <div className="mb-2 text-xs font-semibold">FILTERS</div>
        <SelectInput
          formProps={{
            control: control,
            name: 'seasons'
          }}
          multiple
          label={'Seasons'}
          placeholder="All Seasons"
          options={seasonOptions}
          error={formState.errors.seasons}
        />
      </div>
      {report.report_type !== 'scout' && (
        <SelectInput
          formProps={{
            control: control,
            name: 'competitions'
          }}
          multiple
          label={'Competitions'}
          options={competitionOptions}
          error={formState.errors.competitions}
        />
      )}
      <SelectInput
        formProps={{
          control: control,
          name: 'matches'
        }}
        multiple
        label={'Matches'}
        options={matchOptions}
        error={formState.errors.matches}
      />
    </>
  );
};

export default memo(AggregateOptions);
