import { ListItemNode, ListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { CLEAR_EDITOR_COMMAND, EditorState, LexicalEditor } from 'lexical';
import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { getGetReportLastEditedQueryKey, usePutReportReportIdElementElementId } from 'lib/report/report';
import Plus from 'assets/plus.svg?react';
import { RefObject, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import ElementNoteEditor from './ElementNoteEditor';
import { isEditorStateEmpty } from 'modules/common/Editor/util';

interface ElementNoteProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
  canEdit?: boolean;
}

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: Error) {
  console.error(error);
}

const theme = {
  // Theme styling goes here
  //...
  text: {
    bold: 'font-semibold',
    italic: 'italic'
  }
};

const ElementNote = ({ report, element, canEdit }: ElementNoteProps) => {
  const queryClient = useQueryClient();
  const isEmpty = isEditorStateEmpty(element.user_note);
  const [hideNote, setHideNote] = useState(isEmpty);

  const { mutate: editElement } = usePutReportReportIdElementElementId({
    mutation: {
      onMutate: async ({ data }) => {
        const loadingToastId = toast.info('Saving changes...', { autoClose: false });
        return { loadingToastId };
      },
      onError: (err, res, context) => {
        toast.dismiss(context?.loadingToastId);
        if (err instanceof AxiosError) {
          toast.error(err.response?.data.error);
        }
        toast.error('Your have unsaved changes');
      },
      onSuccess: (err, res, context) => {
        toast.dismiss(context?.loadingToastId);
        toast.success('Your changes have been saved');
        const newNote = res.data.user_note;
        if (isEditorStateEmpty(newNote)) {
          setHideNote(true);
        }
        queryClient.setQueryData(['reports', report.id!, 'elements', element.id], (oldData: ReportElementSchema) => {
          return {
            ...oldData,
            user_note: newNote
          };
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          exact: true,
          queryKey: getGetReportLastEditedQueryKey()
        });
      }
    }
  });

  function onShowNote() {
    setHideNote(false);
  }

  function onCancelChanges(editor: LexicalEditor) {
    if (element.user_note) {
      const parsedEditorState = editor.parseEditorState(element.user_note);
      editor.setEditorState(parsedEditorState);
    } else {
      editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    }

    if (isEmpty) {
      setHideNote(true);
    }
  }

  function onSaveChanges(editor: LexicalEditor) {
    const editorState = editor.getEditorState();
    editElement({
      reportId: report.id!,
      elementId: element.id!,
      data: {
        report_element_template: element.report_element_template!,
        position: element.position,
        attribute_values: element.attribute_values,
        user_note: JSON.stringify(editorState)
      }
    });
  }

  const initialConfig = {
    editorState: element.user_note,
    editable: isEmpty,
    namespace: 'MyEditor',
    nodes: [ListNode, ListItemNode],
    theme,
    onError
  };

  return (
    <div className="flex flex-col gap-4">
      {hideNote ? (
        <>
          {canEdit && (
            <div className="flex cursor-pointer items-center justify-center gap-2" onClick={onShowNote}>
              <Plus className="size-4 fill-brand-800" />
              <span className="text-xs font-medium text-brand-800">Add note</span>
            </div>
          )}
        </>
      ) : (
        <LexicalComposer initialConfig={initialConfig}>
          <ElementNoteEditor
            title={<h3 className="text-sm font-semibold">Note</h3>}
            editablePluginProps={{ onSaveChanges, onCancelChanges }}
            canEdit={canEdit}
          />
        </LexicalComposer>
      )}
    </div>
  );
};

export default ElementNote;
