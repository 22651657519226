import { Match, ScoutNote } from 'lib/model';
import ElementRow from 'modules/reports/reportPage/components/elements/ElementRow';
import PlayerHeader from 'modules/reports/reportPage/components/reportCards/Player/PlayerHeader';
import { NumericFormat } from 'react-number-format';

interface Props {
  scoutNote: ScoutNote;
}

export const ScoutNoteBasicInfoSkeleton = () => {
  return (
    <div className="flex h-fit flex-col gap-6 rounded-xl bg-white p-6 lg:sticky lg:top-[116px] lg:min-w-[400px]">
      <div className="h-28 w-full animate-pulse rounded-md bg-gray-100" />
      {Array.from({ length: 3 }).map((_, i) => (
        <div key={i} className="h-11 w-full animate-pulse rounded-md bg-gray-100" />
      ))}
    </div>
  );
};

const ScoutNoteBasicInfo = ({ scoutNote }: Props) => {
  // In case of missing data show skeleton instead of crashing
  if (!scoutNote) {
    return <ScoutNoteBasicInfoSkeleton />;
  }

  function getMatchName(match: Match) {
    let name = `${match.home_team_name} - ${match.away_team_name}`;
    if (match.home_team_score && match.away_team_score) {
      name += ` (${match.home_team_score} :${match.away_team_score})`;
    }
    return name;
  }

  const player = scoutNote.player!;
  const match = scoutNote.reference;
  const matchText = match && getMatchName(match);

  return (
    <div className="flex h-fit flex-col gap-6 rounded-xl bg-white p-6 lg:sticky lg:top-[116px] lg:min-w-[400px]">
      {player && <PlayerHeader player={player} textSize="lg" iconSize="md" />}
      <div className="flex flex-col gap-2">
        <ElementRow>
          <span className="text-tiny uppercase">ESTIMATED VALUE</span>
          {scoutNote.player_value_estimate ? (
            <NumericFormat displayType="text" thousandSeparator suffix=" €" value={scoutNote.player_value_estimate} />
          ) : (
            '-'
          )}
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">BIRTH DATE</span>
          <span>{player.birth_date ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">HEIGHT</span>
          <span>{player.height ? `${player.height} cm` : '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">WEIGHT</span>
          <span>{player.weight ? `${player.weight} kg` : '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">POSITION</span>
          <span>
            {player.primary_position ?? '-'}

            {player.sub_position_acronym && ` (${player.sub_position_acronym})`}
          </span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">TEAM</span>
          <span>{player.team_name ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">NATIONALITY</span>
          <span>{player.country ?? '-'}</span>
        </ElementRow>
        {match && (
          <ElementRow>
            <span className="text-tiny uppercase">MATCH</span>
            <span>{matchText}</span>
          </ElementRow>
        )}
      </div>
    </div>
  );
};

export default ScoutNoteBasicInfo;
