import { QueryClient } from '@tanstack/react-query';
import LoginPage from 'modules/auth/login/LoginPage';
import EmailConfirmationPage from 'modules/auth/modal-pages/EmailConfirmationPage';
import EmailMismatchPage from 'modules/auth/modal-pages/EmailMismatchPage';
import EmailVerificationPage from 'modules/auth/modal-pages/EmailVerificationPage';
import UnknownOrganizationPage from 'modules/auth/modal-pages/UnknownOrganizationPage';
import PasswordResetPage from 'modules/auth/password-reset/PasswordResetPage';
import RegisterPage from 'modules/auth/register/RegisterPage';
import CustomerSupport from 'modules/customerSupport/CustomerSupport';
import DashboardPage from 'modules/dashboard/DashboardPage';
import ConnectedDataSourcesPage from 'modules/dataSources/connectedDataSources/ConnectedDataSourcesPage';
import DataSourceDetailsPage from 'modules/dataSources/dataSourceDetails/DataSourceDetailsPage';
import DataSourcesPage from 'modules/dataSources/dataSources/DataSourcesPage';
import DataSourcesActivationPage from 'modules/dataSources/dataSourcesActivation/DataSourcesActivationPage';
import DataTablesPage from 'modules/dataTables/DataTablesPage';
import AuthGuard from 'modules/layouts/AuthGuard';
import DataSourcesLayout from 'modules/layouts/dataSourcesLayout/DataSourcesLayout';
import DataTablesLayout from 'modules/layouts/dataTablesLayout/DataTablesLayout';
import ErrorLayout from 'modules/layouts/errorLayout/ErrorLayout';
import HeadlineLayout from 'modules/layouts/headlineLayout/HeadlineLayout';
import ReportsLayout from 'modules/layouts/reportsLayout/ReportsLayout';
import SidebarLayout from 'modules/layouts/sidebarLayout/SidebarLayout';
import PublicReportPage from 'modules/reports/publicReport/PublicReportPage';
import ReportPage from 'modules/reports/reportPage/ReportPage';
import ReportsPage from 'modules/reports/reports/ReportsPage';
import Account from 'modules/settings/components/Account';
import Billing from 'modules/settings/components/Billing';
import BiToolIntegration from 'modules/settings/components/BiToolIntegration';
import Organization from 'modules/settings/components/Organization';
import TierPlans from 'modules/settings/components/TierPlans';
import UserManagement from 'modules/settings/components/UserManagement';
import SettingsPage from 'modules/settings/SettingsPage';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import {
  baseLoader,
  dashboardLoader,
  dataSourceDetailsLoader,
  dataSourcesLoader,
  dataTablesLoader,
  reportLoader,
  reportsLoader
} from 'utils/loaders';
import NewReportPage from './modules/reports/newReport/NewReportPage';
import ScoutNotesLayout from 'modules/layouts/scoutNotesLayout/ScoutNotesLayout';
import ScoutNotesPage from 'modules/scoutNotes/scoutNotes/ScoutNotesPage';
import NewScoutNotePage from 'modules/scoutNotes/newNote/NewScoutNotePage';
import ScoutNotePage from 'modules/scoutNotes/scoutNote/ScoutNotePage';
import ScoutNoteLayout from 'modules/layouts/scoutNotesLayout/ScoutNoteLayout';
import EditScoutNotePage from 'modules/scoutNotes/editScoutNote/EditScoutNotePage';
import ScoutNotesBaseLayout from 'modules/layouts/scoutNotesLayout/ScoutNotesBaseLayout';
import { AdminPage } from 'modules/admin/AdminPage';
import { RedirectUser } from 'modules/auth/RedirectUser';

function generateRouter(queryClient: QueryClient) {
  const routes: RouteObject[] = [
    {
      Component: AuthGuard,
      ErrorBoundary: ErrorLayout,
      children: [
        {
          loader: baseLoader(queryClient),
          Component: SidebarLayout, // move data from initial load to individual loaders to speed up initial load
          children: [
            {
              path: '/',
              Component: RedirectUser
            },
            {
              path: '/dashboard',
              Component: DashboardPage,
              loader: dashboardLoader(queryClient)
            },
            {
              path: '/admin',
              Component: AdminPage
            },
            {
              loader: dataSourcesLoader(queryClient),
              Component: DataSourcesLayout,
              children: [
                {
                  path: '/data-sources/connected',
                  Component: ConnectedDataSourcesPage
                  // loader: dataSourceConfigurationsLoader(queryClient)
                },
                {
                  path: '/data-sources/edit',
                  Component: DataSourcesActivationPage
                },
                {
                  path: '/data-sources/all',
                  Component: DataSourcesPage
                },
                {
                  path: '/data-sources/activation',
                  Component: DataSourcesActivationPage
                },
                {
                  path: '/data-sources/:dataSourceId/details',
                  Component: DataSourceDetailsPage,
                  loader: dataSourceDetailsLoader(queryClient)
                }
              ]
            },
            {
              loader: dataTablesLoader(queryClient),
              Component: DataTablesLayout,
              children: [
                {
                  path: '/data-tables/:dataTableId',
                  Component: DataTablesPage
                }
              ]
            },
            {
              Component: ReportsLayout,
              children: [
                {
                  path: '/reports',
                  Component: ReportsPage,
                  loader: reportsLoader(queryClient)
                }
              ]
            },
            {
              path: '/reports/new',
              Component: NewReportPage
            },
            {
              path: '/reports/:reportId',
              Component: ReportPage,
              loader: reportLoader(queryClient)
            },

            {
              path: '/scout-notes',
              Component: ScoutNotesBaseLayout,
              children: [
                {
                  path: '/scout-notes',
                  Component: ScoutNotesLayout,
                  children: [
                    {
                      index: true,
                      Component: ScoutNotesPage
                    }
                  ]
                },
                {
                  path: '/scout-notes/new',
                  Component: NewScoutNotePage
                },
                {
                  path: '/scout-notes/:noteId',
                  Component: ScoutNoteLayout,
                  children: [
                    { index: true, Component: ScoutNotePage },
                    { path: 'edit', Component: EditScoutNotePage }
                  ]
                }
              ]
            },
            {
              Component: SettingsPage,
              children: [
                {
                  path: '/settings/account',
                  Component: Account
                },
                {
                  path: '/settings/organization',
                  Component: Organization
                },
                {
                  path: '/settings/user-management',
                  Component: UserManagement
                },
                {
                  path: '/settings/bi-tool-integration',
                  Component: BiToolIntegration
                },
                {
                  path: '/settings/billing',
                  Component: Billing
                },
                {
                  path: '/settings/tier-plans',
                  Component: TierPlans
                }
              ]
            },
            {
              path: '/customer-support',
              Component: CustomerSupport
            }
          ]
        }
      ]
    },
    {
      Component: HeadlineLayout,
      children: [
        {
          path: '/register',
          Component: RegisterPage
        },
        {
          path: '/email-verification',
          Component: EmailVerificationPage
        },
        {
          path: '/unknown-organization',
          Component: UnknownOrganizationPage
        },
        {
          path: '/email-confirmation',
          Component: EmailConfirmationPage
        },
        {
          path: '/password-reset',
          Component: PasswordResetPage
        },
        {
          path: '/email-mismatch',
          Component: EmailMismatchPage
        },
        {
          path: '/login',
          Component: LoginPage
        }
      ]
    },
    {
      path: '/reports/:reportId/public',
      Component: PublicReportPage
    }
  ];

  return createBrowserRouter(routes);
}

export const queryClient = new QueryClient({ defaultOptions: { queries: { throwOnError: true } } });
export const router = generateRouter(queryClient);
