import { Field, Input, InputProps, Label } from '@headlessui/react';
import { memo } from 'react';
import { FieldError } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

interface Props extends InputProps {
  label: string;
  disabled?: boolean;
  error?: FieldError | { message: string };
  unit?: string;
  type?: React.HTMLInputTypeAttribute;
  fullWidth?: boolean;
  bigger?: boolean;
}

// Temporary component for NumericFormat
function InputBase({ name, label, disabled, unit, error, type, fullWidth = false, bigger = false, ...rest }: Props) {
  return (
    <Field className={twMerge('relative flex flex-col items-start gap-2', fullWidth && 'w-full')}>
      <Label
        className={twMerge(
          'absolute start-3 top-1.5 z-10 origin-[0] transform text-xs text-gray-500 duration-300 peer-placeholder-shown:translate-y-2.5 peer-placeholder-shown:text-md peer-focus:translate-y-0 peer-focus:text-xs',
          error && 'text-red-600'
        )}
      >
        {label}
      </Label>
      <Input
        name={name}
        className={twMerge(
          'border-px peer block min-h-14 w-full appearance-none rounded-lg border-gray-300 bg-white px-3 pb-1.5 pt-6 text-md focus:outline-none focus:ring-0',
          error && 'border-red-600 text-red-600',
          bigger && 'min-h-28'
        )}
        {...rest}
      />
      <span className={twMerge('absolute inset-y-4 end-3 z-10 text-md', error && 'text-red-600')}>{unit}</span>
    </Field>
  );
}
export default memo(InputBase);
