import { HTMLProps, RefCallback } from 'react';
import { FieldValues } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import SliderFormSimple from './SliderFormSimple';
import { SliderVariantProps } from './interfaces';
import SliderSimple from './SliderSimple';

interface HTMLPropsWithRefCallback<T> extends HTMLProps<T> {
  ref: RefCallback<T>;
}

function renderTrack(
  props: HTMLPropsWithRefCallback<HTMLDivElement>,
  state: {
    index: number;
    value: number;
  }
) {
  const { key, ...rest } = props;
  return (
    <div
      {...rest}
      key={key}
      className={twJoin('inset-y-0 my-auto h-3 rounded-full', state.index === 0 ? 'bg-brand-800' : 'bg-gray-200')}
    />
  );
}

function renderMark(props: HTMLPropsWithRefCallback<HTMLDivElement>) {
  const { key, ...rest } = props;
  return <span key={key} {...rest} className="inset-y-0 my-auto ml-4 h-5 w-0.5 rounded-full bg-brand-800" />;
}

function renderThumb(
  props: HTMLPropsWithRefCallback<HTMLDivElement>,
  state: { index: number; value: number; valueNow: number }
) {
  const { key, ...rest } = props;

  return (
    <div
      key={key}
      {...rest}
      className={twJoin(
        'inset-y-0 my-auto size-8 rounded-full border-2 border-white bg-brand-800 text-xs font-semibold text-white',
        state.index === 0 ? 'left-0' : 'right-0'
      )}
      style={{ ...rest.style }}
    >
      <span className="flex h-full w-full items-center justify-center">{state.value}</span>
    </div>
  );
}

function Slider<T extends FieldValues>(props: SliderVariantProps<T>) {
  if (Array.isArray(props.defaultValue) || Array.isArray(props.value)) {
    // TODO: Refactor/Add implemetation from Slider.tsx
    return null;
  } else {
    let variant: JSX.Element;
    switch (true) {
      case 'formProps' in props:
        variant = (
          <SliderFormSimple renderMark={renderMark} renderThumb={renderThumb} renderTrack={renderTrack} {...props} />
        );
        break;
      default:
        variant = (
          <SliderSimple renderMark={renderMark} renderThumb={renderThumb} renderTrack={renderTrack} {...props} />
        );
    }
    return <div className="flex h-5 w-full flex-col gap-2">{variant}</div>;
  }
}

export default Slider;
