import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table';
import Note from 'assets/draft.svg?react';
import Rating from 'assets/star-full.svg?react';
import { ScoutNote } from 'lib/model';
import CircleImage from 'modules/common/CircleImage';
import DropdownMenuItem from 'modules/common/Dropdown/DropdownMenuItem';
import DropdownWrapper from 'modules/common/Dropdown/DropdownWrapper';
import PaginationWithState, { PaginationProps } from 'modules/common/PaginationWithState';
import RowTable from 'modules/common/Table/RowTable';
import { ScoutNoteDisplayProps } from 'modules/scoutNotes/interfaces';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalizeWord } from 'utils/helpers';

const columnHelper = createColumnHelper<ScoutNote>();

interface Props extends ScoutNoteDisplayProps {
  pagination?: PaginationProps;
}

const ScoutNotesList = ({ data, loading, actions, pagination }: Props) => {
  const navigate = useNavigate();
  const columns = useMemo<ColumnDef<ScoutNote>[]>(() => {
    return [
      columnHelper.display({
        id: 'name',
        cell: (info) => {
          const note = info.row.original;
          return (
            <div className="flex items-center gap-3">
              <div className="flex size-10 items-center justify-center rounded-md bg-gray-50 p-2">
                <Note className="fill-gray-600" />
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-xs font-semibold">{note.note_name}</span>
                <span className="text-xs font-medium text-gray-500">{note.player?.player_name}</span>
              </div>
            </div>
          );
        }
      }),
      columnHelper.accessor('ratings', {
        cell: ({ getValue }) => {
          const ratings = getValue() ?? [];
          const rating = ratings.reduce((acc, rating) => acc + rating.rating!, 0) / ratings.length;
          return (
            <div className="flex items-center gap-2">
              <Rating className="size-5 fill-amber-400" />
              <span className="text-xs font-semibold">{isNaN(rating) ? '-.--' : rating.toFixed(2)}</span>
            </div>
          );
        }
      }),
      columnHelper.display({
        id: 'reference',
        cell: ({ row }) => {
          const data = row.original;
          const reference = data.reference;
          const noteDate = data.note_date ? new Date(data.note_date).toLocaleDateString() : '';

          let noteReference = capitalizeWord(data.type ?? 'Unknown');
          if (!!reference) {
            noteReference = reference.home_team_name + ' - ' + reference.away_team_name;
            // Using == operator to check for nullish constraints while letting 0 through
            // eslint-disable-next-line eqeqeq
            if (reference.home_team_score != null && reference.away_team_score != null) {
              noteReference += ' (' + reference.home_team_score + ':' + reference.away_team_score + ')';
            }
          }

          return (
            <div className="flex items-center gap-2">
              <div>
                <span className="text-tiny font-medium text-gray-500">REFERENCE:</span>
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-xs font-semibold">{noteReference}</span>
                <span className="text-xs font-medium text-gray-500">{noteDate}</span>
              </div>
            </div>
          );
        }
      }),
      columnHelper.display({
        id: 'owner',
        cell: ({ row }) => {
          const owner = row.original.user_full_name!;
          const nameData = owner.split(' ');
          const firstName = nameData[0];
          const lastName = nameData[nameData.length - 1];

          return (
            <div className="flex items-center gap-2">
              <span className="text-tiny font-medium text-gray-500">OWNER:</span>
              <div className="flex items-center gap-2">
                <CircleImage image={undefined} text={firstName.charAt(0) + lastName.charAt(0)} size="size-5" />
                <span className="text-xs font-medium">{row.original.user_full_name}</span>
              </div>
            </div>
          );
        }
      }),
      columnHelper.accessor('date_last_updated', {
        cell: ({ getValue, renderValue }) => (
          <div className="flex items-center gap-2">
            <span className="text-tiny font-medium text-gray-500">EDITED:</span>
            <span className="text-xs font-medium">
              {getValue() ? new Date(getValue() as string).toLocaleDateString() : renderValue()}
            </span>
          </div>
        )
      }),
      // Actions column
      columnHelper.display({
        id: 'actions',
        cell: (info) => {
          const note = info.row.original;
          return (
            <DropdownWrapper>
              <DropdownMenuItem
                onClick={() => {
                  actions?.onDelete(note);
                }}
              >
                Delete
              </DropdownMenuItem>
            </DropdownWrapper>
          );
        }
      })
    ];
  }, []);

  const table = useReactTable({
    data: data ?? [],
    columns,
    state: {
      pagination: pagination?.pagination
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: pagination ? getPaginationRowModel() : undefined,
    renderFallbackValue: '-'
  });

  return (
    <>
      <RowTable
        table={table}
        loading={loading}
        onRowClick={(row) => {
          const note = row.original;
          navigate(`/scout-notes/${note.id}`);
        }}
      />
      {pagination && (
        <PaginationWithState
          pagination={pagination.pagination}
          setPagination={pagination.setPagination}
          rowCount={pagination.rowCount}
        />
      )}
    </>
  );
};

export default ScoutNotesList;
