import { flexRender, Row } from '@tanstack/react-table';
import { defaultPageSize } from 'constants/tableDefaults';
import { Fragment, useCallback } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import TableSkeleton from '../TableSkeleton';
import { BaseTableProps } from './interfaces';

export default function GenericTable<T>({ table, loading, onRowClick, SubComponent }: BaseTableProps<T>) {
  const handleClick = useCallback(
    function handleRowClick(data: Row<T>) {
      return () => {
        if (onRowClick) {
          onRowClick(data);
        }
      };
    },
    [onRowClick]
  );

  if (loading) {
    <TableSkeleton rowNum={table.getState()?.pagination?.pageSize ?? defaultPageSize} />;
  }

  return (
    <>
      <div className="grid max-w-full grid-cols-cards items-center overflow-x-auto">
        <table className="w-full" border={0} cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              {table.getHeaderGroups().flatMap((headerGroup) => {
                return headerGroup.headers.map((header) => {
                  const { column } = header;
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      className={twMerge(
                        'h-12 grow bg-gray-50 text-start',
                        column.getIsPinned() === 'left' && `sticky left-${column.getStart('left')} top-0 z-10`,
                        column.getIsPinned() === 'right' && `sticky right-${column.getStart('right')} top-0 z-10`,
                        column.columnDef.meta as string
                      )}
                      style={{
                        width: header.id === 'actions' ? 56 : header.id === 'checkbox' ? 52 : undefined
                      }}
                    >
                      {header.isPlaceholder ? null : flexRender(column.columnDef.header, header.getContext())}
                    </th>
                  );
                });
              })}
            </tr>
          </thead>
          <tbody className="divide-y divide-divider-gray bg-transparent">
            {table.getRowModel().rows.map((row) => {
              const selected = row.getIsSelected();

              return (
                <Fragment key={row.id}>
                  <tr
                    className={twJoin(
                      'group h-12 whitespace-nowrap text-sm font-medium text-gray-900',
                      selected ? 'bg-brand-50' : 'bg-white hover:bg-gray-50',
                      onRowClick && 'cursor-pointer'
                    )}
                    onClick={handleClick(row)}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const { column } = cell;
                      return (
                        <td
                          className={twJoin(
                            selected ? 'bg-brand-50' : 'bg-white group-hover:bg-gray-50',
                            column.getIsPinned() === 'left' && `sticky left-${column.getStart('left')} z-10`,
                            column.getIsPinned() === 'right' && `sticky right-${column.getStart('right')} z-10`
                          )}
                          style={{
                            width: column.id === 'actions' ? 56 : column.id === 'checkbox' ? 52 : undefined
                          }}
                          key={cell.id}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      );
                    })}
                  </tr>
                  {SubComponent && row.getIsExpanded() && (
                    <tr>
                      {/* 2nd row is a custom 1 cell row */}
                      <td colSpan={row.getVisibleCells().length}>
                        <SubComponent row={row} />
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
