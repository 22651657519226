import Back from 'assets/arrow-left.svg?react';
import Match from 'assets/event-ball.svg?react';
import Team from 'assets/football-shield-outline.svg?react';
import TeamComparison from 'assets/football-shield2-outline.svg?react';
import Player from 'assets/jersey.svg?react';
import PlayerComparison from 'assets/jersey2.svg?react';
import Scout from 'assets/spy.svg?react';
import League from 'assets/trophy.svg?react';
import useAuth from 'contexts/auth/authContext';
import { memo, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateReportButton from './components/CreateReportButton';
import NewLeagueReportDialog from './components/Dialogs/NewLeagueReportDialog';
import NewMatchReportDialog from './components/Dialogs/NewMatchReportDialog';
import NewPlayerComparisonReportDialog from './components/Dialogs/NewPlayerComparisonReportDialog';
import NewPlayerReportDialog from './components/Dialogs/NewPlayerReportDialog';
import NewScoutReportDialog from './components/Dialogs/NewScoutReportDialog/NewScoutReportDialog';
import NewTeamComparisonReportDialog from './components/Dialogs/NewTeamComparisonReportDialog';
import NewTeamReportDialog from './components/Dialogs/NewTeamReportDialog';
import { CreateReportButtonProps } from './interfaces';

const NewReportPage = memo(function ReportsPage() {
  const { isViewer } = useAuth();
  if (isViewer) {
    throw new Error('403 Forbidden');
  }

  const navigate = useNavigate();
  const location = useLocation();

  const reports: CreateReportButtonProps[] = useMemo(() => {
    return [
      {
        title: 'Team Report',
        description: 'Team performance summary and stats',
        Icon: Team,
        Dialog: NewTeamReportDialog
      },
      {
        title: 'Player Report',
        description: 'Player stats and performance overview',
        Icon: Player,
        Dialog: NewPlayerReportDialog,
        open: location.state?.open === 'player'
      },
      {
        title: 'Match Report',
        description: 'Custom visualizations for in-depth match analysis',
        Icon: Match,
        Dialog: NewMatchReportDialog
      },
      {
        title: 'Team Comparison Report',
        description: 'Compare performance of two teams',
        Icon: TeamComparison,
        Dialog: NewTeamComparisonReportDialog
      },
      {
        title: 'Player Comparison Report',
        description: 'Compare performance of two players',
        Icon: PlayerComparison,
        Dialog: NewPlayerComparisonReportDialog
      },
      {
        title: 'Scout Report',
        description: 'Curated player list based on selected criteria',
        Icon: Scout,
        Dialog: NewScoutReportDialog
      },
      {
        title: 'Competition Report',
        description: 'Competition stats and performance overview',
        Icon: League,
        Dialog: NewLeagueReportDialog
      }
    ];
  }, [location.state]);

  function handleBack() {
    navigate(-1);
  }

  return (
    <div className="w-full max-w-5xl">
      <header className="sticky top-0 z-20 flex w-full flex-col gap-6 bg-gray-100 pb-8 pt-10">
        <div className="flex items-center gap-2">
          <Back width={24} height={24} className="cursor-pointer fill-gray-950" onClick={handleBack} />
          <span className="text-lg font-semibold text-gray-950">New Report</span>
        </div>
      </header>
      <div className="flex w-full flex-col items-center">
        <div className="flex w-full flex-col gap-6">
          <span className="text-sm font-semibold text-gray-900">Choose a Report Type</span>
          <div className="grid grid-cols-3 gap-6 rounded-xl bg-white p-10 max-lg:grid-cols-2 max-sm:grid-cols-1">
            {reports.map((report) => (
              <CreateReportButton key={report.title} {...report} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default NewReportPage;
