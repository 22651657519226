import { ScoutNote } from 'lib/model';
import DeleteNoteDialog from 'modules/scoutNotes/components/DeleteNoteDialog';
import { ScoutDisplayType } from 'modules/scoutNotes/interfaces';
import { useState } from 'react';
import ScoutNotesGrid from './display/ScoutNotesGrid';
import ScoutNotesList from './display/ScoutNotesList';

interface Props {
  display: ScoutDisplayType;
  data: ScoutNote[];
  loading: boolean;
}

enum DialogType {
  NONE,
  DELETE
}

const ScoutNotes = ({ display, data, loading }: Props) => {
  const [activeNote, setActiveNote] = useState<ScoutNote | null>(null);
  const [showDialog, setShowDialog] = useState<DialogType>(DialogType.NONE);

  function showNoteDelete(note: ScoutNote) {
    setActiveNote(note);
    setShowDialog(DialogType.DELETE);
  }

  function handleDialogClose() {
    setActiveNote(null);
    setShowDialog(DialogType.NONE);
  }

  let displayComponent = null;
  switch (display) {
    case 'list':
      displayComponent = <ScoutNotesList data={data} loading={loading} actions={{ onDelete: showNoteDelete }} />;
      break;
    case 'grid':
      displayComponent = <ScoutNotesGrid data={data} loading={loading} actions={{ onDelete: showNoteDelete }} />;
      break;
  }

  return (
    <>
      <DeleteNoteDialog note={activeNote} open={showDialog === DialogType.DELETE} closeDialog={handleDialogClose} />
      {displayComponent}
    </>
  );
};

export default ScoutNotes;
