import { memo, useCallback } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import ReactSlider from 'react-slider';
import ErrorMessage from '../ErrorMessage';
import { SimpleFormSliderProps } from './interfaces';

function SliderFormSimple<T extends FieldValues>({
  error,
  min = 0,
  max = 100,
  formProps,
  ...sliderProps
}: SimpleFormSliderProps<T>) {
  const {
    field: { onChange: fieldOnChange, value }
  } = useController<T>(formProps);

  const handleSliderChange = useCallback(
    function handleSliderChange(value: number) {
      fieldOnChange(value);
    },
    [fieldOnChange]
  );

  return (
    <>
      <ReactSlider
        className="h-8"
        value={value}
        min={min}
        max={max}
        pearling
        onChange={handleSliderChange}
        {...sliderProps}
      />
      {error && <ErrorMessage error={error} />}
    </>
  );
}

export default SliderFormSimple;
