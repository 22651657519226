import { $getRoot, EditorState } from 'lexical';
import { ScoutMetric } from 'lib/model';
import { ScoutNoteFormValues } from 'modules/scoutNotes/interfaces';
import { Control, FieldError, UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import ScoutFormRatingNote from './ScoutFormRatingNote';
import Slider from 'modules/common/Form/Slider/Slider';
import { SCOUT_RATING_MAX } from 'modules/scoutNotes/constants';

interface Props {
  control: Control<ScoutNoteFormValues>;
  setValue: UseFormSetValue<ScoutNoteFormValues>;
  getValues: UseFormGetValues<ScoutNoteFormValues>;
  index: number;
  error?: FieldError;
  metric: ScoutMetric;
  overallMetric?: ScoutMetric;
}

export const ScoutFormRatingSkeleton = () => {
  return (
    <div className="flex flex-col gap-3">
      <div className="h-5 w-full animate-pulse bg-gray-200" />
      <div className="h-2 w-full animate-pulse bg-gray-200" />
      <div className="h-5 w-full animate-pulse bg-gray-200" />
      <div className="h-8 w-full animate-pulse bg-gray-200" />
    </div>
  );
};

function ScoutFormRating({ control, error, index, metric, setValue, getValues, overallMetric }: Props) {
  const disabled = metric.name === 'Overall';
  const rating = getValues(`ratings.${metric.id}`);
  if (!rating || !rating.scout_metric) {
    setValue(`ratings.${metric.id}.scout_metric`, metric.id);
  }

  function onNoteChange(editorState: EditorState) {
    const plainText = editorState.read(() => $getRoot().getTextContent());
    setValue(`ratings.${metric.id}.formatted_text`, JSON.stringify(editorState));
    setValue(`ratings.${metric.id}.plain_text`, plainText);
  }

  function onRatingChange(value: number) {
    setValue(`ratings.${metric.id}.rating`, value);

    const ratings = getValues('ratings');
    const ratingsList = Object.values(ratings) ?? [];
    const averageRating =
      ratingsList.reduce((acc, rating) => {
        if (rating.scout_metric === metric.id) {
          // If the form value hasn't been updated yet, use the current value
          acc += value;
        } else if (rating.scout_metric !== overallMetric?.id) {
          acc += rating.rating ?? 0;
        }
        return acc;
      }, 0) /
      (ratingsList.length - 1);
    setValue(`ratings.${overallMetric?.id}.rating`, averageRating);
  }

  return (
    <div className="flex flex-col gap-3">
      <h4 className="text-md font-semibold">{metric.name}</h4>
      <div className="text-sm text-gray-500">{metric.description}</div>
      <Slider
        formProps={{ control: control, name: `ratings.${metric.id}.rating` }}
        step={metric.name === 'Overall' ? 0.1 : 0.5}
        onChange={onRatingChange}
        error={error}
        max={SCOUT_RATING_MAX}
        marks={!disabled}
        disabled={disabled}
      />
      <ScoutFormRatingNote onChange={onNoteChange} rating={rating} editable />
    </div>
  );
}

export default ScoutFormRating;
