import { useQueryClient } from '@tanstack/react-query';
import ArrowLeft from 'assets/arrow-left.svg?react';
import Info from 'assets/info.svg?react';
import Plus from 'assets/plus.svg?react';
import useAuth from 'contexts/auth/authContext';
import { useSelectedDataSources } from 'contexts/selectedDataSourcesContext';
import { DatasourcesSchema } from 'lib/model';
import Button from 'modules/common/Button';
import { memo, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AddAnotherDataSourceDialog from './components/AddAnotherDataSourceDialog';
import ConnectDataSourcesDialog from './components/ConnectDataSourcesDialog';
import DataSourceActivationCard from './components/DataSourceActivationCard';

const DataSourcesActivationPage = memo(function DataSourcesActivationPage() {
  const { isOwner, isAdmin } = useAuth();
  if (!isOwner && !isAdmin) {
    throw new Error('403 Forbidden');
  }
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const dataSources = queryClient.getQueryData<DatasourcesSchema>(['dataSources'])!;
  const { selectedDataSources, resetSelectedDataSources, isLoading } = useSelectedDataSources();
  const [connectDataSourcesOpen, setConnectDataSourcesOpen] = useState(false);
  const [addAnotherDataSourceOpen, setAddAnotherDataSourceOpen] = useState(false);
  const canConnectDataSources = selectedDataSources.filter((x) => !x.state.configured).length === 0;
  const canAddMoreDataSources = dataSources ? selectedDataSources.length !== dataSources!.objects!.length : false;

  function connectDataSources() {
    setConnectDataSourcesOpen(true);
  }

  function addAnotherDataSource() {
    setAddAnotherDataSourceOpen(true);
  }

  window.onpopstate = () => {
    resetSelectedDataSources();
  };

  useEffect(() => {
    if (!dataSources || (!isLoading && selectedDataSources.length === 0)) {
      navigate('/data-sources/all');
    }
  });

  const isEditing = pathname === '/data-sources/edit';
  const previousLocation = isEditing ? '/data-sources/connected' : '/data-sources/all';

  return (
    <div className="flex w-full max-w-screen-sm flex-col gap-8 pt-10">
      <header className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <Link to={previousLocation} onClick={resetSelectedDataSources}>
            <ArrowLeft width={16} height={16} className="fill-gray-700" />
          </Link>
          <span className="text-xl font-semibold">{isEditing ? 'Edit' : 'Connect'} Data Sources</span>
        </div>
        {/* TODO: Implement this when assistant is working <span className="cursor-pointer text-xs font-medium text-brand-800">
          Set back to recommended Data Source set
        </span> */}
      </header>
      {selectedDataSources.length === 1 && (
        <section className="flex max-w-screen-sm items-center gap-6 rounded-xl bg-white p-10">
          <Info width={128} height={64} className="fill-gray-300" />
          <p className="text-md font-medium">
            Its better to <b>connect multiple Data Sources</b> at once because Data Synchronization may take up to
            <b> 24 hours</b> regardless of the number of sources connected.
          </p>
        </section>
      )}
      <section className="flex max-w-screen-sm flex-col gap-10 rounded-xl">
        {selectedDataSources.map((x) => {
          return <DataSourceActivationCard {...x} key={x.dataSource.id} />;
        })}
        {canAddMoreDataSources && (
          <span onClick={addAnotherDataSource} className="flex cursor-pointer gap-3 self-center">
            <Plus width={20} height={20} className="fill-brand-800" />
            <span className="text-sm font-medium text-brand-800">Add another Data Source</span>
          </span>
        )}
        <Button
          variant="primary"
          size="xl"
          isFullWidth={true}
          onClick={connectDataSources}
          disabled={!canConnectDataSources}
        >
          Connect Data Sources
        </Button>
        <ConnectDataSourcesDialog open={connectDataSourcesOpen} setOpen={setConnectDataSourcesOpen} />
        <AddAnotherDataSourceDialog open={addAnotherDataSourceOpen} setOpen={setAddAnotherDataSourceOpen} />
      </section>
    </div>
  );
});

export default DataSourcesActivationPage;
