import { PlayerScoutNotes } from 'lib/model';
import ScoutNotesPlayerList from './display/ScoutNotesPlayerList';

interface Props {
  data: PlayerScoutNotes[];
  loading: boolean;
}

const ScoutNotesByPlayer = ({ data,  loading }: Props) => {
  return <ScoutNotesPlayerList data={data} loading={loading} />;
};

export default ScoutNotesByPlayer;
