import useActiveProject from 'contexts/project/projectContext';
import { ScoutNote } from 'lib/model';
import { useGetProjectScoutNote } from 'lib/scout-note/scout-note';
import { Outlet, useParams } from 'react-router-dom';

export interface ScoutNoteLayoutContext {
  scoutNote?: ScoutNote;
  loading: boolean;
}

const ScoutNoteLayout = () => {
  const { project } = useActiveProject();
  const { noteId } = useParams();

  const { data: scoutNote, isFetching } = useGetProjectScoutNote(project.id!, noteId!, {
    query: {
      queryKey: ['project', project.id, 'scout-note', noteId],
      staleTime: Infinity,
      enabled: !!noteId && !!project.id
    }
  });

  return <Outlet context={{ scoutNote: scoutNote, loading: isFetching } as ScoutNoteLayoutContext} />;
};

export default ScoutNoteLayout;
