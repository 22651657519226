import Pause from 'assets/pause.svg?react';
import Play from 'assets/play-full.svg?react';
import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { snakeCaseToWords } from 'utils/helpers';
import { EventMapEvent } from '../../dialogs/elementForms/interfaces';
import EventMapChart from './EventMapChart';
import { EventMapFrame } from './interfaces';

interface EventMapElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

interface EventMapData {
  eventmap_data: EventMapFrame[];
}

const frameDuration = 250;

const EventMapElement = memo(function EventMapElement({ report, element }: EventMapElementProps) {
  const event = (element?.attribute_values?.events[0] as EventMapEvent) ?? null;
  const interval = useRef(0);
  const [tick, setTick] = useState<number>(0);
  const [playing, setPlaying] = useState<boolean>(false);
  const { eventmap_data: frames } = element.entity_data as EventMapData;

  useEffect(
    function PlayVideo() {
      if (playing) {
        interval.current = window.setInterval(() => {
          setTick((prevTick) => {
            const nextTick = prevTick + 1;
            if (nextTick > frames.length - 1) {
              clearInterval(interval.current);
              setPlaying(false);
              return 0; // reset tick to 0
            }
            return nextTick;
          });
        }, frameDuration);
      }

      return () => {
        clearInterval(interval.current);
      };
    },
    [frames, playing]
  );

  useEffect(() => {
    frames.forEach((frame) => frame.players_data.sort((a, b) => a.player_id - b.player_id));
  }, [frames]);

  const togglePlaying = useCallback(
    function togglePlaying() {
      setPlaying((state) => !state);
      if (playing) {
        clearInterval(interval.current);
      }
    },
    [playing]
  );

  // each tick represents 5 seconds of video
  const videoLines = useMemo(() => {
    const tickNum = frames.length / ((1000 / frameDuration) * 5);
    const tickNumRounded = Math.floor(tickNum);
    const ticks = [];
    for (let i = 0; i < tickNumRounded; i++) {
      ticks.push(
        <div className="absolute z-[15] h-1.5 w-px bg-gray-400" style={{ left: `${((i + 1) / tickNum) * 100}%` }} />
      );
    }
    return ticks;
  }, [frames.length]);

  return (
    <div className="flex w-full justify-center">
      <div className="flex w-full max-w-screen-lg flex-col gap-3 rounded-t-xl">
        {event && (
          <div className="flex flex-wrap items-center gap-2 bg-transparent">
            <div className="text-xs font-semibold">{snakeCaseToWords(event.event_type)}</div>
            <div className="text-xs font-semibold">({event.minutes_elapsed}')</div>
            {event.player_name && (
              <>
                <div className="h-3.5 w-px bg-gray-300" />
                <div className="text-xs font-semibold">{event.player_name}</div>
              </>
            )}
            {event.team_name && (
              <>
                <div className="h-3.5 w-px bg-gray-300" />
                <div className="text-xs font-semibold">{event.team_name}</div>
              </>
            )}
          </div>
        )}
        <EventMapChart frames={frames} tick={tick} report={report} />
        <div className="flex items-center gap-4 bg-gray-50 px-3 py-3">
          {!playing ? (
            <Play onClick={togglePlaying} className="size-7 fill-brand-800" />
          ) : (
            <Pause onClick={togglePlaying} className="size-7 fill-brand-800" />
          )}
          <div className="relative h-1.5 w-full rounded-badge bg-gray-200">
            {videoLines}
            <div
              className="absolute left-0 z-10 h-1.5 rounded-badge bg-brand-800 transition-all duration-200"
              style={{ width: `${(tick / (frames.length - 1)) * 100}%` }}
            />
            <div
              className="absolute z-20 size-5 -translate-x-1/2 translate-y-[-7px] rounded-full border-2 border-white bg-brand-800 shadow-card transition-all duration-200"
              style={{ left: `${(tick / (frames.length - 1)) * 100}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default EventMapElement;
