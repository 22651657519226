/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';

export function parseErrorMessage(error: any, defaultMessage = 'An unknown error occurred. Please try again later.') {
  let message = defaultMessage;
  if (error instanceof AxiosError) {
    const data = error.response?.data;
    if (data.error) {
      message = data.error;
    } else if (data._schema) {
      const errors = data._schema as string[];
      message = errors.join(', ');
    }
  }
  return message;
}
