/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import getProjectScoutMetricsMutator from '../../api/axios';
import type { ScoutMetricGetSchema } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of all scout metrics
 */
export const getProjectScoutMetrics = (
  projectId: string,
  options?: SecondParameter<typeof getProjectScoutMetricsMutator>,
  signal?: AbortSignal
) => {
  return getProjectScoutMetricsMutator<ScoutMetricGetSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/scout/metrics`, method: 'GET', signal },
    options
  );
};

export const getGetProjectScoutMetricsQueryKey = (projectId: string) => {
  return [`/project/${projectId}/scout/metrics`] as const;
};

export const getGetProjectScoutMetricsQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectScoutMetrics>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectScoutMetrics>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectScoutMetricsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectScoutMetricsQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectScoutMetrics>>> = ({ signal }) =>
    getProjectScoutMetrics(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectScoutMetrics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectScoutMetricsQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectScoutMetrics>>>;
export type GetProjectScoutMetricsQueryError = unknown;

/**
 * @summary Returns a list of all scout metrics
 */
export const useGetProjectScoutMetrics = <TData = Awaited<ReturnType<typeof getProjectScoutMetrics>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectScoutMetrics>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectScoutMetricsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectScoutMetricsQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
