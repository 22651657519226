import Clear from 'assets/close-circle.svg?react';
import Filter from 'assets/filter.svg?react';
import List from 'assets/list.svg?react';
import Plus from 'assets/plus.svg?react';
import Grid from 'assets/tile-view.svg?react';
import useAuth from 'contexts/auth/authContext';
import useScroll from 'hooks/useScroll';
import Button from 'modules/common/Button';
import RadioIconButtons from 'modules/common/RadioIconButtons';
import SearchParamButton from 'modules/common/SearchParamButton';
import { CREATE_SCOUT_NOTE_ROLES } from 'modules/scoutNotes/constants';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { IconButton, SearchParamRoute } from 'utils/interfaces';
import ScoutNotesFilter from './ScoutNotesFilter';
import Jersey from 'assets/jersey.svg?react';

const displayButtons: IconButton[] = [
  {
    Icon: Jersey,
    id: 'player'
  },
  {
    Icon: List,
    id: 'list'
  },
  {
    Icon: Grid,
    id: 'grid'
  }
];

const ownershipRoutes: SearchParamRoute[] = [
  {
    name: 'ALL',
    key: 'ownership',
    value: null
  },
  {
    name: 'MY NOTES',
    key: 'ownership',
    value: 'my_reports'
  }
];

interface ScoutNotesPageHeaderProps {
  display: string;
  setDisplay: React.Dispatch<React.SetStateAction<string>>;
}

const ScoutNotesHeader = ({ display, setDisplay }: ScoutNotesPageHeaderProps) => {
  const { user, userRole } = useAuth();
  const navigate = useNavigate();
  const { direction, y } = useScroll();
  const [expanded, setExpanded] = useState<boolean>(false);
  useEffect(() => {
    if (y <= 50) {
      setExpanded(false);
    } else if (direction === 'down') {
      setExpanded(false);
    } else if (direction === 'up') {
      setExpanded(true);
    }
  }, [direction, y]);

  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = searchParams.get('filters') ?? undefined;

  const canCreateNote = user.is_admin || CREATE_SCOUT_NOTE_ROLES.includes(userRole!); 

  function showDialog() {
    setOpen(true);
  }

  function clearFilters(e: React.MouseEvent) {
    e.stopPropagation();
    if (searchParams.get('filters')) {
      searchParams.delete('filters');
    }
    setSearchParams(searchParams);
  }

  const handleNewReport = useCallback(
    function handleNewReport() {
      navigate('/scout-notes/new');
    },
    [navigate]
  );

  return (
    <>
      <ScoutNotesFilter open={open} setOpen={setOpen} />
      <header className="sticky top-0 z-20 flex w-full flex-col bg-gray-100">
        <span className="pb-8 pt-10 text-lg font-semibold text-gray-950">Scout Notes</span>
        <div className={twJoin('flex flex-col', expanded && 'divide-y divide-gray-200')}>
          <div
            className={twJoin(
              'flex w-full flex-wrap justify-between gap-6 rounded-t-xl bg-white p-4',
              !expanded && 'rounded-b-xl'
            )}
          >
            <div className="flex flex-wrap items-center gap-2">
              {/* TODO: Currently has no functionality */}
              {/* 
              {ownershipRoutes.map((route) => (
                <SearchParamButton key={route.value} route={route} size="xs" />
              ))}
               */}
            </div>
            <div className="flex items-center gap-6">
              <RadioIconButtons selected={display} setSelected={setDisplay} options={displayButtons} />
              <Button variant="secondary" size="sm" isFullWidth={false} onClick={showDialog}>
                <Filter width={16} height={16} />
                <span>Filter</span>
                {Boolean(filters) && <Clear width={20} height={20} onClick={clearFilters} />}
              </Button>
              {canCreateNote && (
                <Button isFullWidth={false} onClick={handleNewReport}>
                  <Plus width={18} height={18} />
                  <span>New Note</span>
                </Button>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default ScoutNotesHeader;
