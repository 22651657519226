/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { default as getProjectScoutsMutator, default as getScoutNotesPlayersMutator } from '../../api/axios';
import type { PlayersSchema, ScoutsSchema } from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of all players who have scout notes
 */
export const getScoutNotesPlayers = (
  projectId: string,
  options?: SecondParameter<typeof getScoutNotesPlayersMutator>,
  signal?: AbortSignal
) => {
  return getScoutNotesPlayersMutator<PlayersSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/scout/players`, method: 'GET', signal },
    options
  );
};

export const getGetScoutNotesPlayersQueryKey = (projectId: string) => {
  return [`/project/${projectId}/scout/players`] as const;
};

export const getGetScoutNotesPlayersQueryOptions = <
  TData = Awaited<ReturnType<typeof getScoutNotesPlayers>>,
  TError = unknown
>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getScoutNotesPlayers>>, TError, TData>>;
    request?: SecondParameter<typeof getScoutNotesPlayersMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetScoutNotesPlayersQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getScoutNotesPlayers>>> = ({ signal }) =>
    getScoutNotesPlayers(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getScoutNotesPlayers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetScoutNotesPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof getScoutNotesPlayers>>>;
export type GetScoutNotesPlayersQueryError = unknown;

/**
 * @summary Returns a list of all players who have scout notes
 */
export const useGetScoutNotesPlayers = <TData = Awaited<ReturnType<typeof getScoutNotesPlayers>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getScoutNotesPlayers>>, TError, TData>>;
    request?: SecondParameter<typeof getScoutNotesPlayersMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetScoutNotesPlayersQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Returns a list of all scouts
 */
export const getProjectScouts = (
  projectId: string,
  options?: SecondParameter<typeof getProjectScoutsMutator>,
  signal?: AbortSignal
) => {
  return getProjectScoutsMutator<ScoutsSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/scout/scouts`, method: 'GET', signal },
    options
  );
};

export const getGetProjectScoutsQueryKey = (projectId: string) => {
  return [`/project/${projectId}/scout/scouts`] as const;
};

export const getGetProjectScoutsQueryOptions = <TData = Awaited<ReturnType<typeof getProjectScouts>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectScouts>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectScoutsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectScoutsQueryKey(projectId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectScouts>>> = ({ signal }) =>
    getProjectScouts(projectId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectScouts>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectScoutsQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectScouts>>>;
export type GetProjectScoutsQueryError = unknown;

/**
 * @summary Returns a list of all scouts
 */
export const useGetProjectScouts = <TData = Awaited<ReturnType<typeof getProjectScouts>>, TError = unknown>(
  projectId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectScouts>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectScoutsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectScoutsQueryOptions(projectId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
