import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
enableMocking().then(() => {
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
});

async function enableMocking() {
  if (import.meta.env.VITE_MOCKING === 'enabled') {
    const { worker } = await import('api/browser');
    return worker.start();
  }
  return;
}
