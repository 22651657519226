import { memo} from 'react'
import ReactSlider from 'react-slider';
import { SimpleSliderProps } from './interfaces';


function SimpleSlider({ min = 0, max = 100, ...sliderProps }:SimpleSliderProps) {

  return (
    <>
      <ReactSlider className="h-8" min={min} max={max} {...sliderProps} />
      {/* {error && <ErrorMessage error={error} />} */}
    </>
  );
}



export default memo(SimpleSlider);
