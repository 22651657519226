import useActiveProject from 'contexts/project/projectContext';
import { useGetProjectScouts, useGetScoutNotesPlayers } from 'lib/scouts/scouts';
import Button from 'modules/common/Button';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import SideDialogBase from 'modules/common/Dialog/SideDialogBase';
import Input from 'modules/common/Form/Input';
import SelectInput from 'modules/common/Form/Select/SelectInputVariants';
import { memo, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { DialogProps, ValueOption } from 'utils/interfaces';
import { playerToAutocompleteOption, scoutToValueOption, stringToValueOption } from 'utils/mappings';

interface Filter {
  scouts?: ValueOption[];
  player_id?: ValueOption[];
  league_id?: ValueOption[];
  team_id?: ValueOption[];
  match_id?: ValueOption[];
  note_type?: ValueOption[];
  start_date?: string;
  end_date?: string;
}

const EMPTY_STATE: Filter = {
  scouts: [],
  player_id: [],
  league_id: [],
  team_id: [],
  match_id: [],
  note_type: [],
  start_date: '',
  end_date: ''
};

type ParsedFilter = { [K in keyof Filter]?: (string | number)[] | string };

function ScoutNotesFilter({ open, setOpen }: DialogProps) {
  const { project } = useActiveProject();
  const [searchParams, setSearchParams] = useSearchParams();
  // TODO: Load filter state from url

  const { control, handleSubmit, reset, register } = useForm<Filter>({
    defaultValues: EMPTY_STATE
  });

  function parseFilters(filters: Filter): ParsedFilter {
    const parsed: ParsedFilter = {};
    for (const [key, value] of Object.entries(filters)) {
      switch (key) {
        case 'start_date':
        case 'end_date':
          if (value && value !== '') {
            parsed[key as keyof Filter] = value;
          }
          break;
        default:
          if (value && Array.isArray(value) && value.length > 0) {
            parsed[key as keyof Filter] = value.map((item) => item.id);
          }
      }
    }
    return parsed;
  }

  function onFilterChange(filter: ParsedFilter) {
    searchParams.set('filters', JSON.stringify(filter));
    setSearchParams(searchParams);
    setOpen(false);
  }

  function filterNotes(formData: Filter) {
    const newFilters = parseFilters(formData);
    onFilterChange(newFilters);
  }

  function onCancel() {
    setOpen(false);
  }

  function clearFilters() {
    reset(EMPTY_STATE);
    if (searchParams.get('filters')) {
      searchParams.delete('filters');
      setSearchParams(searchParams);
    }
  }

  // ====================================
  //            Data fetching
  // ====================================
  const { data: scoutsData, isFetching: isFetchingScouts } = useGetProjectScouts(project.id!, {
    query: {
      queryKey: ['project', project.id, 'scouts'],
      enabled: !!project.id,
      staleTime: Infinity
    }
  });
  const scouts = useMemo(() => scoutsData?.objects ?? [], [scoutsData]);

  const { data: playerData, isFetching: isFetchingPlayers } = useGetScoutNotesPlayers(project.id!, {
    query: {
      queryKey: ['project', project.id, 'scout-notes', 'players'],
      enabled: !!project.id,
      staleTime: Infinity
    }
  });

  const playersOptions = useMemo(() => {
    const allPlayers = playerData?.objects ?? [];
    return allPlayers.map(playerToAutocompleteOption);
  }, [playerData]);

  // Sync filter state with search params
  useEffect(() => {
    const queryFilters = searchParams.get('filters');
    if (queryFilters) {
      const parsedFilters: ParsedFilter = JSON.parse(queryFilters);
      const newFilter: Filter = {};
      for (const [key, values] of Object.entries(parsedFilters) as [keyof Filter, (string | number)[] | string][]) {
        switch (key) {
          case 'note_type':
            newFilter[key] = (values as string[]).map(stringToValueOption);
            break;
          case 'start_date':
          case 'end_date':
            newFilter[key] = values as string;
            break;
          case 'player_id':
            newFilter[key] = playersOptions.filter((player) => (values as number[]).includes(player.id as number));
            break;
          case 'scouts':
            newFilter[key] = scouts
              .filter((scout) => (values as string[]).includes(scout.email as string))
              .map(scoutToValueOption);
            break;
          case 'match_id':
            // Not yet implemented
            break;
          case 'team_id':
            // Not yet implemented
            break;
          case 'league_id':
            // Not yet implemented
            break;
        }
      }
      reset(newFilter, { keepValues: false, keepDefaultValues: false });
    } else {
      reset(EMPTY_STATE);
    }
  }, [searchParams, playersOptions, scouts, reset]);

  return (
    <SideDialogBase title="Filter" open={open} onCancel={onCancel}>
      <DialogContent>
        {/* TODO: Improve sizing on various screens */}
        <form
          className="flex w-full min-w-[400px] max-w-[500px] flex-col gap-6"
          id="filter-form"
          onSubmit={handleSubmit(filterNotes)}
        >
          <SelectInput
            variant="form"
            label="Player"
            placeholder="Player name / team / country"
            options={playersOptions}
            loading={isFetchingPlayers}
            searchable
            multiple
            formProps={{
              control: control,
              name: 'player_id'
            }}
          />
          <SelectInput
            variant="form"
            label="Scout"
            multiple
            searchable
            loading={isFetchingScouts}
            options={scouts.map(scoutToValueOption)}
            formProps={{
              control: control,
              name: 'scouts'
            }}
          />
          <SelectInput
            variant="form"
            label="Type"
            multiple
            options={['match', 'training', 'other'].map(stringToValueOption)}
            formProps={{
              control: control,
              name: 'note_type'
            }}
          />
          <div className="flex items-center gap-6">
            <Input type="date" registerReturn={register('start_date')} label="Start Date" fullWidth />
            <span className="text-gray-950">-</span>
            <Input type="date" registerReturn={register('end_date')} label="End Date" fullWidth />
          </div>
        </form>
      </DialogContent>
      <DialogFooter>
        <div className="flex w-full items-center gap-8">
          <span
            className="cursor-pointer whitespace-nowrap text-md font-semibold text-brand-800"
            onClick={clearFilters}
          >
            Clear all
          </span>

          <Button size="xl" form="filter-form" isSubmitButton>
            Filter
          </Button>
        </div>
      </DialogFooter>
    </SideDialogBase>
  );
}

export default memo(ScoutNotesFilter);
