import { flexRender, Row } from '@tanstack/react-table';
import { Fragment, useCallback } from 'react';
import { twJoin } from 'tailwind-merge';
import RowTableSkeleton from './RowTableSkeleton';
import { BaseTableProps } from './interfaces';

const RowTable = <T,>({ table, onRowClick, loading, SubComponent }: BaseTableProps<T>) => {
  const handleClick = useCallback(
    function handleRowClick(data: Row<T>) {
      return () => {
        if (onRowClick) {
          onRowClick(data);
        }
      };
    },
    [onRowClick]
  );

  if (loading) {
    return <RowTableSkeleton />;
  }

  return (
    <div className="grid max-w-full grid-cols-cards items-center overflow-x-auto">
      <table className="w-full border-separate border-spacing-y-3" border={0} cellPadding="0" cellSpacing="0">
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Fragment key={row.id}>
                <tr
                  className={twJoin('group whitespace-nowrap', onRowClick && 'cursor-pointer')}
                  onClick={handleClick(row)}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        className={twJoin(
                          'bg-white px-2.5 py-4 first:rounded-l-xl first:pl-4 last:rounded-r-xl last:pr-4',
                          row.getIsExpanded() && 'first:rounded-bl-none last:rounded-br-none'
                        )}
                        style={{
                          width: cell.column.id === 'actions' ? 56 : undefined
                        }}
                        key={cell.id}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
                {SubComponent && row.getIsExpanded() && (
                  <tr>
                    <td colSpan={row.getVisibleCells().length}>{<SubComponent row={row} />}</td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RowTable;
