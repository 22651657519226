import RedCard from 'assets/card-red.svg?react';
import YellowCard from 'assets/card-yellow.svg?react';
import Change from 'assets/change.svg?react';
import Goal from 'assets/event-ball.svg?react';
import { memo, useCallback } from 'react';
import ElementRow from '../../ElementRow';
import Player from '../../Player';
import { LineupPlayer } from '../interfaces';

interface LineupTableRowProps extends LineupPlayer {
  team_color: string;
}

const LineupTableRow = memo(function LineupTableRow({
  player_name,
  shirt_number,
  sub_position_acronym,
  cards_yellow,
  cards_red,
  goals_total,
  team_color,
  minute_subbed_in,
  minute_subbed_out,
  player_started
}: LineupTableRowProps) {
  const goalEvents = useCallback(
    function goalEvents() {
      const goals = [];
      for (let i = 0; i < Number(goals_total); i++) {
        goals.push(<Goal key={i} className="-ml-1 size-4 rounded-full border border-white bg-white fill-black" />);
      }
      return <div className="flex rounded-badge bg-white p-0.5">{goals}</div>;
    },
    [goals_total]
  );

  const yellowCardEvents = useCallback(
    function yellowCardEvents() {
      const cards = [];
      for (let i = 0; i < Number(cards_yellow); i++) {
        cards.push(<YellowCard key={i} className="-ml-1 size-5" />);
      }
      return <div className="flex">{cards}</div>;
    },
    [cards_yellow]
  );

  const redCardEvents = useCallback(
    function redCardEvents() {
      const cards = [];
      for (let i = 0; i < Number(cards_red); i++) {
        cards.push(<RedCard key={i} className="-ml-1 size-5" />);
      }
      return <div className="flex">{cards}</div>;
    },
    [cards_red]
  );

  return (
    <ElementRow>
      <Player color={team_color} player_name={player_name} shirt_number={Number(shirt_number)} size="md" />

      <div className="flex items-center gap-1">
        {!player_started && minute_subbed_in && (
          <div className="flex items-center gap-0.5 rounded-badge bg-white px-1">
            <Change className="size-4 fill-green-500" />
            <span className="text-xs font-semibold text-gray-500">{Number(minute_subbed_in)}</span>
          </div>
        )}
        {minute_subbed_out && (
          <div className="flex items-center gap-0.5 rounded-badge bg-white px-1">
            <Change className="size-4 fill-red-500" />
            <span className="text-xs font-semibold text-gray-500">{Number(minute_subbed_out)}</span>
          </div>
        )}
        {!!goals_total && goals_total > 0 && goalEvents()}
        {!!cards_yellow && cards_yellow > 0 && yellowCardEvents()}
        {!!cards_red && cards_red > 0 && redCardEvents()}
        <span className="bg-white px-1 text-xs font-semibold text-gray-900">{sub_position_acronym}</span>
      </div>
    </ElementRow>
  );
});

export default LineupTableRow;
