import { DatasourceSchema } from 'lib/model';
import { useOutletContext } from 'react-router-dom';
import { ValueOption } from 'utils/interfaces';

export interface SelectedDataSource {
  dataSource: DatasourceSchema;
  state: SelectedDataSourceState;
}

export interface SelectedDataSourceConfiguration {
  areas: ValueOption[];
  leagues: ValueOption[];
  min_season: ValueOption;
  refreshFrequency: ValueOption;
  time: ValueOption;
}

export interface SelectedDataSourceState {
  expanded: boolean;
  authenticated: boolean;
  configured: boolean;
  configuration?: SelectedDataSourceConfiguration;
}

export interface SelectedDataSourcesContext {
  selectedDataSources: SelectedDataSource[];
  setSelectedDataSources: React.Dispatch<React.SetStateAction<SelectedDataSource[]>>;
  resetSelectedDataSources: () => void;
  isLoading: boolean;
}

export function useSelectedDataSources() {
  return useOutletContext<SelectedDataSourcesContext>();
}
