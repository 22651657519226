import { ListItemNode, ListNode } from '@lexical/list';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { EditorState, LexicalEditor } from 'lexical';
import { isEditorStateEmpty } from 'modules/common/Editor/util';
import ElementNoteEditor from 'modules/reports/reportPage/components/elements/Card/ElementNoteEditor';
import { ScoutNoteFormRating } from 'modules/scoutNotes/interfaces';

function onError(error: Error) {
  console.error(error);
}

const theme = {
  // Theme styling goes here
  //...
  text: {
    bold: 'font-semibold',
    italic: 'italic'
  }
};

interface Props {
  onChange?: (editorState: EditorState, editor: LexicalEditor, tags: Set<string>) => void;
  editable?: boolean;
  rating?: ScoutNoteFormRating;
}

const ScoutFormRatingNote = ({ onChange, editable, rating }: Props) => {
  const isEmptyNote = isEditorStateEmpty(rating?.formatted_text);
  const initialConfig = {
    editorState: isEmptyNote ? undefined : rating?.formatted_text,
    editable: editable,
    namespace: 'RatingEditor',
    nodes: [ListNode, ListItemNode],
    theme,
    onError
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <ElementNoteEditor canEdit={editable} onChange={onChange} />
    </LexicalComposer>
  );
};

export default ScoutFormRatingNote;
