import { autoUpdate, shift, size, SizeOptions, useFloating } from '@floating-ui/react-dom';
import { Button, Field, Input, Label, Portal, Transition } from '@headlessui/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useClickAway, useDebounce } from '@uidotdev/usehooks';
import ChevronDown from 'assets/chevron-down.svg?react';
import Loader from 'assets/loading.svg?react';
import Search from 'assets/search-eye.svg?react';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { conditionallyRender } from 'utils/helpers';
import { ValueOption } from 'utils/interfaces';
import ErrorMessage from '../../ErrorMessage';
import InfoMessage from '../../InfoMessage';
import ValueBadge from '../../ValueBadge';
import { SelectInputBaseProps } from '../interfaces';
import SelectDropdownRow, { SelectDropdownRowSkeleton } from './SelectDropdownRow';

function displayOption(option: ValueOption) {
  return option ? `${option.label}${conditionallyRender(!!option.secondaryLabel, `- ${option.secondaryLabel}`)}` : '';
}

function SelectInputBase({
  label,
  loading,
  placeholder,
  options,
  error,
  info,
  searchable,
  debounce = 300,
  multiple,
  disabled,
  infiniteQuery,
  onInputChange,
  inputValue,
  onValueChange
}: SelectInputBaseProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const virtualizerRef = useRef<HTMLDivElement | null>(null);
  const [internalQuery, setInternalQuery] = useState('');
  const debouncedInternalQuery = useDebounce(internalQuery, debounce);
  const dropdownRef = useClickAway((e) => {
    if (e.target !== inputRef.current && !inputRef.current?.contains(e.target as Node)) {
      setOpen(false);
    }
  }) as React.RefObject<HTMLDivElement>;
  const [open, setOpen] = useState(false);

  const filteredRows = useMemo(() => {
    if (onInputChange || !searchable) {
      return options ?? [];
    } else {
      return (
        options?.filter((option) => option.label.toLowerCase().includes(debouncedInternalQuery.toLowerCase())) ?? []
      );
    }
  }, [options, debouncedInternalQuery, onInputChange]);
  const noOptions = !loading && filteredRows.length === 0;

  const virtualizer = useVirtualizer({
    count: infiniteQuery?.hasNextPage ? filteredRows.length + 1 : filteredRows.length,
    getScrollElement: () => virtualizerRef.current,
    estimateSize: (index) => {
      const LARGE_SIZE = 66;
      const SMALL_SIZE = 46;
      if (index >= filteredRows.length) {
        return SMALL_SIZE;
      }
      return filteredRows[index].secondaryLabel ? LARGE_SIZE : SMALL_SIZE;
    }
  });

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`
            // NOTE: Maybe we can add dropdown styles as component props
            // minWidth: 'fit-content' // Causus sizing issue with element exceeding dropdown
          });
        }
      } as SizeOptions),
      shift()
    ]
  });

  // If onInputChange is provided, update the query with the debounced internal query
  useEffect(() => {
    if (onInputChange) {
      if (multiple) {
        onInputChange(debouncedInternalQuery);
      } else if (debouncedInternalQuery === '' || debouncedInternalQuery !== displayOption(inputValue as ValueOption)) {
        onInputChange(debouncedInternalQuery);
      }
    }
  }, [debouncedInternalQuery, onInputChange, inputValue, multiple]);

  const handleRemove = useCallback(
    function handleRemove(removedValue: string) {
      onValueChange((inputValue as ValueOption[]).filter((x) => x.label !== removedValue));
    },
    [onValueChange, inputValue]
  );

  const handleInputChange = useCallback(
    function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
      const value = event.target.value;
      setInternalQuery(value);
      if (value !== '') {
        setOpen(true);
      } else if (!multiple) {
        onValueChange(null);
      }
    },
    [multiple, onValueChange]
  );

  const handleDropdownClick = useCallback(
    (newOption: ValueOption) => {
      if (multiple) {
        const newFormValue = (inputValue as ValueOption[]) ?? [];
        if (newFormValue.some((value: ValueOption) => value.id === (newOption as ValueOption).id)) {
          handleRemove(newOption.label);
        } else {
          newFormValue.push(newOption);
          onValueChange(newFormValue);
        }
      } else {
        setOpen(false);
        onValueChange(newOption);
      }
    },
    [inputValue, multiple, onValueChange, handleRemove]
  );

  const inputDisplayValue = useMemo(() => {
    if (!multiple) {
      return internalQuery || displayOption(inputValue as ValueOption);
    }
    return internalQuery;
  }, [internalQuery, multiple, inputValue]);

  // If infinite query is enabled, fetch next page when the last item is visible
  useEffect(() => {
    if (infiniteQuery) {
      const { hasNextPage, isFetchingNextPage, fetchNextPage } = infiniteQuery;
      const [lastItem] = [...virtualizer.getVirtualItems()].reverse();

      if (!lastItem || !filteredRows.length) {
        return;
      }

      if (lastItem.index >= filteredRows.length - 1 && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  }, [infiniteQuery, filteredRows.length, virtualizer.getVirtualItems()]);

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="relative w-full grow">
        <div
          ref={refs.setReference}
          onClick={(e) => {
            if (!disabled) {
              setOpen(!open);
              e.stopPropagation();
            }
          }}
        >
          <Field>
            <div
              className={twMerge(
                'w-full rounded-lg border border-gray-300 bg-white text-md focus-within:border-blue-600 focus:ring-0',
                error && 'border-red-600'
              )}
              ref={inputRef}
            >
              <Label
                className={twMerge(
                  'pointer-events-none absolute start-3 top-1.5 z-10 origin-[0] translate-y-2.5 transform text-gray-500 duration-300',
                  (Boolean(placeholder) || inputDisplayValue) && '-translate-y-0 text-xs'
                )}
              >
                {label}
              </Label>
              <Input
                className={twMerge(
                  'peer h-14 w-full appearance-none rounded-lg border-none bg-white px-3 pb-1.5 pt-6 text-md placeholder:text-gray-400 focus:outline-none focus:ring-0',
                  !searchable && 'cursor-pointer caret-transparent',
                  error && 'placeholder:text-red-600',
                  disabled && 'bg-gray-50'
                )}
                disabled={disabled}
                value={inputDisplayValue}
                onChange={searchable ? handleInputChange : undefined}
                onBlur={() => {
                  if (!multiple) {
                    setInternalQuery('');
                  }
                }}
                placeholder={placeholder}
              />
              {loading ? (
                <Loader
                  aria-hidden="true"
                  className="pointer-events-none absolute inset-y-4.5 right-3 z-10 size-5 animate-spin fill-gray-700"
                />
              ) : (
                <Button
                  className="absolute right-3 top-4.5 z-10"
                  onClick={(e) => {
                    setOpen(!open);
                    e.stopPropagation();
                  }}
                >
                  <ChevronDown aria-hidden="true" width={20} height={20} className="fill-gray-700" />
                </Button>
              )}
              {multiple && Array.isArray(inputValue) && inputValue.length > 0 && (
                <div className="flex w-full flex-wrap gap-2 p-2">
                  {inputValue.map((selectedValue: ValueOption) => (
                    <ValueBadge value={selectedValue.label} key={selectedValue.id} onRemove={handleRemove} />
                  ))}
                </div>
              )}
            </div>
          </Field>
        </div>

        {!loading && open && (
          <Portal>
            <div ref={refs.setFloating} className="z-50 w-full" style={floatingStyles}>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div ref={dropdownRef}>
                  <div
                    className="max-h-60 overflow-y-auto rounded-md bg-white py-3 shadow-card ring-0 focus:outline-none"
                    ref={virtualizerRef}
                  >
                    {noOptions ? (
                      <div className="mx-3 flex items-center justify-center gap-3 p-3">
                        <Search className="size-5 fill-gray-600" />
                        <div className="text-center text-sm">
                          Unfortunately, there are no options available for the given search input.
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`relative w-full`}
                        style={{
                          height: `${virtualizer.getTotalSize()}px`
                        }}
                      >
                        {virtualizer.getVirtualItems().map((virtualRow) => {
                          const isLoaderRow = virtualRow.index > filteredRows.length - 1;
                          if (isLoaderRow) {
                            return <SelectDropdownRowSkeleton key={virtualRow.index} row={virtualRow} />;
                          }

                          const option = filteredRows[virtualRow.index];
                          let activeOption = false;
                          if (multiple) {
                            activeOption =
                              (inputValue as ValueOption[])?.some((value: ValueOption) => value.id === option.id) ??
                              false;
                          } else {
                            activeOption = (inputValue as ValueOption)?.id === option.id;
                          }

                          return (
                            <SelectDropdownRow
                              key={virtualRow.index}
                              row={virtualRow}
                              active={activeOption}
                              option={option}
                              onClick={handleDropdownClick}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </Transition>
            </div>
          </Portal>
        )}
      </div>
      {error && <ErrorMessage error={error} />}
      {info && <InfoMessage info={info} />}
    </div>
  );
}

export default SelectInputBase;
