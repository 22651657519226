import Note from 'assets/draft.svg?react';

function ScoutNotesEmpty(){
  return <div className="mt-6 flex w-full items-center justify-center rounded-xl bg-gray-50 py-16">
  <div className="flex flex-col items-center gap-3">
    <Note className="size-16 fill-gray-600" />
    <span className="text-sm font-medium text-gray-500">No scout notes.</span>
  </div>
</div>
}

export default ScoutNotesEmpty;
