import { useIsFetching } from '@tanstack/react-query';
import { CellContext } from '@tanstack/react-table';
import Report from 'assets/report.svg?react';
import { ReportSchema } from 'lib/model';
import { AdminProjectSchema } from 'modules/admin/interfaces';
import LoadingCell from 'modules/common/Table/cells/LoadingCell';

function ReportsCell({ getValue }: CellContext<AdminProjectSchema, ReportSchema[]>) {
  const isFetchingReports = useIsFetching({ queryKey: ['reports'], exact: true });
  if (isFetchingReports) return <LoadingCell />;

  const reports = getValue();
  return (
    <div className="mx-2 flex justify-center gap-1 text-xs">
      <Report className="size-5 fill-gray-700" /> {reports.length}
    </div>
  );
}

export default ReportsCell;
