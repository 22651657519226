import { useQueryClient } from '@tanstack/react-query';
import Back from 'assets/arrow-left.svg?react';
import Calendar from 'assets/calendar-check.svg?react';
import CheckCircle from 'assets/check-circle.svg?react';
import Close from 'assets/close.svg?react';
import Filter from 'assets/filter.svg?react';
import LinkIcon from 'assets/link.svg?react';
import Circles from 'assets/multiple-circles.svg?react';
import Trophy from 'assets/trophy.svg?react';
import { useSelectedDataSources } from 'contexts/selectedDataSourcesContext';
import useIsMobile from 'hooks/useIsMobile';
import Button from 'modules/common/Button';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import Divider from 'modules/common/Divider';
import DataSourcePricingBadge from 'modules/dataSources/components/DataSourcePricingBadge';
import DataSourceDataTypeBadge from 'modules/dataSources/components/DataSourceTypeBadge';
import { memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { twJoin, twMerge } from 'tailwind-merge';
import { ValueOption } from 'utils/interfaces';
import { DatasourceConfigurationsNestedSchema, DatasourceSchema } from '../../../lib/model';
import VerifiedBadge from '../dataSources/components/VerifiedBadge';
import { useGetDatasourceDatasourceId } from 'lib/datasource/datasource';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import useActiveProject from 'contexts/project/projectContext';

interface EndpointFiltersFormValues {
  endpoints: ValueOption[];
}

const DataSourceDetailsPage = memo(function DataSourceDetailsPage() {
  const isMobile = useIsMobile();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const { dataSourceId } = useParams();
  const queryClient = useQueryClient();
  const { data: dataSource } = useGetDatasourceDatasourceId<DatasourceSchema>(dataSourceId!, {
    query: {
      queryKey: ['dataSourcesById', dataSourceId],
      staleTime: Infinity
    }
  });
  const navigate = useNavigate();
  const { project } = useActiveProject();
  const { setSelectedDataSources } = useSelectedDataSources();

  const connectedDataSources = queryClient.getQueryData<DatasourceConfigurationsNestedSchema>([
    'project',
    project.id,
    'configuration',
    project.project_configuration_latest,
    'datasource-configuration'
  ]);
  const isDataSourceConnected =
    connectedDataSources?.objects!.find((x) => x.datasource === dataSource?.id) !== undefined;
  // TODO: react on filter changes and filter endpoints
  // TODO: somehow connect endpoint categories with endpoints

  const { control, watch } = useForm<EndpointFiltersFormValues>({
    defaultValues: {
      endpoints: []
    }
  });
  const selectedEndpoints = watch('endpoints');

  function handleToggleFilter() {
    setShowFilter((state) => !state);
  }

  function handleConnectDataSource() {
    setSelectedDataSources((prev) => {
      return prev.concat({
        dataSource: dataSource!,
        state: { expanded: prev.length === 0, authenticated: false, configured: false }
      });
    });
    navigate('/data-sources/activation');
  }

  const endpointOptions: ValueOption[] =
    dataSource?.datasource_endpoints?.map((ep) => ({ label: ep.name!, id: ep.id! })) ?? [];
  // const mockEndpointSubcategories =
  //   dataSource.datasource_endpoints?.flatMap((ep) => {
  //     const subcategories = new Array(4);
  //     for (let i = 0; i < 4; i++) {
  //       subcategories[i] = {
  //         endpoint: ep.id!,
  //         name: ep.name! + i,
  //         highlighted: false
  //       };
  //     }
  //     return subcategories;
  //   }) ?? [];
  const filteredEndpointSubcategories =
    dataSource?.datasource_endpoints!.map((eps) => ({
      ...eps,
      highlighted: selectedEndpoints.some((sep) => sep.id === eps.id!)
    })) ?? [];

  return (
    <div className="flex w-full max-w-[920px] flex-col items-center">
      <header
        className={twMerge(
          'sticky top-0 z-20 flex w-full flex-col gap-6 bg-gray-100 p-10 pb-8',
          isMobile && 'top-14 px-0 py-4'
        )}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Link to={'/data-sources/all'}>
              <Back width={24} height={24} className="fill-gray-950" />
            </Link>
            <DataSourceTitle
              name={dataSource?.name ?? 'Loading...'}
              imageSize="sm"
              size="lg"
              image={dataSource?.logo_image_path}
            />
          </div>
          {isDataSourceConnected ? (
            <div className="flex gap-2">
              <CheckCircle width={20} height={20} className="fill-green-600" />
              <span className="text-sm font-medium">Data Source connected</span>
            </div>
          ) : (
            <Button isFullWidth={false} onClick={handleConnectDataSource}>
              Connect Data Source
            </Button>
          )}
        </div>
      </header>
      <div className={twMerge('flex max-w-[840px] flex-col gap-6', isMobile && 'max-w-[calc(100vw-16px)]')}>
        <div className="flex flex-col gap-8 rounded-xl bg-white p-10">
          <div className="flex flex-wrap items-center justify-between gap-4">
            <div className="flex items-center gap-4">
              <VerifiedBadge verified={dataSource?.verified} />
              <DataSourcePricingBadge pricing={dataSource?.pricing} />
            </div>
            <div className="flex items-center gap-2">
              {dataSource?.data_types?.map((dt) => <DataSourceDataTypeBadge type={dt} key={dt} withText={true} />)}
            </div>
          </div>
          <p className="text-sm font-medium">{dataSource?.description}</p>
          <Divider />
          <div className={twMerge('grid grid-cols-3 gap-6', isMobile && 'grid-cols-1 grid-rows-3')}>
            <div className="flex flex-col gap-2 rounded-xl bg-gray-50 p-6">
              <Circles width={24} height={24} className="fill-gray-950" />
              <span className="text-h6 text-gray-950">{dataSource?.competitions_seasons_num ?? 'N/A'}</span>
              <span className="text-sm font-medium">Competition-Season Pairs</span>
            </div>
            <div className="flex flex-col gap-2 rounded-xl bg-gray-50 p-6">
              <Trophy width={24} height={24} className="fill-gray-950" />
              <span className="text-h6 text-gray-950">{dataSource?.competitions_num ?? 'N/A'}</span>
              <span className="text-sm font-medium">Competitions</span>
            </div>
            <div className="flex flex-col gap-2 rounded-xl bg-gray-50 p-6">
              <Calendar width={24} height={24} className="fill-gray-950" />
              <span className="text-h6 text-gray-950">{dataSource?.oldest_season ?? 'N/A'}</span>
              <span className="text-sm font-medium">Oldest data history</span>
            </div>
          </div>
          <Link
            className="flex items-center justify-center gap-2 rounded-md bg-gray-50 p-3"
            to={dataSource?.website ?? '#'}
            target="_blank"
          >
            <LinkIcon width={16} height={16} className="fill-brand-800" />
            <span className="text-xs font-medium text-brand-800">Visit website</span>
          </Link>
        </div>
        <div className="flex flex-col gap-8 rounded-xl bg-white p-10">
          <div className="flex items-center justify-between">
            <span className="text-sm font-semibold">Endpoints</span>
            <Button variant="secondary" isFullWidth={false} size="sm" onClick={handleToggleFilter}>
              {!showFilter && <Filter width={16} height={16} />}
              {showFilter && <Close width={16} height={16} />}
              <span>Filter</span>
            </Button>
          </div>
          {showFilter && (
            <form>
              <SelectInput
                formProps={{
                  control: control,
                  name: 'endpoints'
                }}
                multiple
                label="Endpoints"
                options={endpointOptions}
                searchable
                placeholder="All Endpoints"
              />
            </form>
          )}
          <div className="grid grid-cols-4 gap-3">
            {filteredEndpointSubcategories.map((eps, index) => (
              <span
                key={index}
                className={twJoin(
                  'rounded-badge px-3 py-2 text-center text-tiny font-semibold',
                  selectedEndpoints.length !== 0 &&
                    eps.highlighted &&
                    'border border-brand-800 bg-brand-50 text-brand-800',
                  selectedEndpoints.length !== 0 && !eps.highlighted && 'bg-gray-50 text-gray-300',
                  selectedEndpoints.length === 0 && 'bg-gray-50'
                )}
              >
                {eps.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default DataSourceDetailsPage;
