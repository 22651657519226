import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useActiveProject from 'contexts/project/projectContext';
import { ScoutNote } from 'lib/model';
import { useDeleteScoutNote } from 'lib/scout-note/scout-note';
import ConfirmDeleteDialog from 'modules/common/Dialog/ConfirmDeleteDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DialogProps } from 'utils/interfaces';

interface Props extends Omit<DialogProps, 'setOpen'> {
  note?: ScoutNote | null;
  closeDialog: () => void;
}

function DeleteNoteDialog({ note, open, closeDialog: handleDialogClose }: Props) {
  const { project } = useActiveProject();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate: deleteScoutNote, isPending: isDeleting } = useDeleteScoutNote({
    mutation: {
      onSuccess() {
        toast.success('Note has been successfully deleted');
        queryClient.removeQueries({
          queryKey: ['project', project.id, 'scout-note', note!.id!]
        });
        queryClient.invalidateQueries({
          queryKey: ['project', project.id, 'scout-notes']
        });
        if (location.pathname !== '/scout-notes') {
          navigate('/scout-notes');
        }
      },
      onError(err) {
        let errorMessage = 'An error occurred while deleting the note. Please try again later.';
        if (err instanceof AxiosError && err.response?.data.error) {
          errorMessage = err.response?.data?.error;
        }
        toast.error(errorMessage);
      },
      onSettled() {
        handleDialogClose();
      }
    }
  });

  function handleNoteDelete() {
    if (project && note) {
      deleteScoutNote({ projectId: project.id!, scoutNoteId: note!.id! });
    } else {
      toast.error('An error occurred while deleting the note');
      handleDialogClose();
    }
  }

  if (!note) {
    return null;
  }

  return (
    <ConfirmDeleteDialog
      open={open}
      handleCancel={handleDialogClose}
      handleDelete={handleNoteDelete}
      isDeleting={isDeleting}
      objectName={note.note_name ?? ''}
    />
  );
}

export default DeleteNoteDialog;
