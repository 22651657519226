/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */

export type ScoutNoteCreateSchemaType = (typeof ScoutNoteCreateSchemaType)[keyof typeof ScoutNoteCreateSchemaType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ScoutNoteCreateSchemaType = {
  match: 'match',
  training: 'training',
  other: 'other'
} as const;
