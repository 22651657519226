import useActiveProject from 'contexts/project/projectContext';
import { UserAccountGetSchema } from 'lib/model';
import { useGetUser } from 'lib/user-account/user-account';
import { VIEW_SCOUT_NOTE_ROLES } from 'modules/scoutNotes/constants';
import { Navigate, Outlet } from 'react-router-dom';
import { toast } from 'react-toastify';

function ScoutNotesBaseLayout() {
  const { project } = useActiveProject();
  const { data: user, isPending } = useGetUser({
    query: { queryKey: ['user'], staleTime: Infinity, refetchInterval: 1000 * 60 * 30 }
  });

  if (!isPending && !user) {
    Navigate({ to: '/login' });
  }

  function checkPermissions(user: UserAccountGetSchema) {
    if (user.is_admin) {
      return true;
    }

    const currentProject = user.user_projects?.find((user_project) => project.id === user_project.project);
    if (!currentProject) {
      // User is not a member of the current project
      return false;
    } else {
      const userRole = currentProject.project_role_name!.toLowerCase();
      if (VIEW_SCOUT_NOTE_ROLES.includes(userRole)) {
        return true;
      }
    }

    return false;
  }

  const allowed = checkPermissions(user!);
  if (!allowed) {
    toast.warning('You do not have permission to view this page', {
      toastId: 'view-scout-note-access-permission'
    });
    Navigate({ to: '/dashboard' });
  }

  return <Outlet />;
}

export default ScoutNotesBaseLayout;
