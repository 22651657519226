import { ValueOption } from 'utils/interfaces';

// TODO: Sync with BE enums
export const SCOUT_REPORT_VARIANT = {
  ranking: 'ranking',
  similar: 'similar'
} as const;

export const scoutReportVariantOptions = [
  {
    id: SCOUT_REPORT_VARIANT.ranking,
    label: 'Best ranking players'
  },
  {
    id: SCOUT_REPORT_VARIANT.similar,
    label: 'Similar players'
  }
] as ValueOption[];
