import StatusEnum from 'constants/enums/status';
import { ProjectDeploymentSchema } from 'lib/model';

export function getDeploymentStatus(deployment: ProjectDeploymentSchema) {
  if (deployment.exit_status === 'FAILED') {
    return StatusEnum.ERROR;
  } else if (deployment.exit_status === 'UNKNOWN') {
    return StatusEnum.UNKNOWN;
  } else if (deployment.exit_status === 'SUCCEEDED') {
    return StatusEnum.SUCCESS;
  } else if (!deployment.time_finished) {
    return StatusEnum.RUNNING;
  } else {
    return StatusEnum.UNKNOWN;
  }
}

export function getLastLoginDateString(date: Date | string) {
  let parseDate;
  if (typeof date === 'string') {
    parseDate = new Date(date);
  } else {
    parseDate = date;
  }

  return (
    parseDate.toLocaleDateString() +
    ' ' +
    parseDate.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })
  );
}
