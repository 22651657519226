import { VirtualItem } from "@tanstack/react-virtual";
import { twJoin, twMerge } from "tailwind-merge";
import { ValueOption } from "utils/interfaces";
import Check from 'assets/check.svg?react';
import Loader from 'assets/loading.svg?react';

interface SelectDropdownRowProps {
  row: VirtualItem;
  active: boolean;
  option: ValueOption;
  onClick: (option: ValueOption) => void;
};


export function SelectDropdownRowSkeleton({row}: {row: VirtualItem}){
  return (
    <div
      className={twJoin(
        'absolute left-0 top-0 w-full cursor-pointer select-none px-2 py-1 hover:bg-gray-50'
      )}
      style={{
        height: `${row.size}px`,
        transform: `translateY(${row.start}px)`
      }}
    >
      <div className="flex w-full justify-center">
        <Loader
          aria-hidden="true"
          className="pointer-events-none animate-spin fill-gray-700"
        />
      </div>
    </div>
  );
}

function SelectDropdownRow({row, active, option, onClick}:SelectDropdownRowProps){
  function handleOnClick(){
    return onClick(option);
  }

  return (
    <div
      key={row.index}
      className={
        'absolute left-0 top-0 w-full cursor-pointer select-none px-2 py-1 hover:bg-gray-50'
      }
      style={{
        height: `${row.size}px`,
        transform: `translateY(${row.start}px)`
      }}
      onClick={handleOnClick}
    >
      <div
        className={twJoin(
          'flex items-center justify-between gap-3 rounded-md px-1.5 py-2',
          active && 'bg-brand-50'
        )}
      >
        <div className="flex items-center gap-2">
          <div className="flex flex-col">
            <span
              className={twJoin(
                'block truncate text-sm font-medium',
                active && 'font-semibold text-brand-800'
              )}
            >
              {option.label}
            </span>
            {option.secondaryLabel && (
              <span
                className={twMerge(
                  'block truncate text-xs text-gray-500',
                  active && 'font-semibold text-brand-800'
                )}
              >
                {option.secondaryLabel}
              </span>
            )}
          </div>
        </div>
        <Check
          width={20}
          height={20}
          className={twMerge('hidden fill-brand-800', active && 'inline')}
        />
      </div>
    </div>
  );
}

export default SelectDropdownRow;
