import Note from 'assets/draft.svg?react';
import { ScoutNote } from 'lib/model';
import PlayerHeader from 'modules/reports/reportPage/components/reportCards/Player/PlayerHeader';
import { NavLink } from 'react-router-dom';

export const ScoutNoteCardSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 rounded-xl bg-white p-4 transition-transform duration-200 hover:-translate-y-1 hover:shadow-card">
      <div className="h-20 animate-pulse rounded-xl bg-gray-50" />
      <div className="h-5 w-1/4 animate-pulse rounded-md bg-gray-50" />
      <div className="h-4 w-1/4 animate-pulse rounded-md bg-gray-50" />
      <div className="flex w-full items-center justify-between gap-6">
        <div className="h-8 basis-1/3 animate-pulse rounded-md bg-gray-50" />
      </div>
    </div>
  );
};

const ScoutNoteCard = ({ note }: { note: ScoutNote }) => {
  const lastEdited = note.date_last_updated ? new Date(note.date_last_updated) : undefined;

  let referenceData = undefined;
  if (note.reference) {
    const match = note.reference;
    referenceData = match.home_team_name + ' - ' + match.away_team_name;
  }

  return (
    <NavLink
      className="flex flex-col justify-between gap-4 rounded-xl bg-white p-4 transition-transform duration-200 hover:-translate-y-1 hover:shadow-card"
      to={`/scout-notes/${note.id}`}
    >
      <div className="flex flex-col gap-4">
        {note.player && <PlayerHeader textSize="tiny" iconSize="md" narrower player={note.player!} />}
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <Note className="fill-gray-600" />
              <span className="truncate text-xs font-semibold" title={note.note_name}>
                {note.note_name}
              </span>
              {/* {referenceData && <span className="truncate text-xs font-semibold text-gray-500">({referenceData})</span>} */}
            </div>
          </div>
          <div className="text-tiny font-semibold text-gray-500">{note.user_full_name}</div>
        </div>
      </div>
      <div className="flex items-center justify-between gap-6">
        <div className="flex flex-col gap-1">
          <span className="text-micro font-medium">CREATED</span>
          {lastEdited ? (
            <div className="flex items-center gap-2">
              <span className="text-tiny font-semibold">{lastEdited.toLocaleDateString()}</span>
              <span className="h-2.5 w-px bg-gray-300" />
              <span className="text-tiny font-semibold">{lastEdited.toLocaleTimeString()}</span>
            </div>
          ) : (
            <span className="text-tiny font-semibold">-</span>
          )}
        </div>
        {/* <SharedUsersIcons users={report.users ?? []} /> */}
      </div>
    </NavLink>
  );
};

export default ScoutNoteCard;
