import Spinner from 'modules/common/Spinner';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
}

function LoadingCell({ className }: Props) {
  return (
    <div className={twMerge('mx-2 flex items-center justify-center', className)}>
      <Spinner />
    </div>
  );
}

export default LoadingCell;
