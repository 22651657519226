/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  default as createProjectScoutNoteMutator,
  default as deleteScoutNoteMutator,
  default as getProjectScoutNoteMutator,
  default as getProjectScoutNotesMutator,
  default as getScoutNotesByPlayerMutator,
  default as updateProjectScoutNoteMutator
} from '../../api/axios';
import type {
  GetProjectScoutNotesParams,
  GetScoutNotesByPlayerParams,
  PlayerScoutNotesGetSchema,
  ScoutNoteCreateSchema,
  ScoutNoteGetSchema,
  ScoutNoteSchema
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Returns a list of all scout notes
 */
export const getProjectScoutNotes = (
  projectId: string,
  params?: GetProjectScoutNotesParams,
  options?: SecondParameter<typeof getProjectScoutNotesMutator>,
  signal?: AbortSignal
) => {
  return getProjectScoutNotesMutator<ScoutNoteGetSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/scout/note`, method: 'GET', params, signal },
    options
  );
};

export const getGetProjectScoutNotesQueryKey = (projectId: string, params?: GetProjectScoutNotesParams) => {
  return [`/project/${projectId}/scout/note`, ...(params ? [params] : [])] as const;
};

export const getGetProjectScoutNotesQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectScoutNotes>>,
  TError = unknown
>(
  projectId: string,
  params?: GetProjectScoutNotesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectScoutNotes>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectScoutNotesMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectScoutNotesQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectScoutNotes>>> = ({ signal }) =>
    getProjectScoutNotes(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectScoutNotes>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectScoutNotesQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectScoutNotes>>>;
export type GetProjectScoutNotesQueryError = unknown;

/**
 * @summary Returns a list of all scout notes
 */
export const useGetProjectScoutNotes = <TData = Awaited<ReturnType<typeof getProjectScoutNotes>>, TError = unknown>(
  projectId: string,
  params?: GetProjectScoutNotesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectScoutNotes>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectScoutNotesMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectScoutNotesQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Creates a new scout note
 */
export const createProjectScoutNote = (
  projectId: string,
  scoutNoteCreateSchema: ScoutNoteCreateSchema,
  options?: SecondParameter<typeof createProjectScoutNoteMutator>
) => {
  return createProjectScoutNoteMutator<ScoutNoteSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/scout/note`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: scoutNoteCreateSchema
    },
    options
  );
};

export const getCreateProjectScoutNoteMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProjectScoutNote>>,
    TError,
    { projectId: string; data: ScoutNoteCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof createProjectScoutNoteMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createProjectScoutNote>>,
  TError,
  { projectId: string; data: ScoutNoteCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createProjectScoutNote>>,
    { projectId: string; data: ScoutNoteCreateSchema }
  > = (props) => {
    const { projectId, data } = props ?? {};

    return createProjectScoutNote(projectId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateProjectScoutNoteMutationResult = NonNullable<Awaited<ReturnType<typeof createProjectScoutNote>>>;
export type CreateProjectScoutNoteMutationBody = ScoutNoteCreateSchema;
export type CreateProjectScoutNoteMutationError = unknown;

/**
 * @summary Creates a new scout note
 */
export const useCreateProjectScoutNote = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createProjectScoutNote>>,
    TError,
    { projectId: string; data: ScoutNoteCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof createProjectScoutNoteMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof createProjectScoutNote>>,
  TError,
  { projectId: string; data: ScoutNoteCreateSchema },
  TContext
> => {
  const mutationOptions = getCreateProjectScoutNoteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes an existing scout note
 */
export const deleteScoutNote = (
  projectId: string,
  scoutNoteId: string,
  options?: SecondParameter<typeof deleteScoutNoteMutator>
) => {
  return deleteScoutNoteMutator<void>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/scout/note/${encodeURIComponent(String(scoutNoteId))}`,
      method: 'DELETE'
    },
    options
  );
};

export const getDeleteScoutNoteMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteScoutNote>>,
    TError,
    { projectId: string; scoutNoteId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteScoutNoteMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteScoutNote>>,
  TError,
  { projectId: string; scoutNoteId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteScoutNote>>,
    { projectId: string; scoutNoteId: string }
  > = (props) => {
    const { projectId, scoutNoteId } = props ?? {};

    return deleteScoutNote(projectId, scoutNoteId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteScoutNoteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteScoutNote>>>;

export type DeleteScoutNoteMutationError = unknown;

/**
 * @summary Deletes an existing scout note
 */
export const useDeleteScoutNote = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteScoutNote>>,
    TError,
    { projectId: string; scoutNoteId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteScoutNoteMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteScoutNote>>,
  TError,
  { projectId: string; scoutNoteId: string },
  TContext
> => {
  const mutationOptions = getDeleteScoutNoteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns scout note with the specified id
 */
export const getProjectScoutNote = (
  projectId: string,
  scoutNoteId: string,
  options?: SecondParameter<typeof getProjectScoutNoteMutator>,
  signal?: AbortSignal
) => {
  return getProjectScoutNoteMutator<ScoutNoteSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/scout/note/${encodeURIComponent(String(scoutNoteId))}`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetProjectScoutNoteQueryKey = (projectId: string, scoutNoteId: string) => {
  return [`/project/${projectId}/scout/note/${scoutNoteId}`] as const;
};

export const getGetProjectScoutNoteQueryOptions = <
  TData = Awaited<ReturnType<typeof getProjectScoutNote>>,
  TError = unknown
>(
  projectId: string,
  scoutNoteId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectScoutNote>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectScoutNoteMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetProjectScoutNoteQueryKey(projectId, scoutNoteId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectScoutNote>>> = ({ signal }) =>
    getProjectScoutNote(projectId, scoutNoteId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(projectId && scoutNoteId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getProjectScoutNote>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetProjectScoutNoteQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectScoutNote>>>;
export type GetProjectScoutNoteQueryError = unknown;

/**
 * @summary Returns scout note with the specified id
 */
export const useGetProjectScoutNote = <TData = Awaited<ReturnType<typeof getProjectScoutNote>>, TError = unknown>(
  projectId: string,
  scoutNoteId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectScoutNote>>, TError, TData>>;
    request?: SecondParameter<typeof getProjectScoutNoteMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetProjectScoutNoteQueryOptions(projectId, scoutNoteId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Updates the specified scout note
 */
export const updateProjectScoutNote = (
  projectId: string,
  scoutNoteId: string,
  scoutNoteCreateSchema: ScoutNoteCreateSchema,
  options?: SecondParameter<typeof updateProjectScoutNoteMutator>
) => {
  return updateProjectScoutNoteMutator<ScoutNoteSchema>(
    {
      url: `/project/${encodeURIComponent(String(projectId))}/scout/note/${encodeURIComponent(String(scoutNoteId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: scoutNoteCreateSchema
    },
    options
  );
};

export const getUpdateProjectScoutNoteMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProjectScoutNote>>,
    TError,
    { projectId: string; scoutNoteId: string; data: ScoutNoteCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof updateProjectScoutNoteMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateProjectScoutNote>>,
  TError,
  { projectId: string; scoutNoteId: string; data: ScoutNoteCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateProjectScoutNote>>,
    { projectId: string; scoutNoteId: string; data: ScoutNoteCreateSchema }
  > = (props) => {
    const { projectId, scoutNoteId, data } = props ?? {};

    return updateProjectScoutNote(projectId, scoutNoteId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateProjectScoutNoteMutationResult = NonNullable<Awaited<ReturnType<typeof updateProjectScoutNote>>>;
export type UpdateProjectScoutNoteMutationBody = ScoutNoteCreateSchema;
export type UpdateProjectScoutNoteMutationError = unknown;

/**
 * @summary Updates the specified scout note
 */
export const useUpdateProjectScoutNote = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateProjectScoutNote>>,
    TError,
    { projectId: string; scoutNoteId: string; data: ScoutNoteCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof updateProjectScoutNoteMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateProjectScoutNote>>,
  TError,
  { projectId: string; scoutNoteId: string; data: ScoutNoteCreateSchema },
  TContext
> => {
  const mutationOptions = getUpdateProjectScoutNoteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Returns a list of all scout notes
 */
export const getScoutNotesByPlayer = (
  projectId: string,
  params?: GetScoutNotesByPlayerParams,
  options?: SecondParameter<typeof getScoutNotesByPlayerMutator>,
  signal?: AbortSignal
) => {
  return getScoutNotesByPlayerMutator<PlayerScoutNotesGetSchema>(
    { url: `/project/${encodeURIComponent(String(projectId))}/scout/player-note`, method: 'GET', params, signal },
    options
  );
};

export const getGetScoutNotesByPlayerQueryKey = (projectId: string, params?: GetScoutNotesByPlayerParams) => {
  return [`/project/${projectId}/scout/player-note`, ...(params ? [params] : [])] as const;
};

export const getGetScoutNotesByPlayerQueryOptions = <
  TData = Awaited<ReturnType<typeof getScoutNotesByPlayer>>,
  TError = unknown
>(
  projectId: string,
  params?: GetScoutNotesByPlayerParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getScoutNotesByPlayer>>, TError, TData>>;
    request?: SecondParameter<typeof getScoutNotesByPlayerMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetScoutNotesByPlayerQueryKey(projectId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getScoutNotesByPlayer>>> = ({ signal }) =>
    getScoutNotesByPlayer(projectId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!projectId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getScoutNotesByPlayer>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetScoutNotesByPlayerQueryResult = NonNullable<Awaited<ReturnType<typeof getScoutNotesByPlayer>>>;
export type GetScoutNotesByPlayerQueryError = unknown;

/**
 * @summary Returns a list of all scout notes
 */
export const useGetScoutNotesByPlayer = <TData = Awaited<ReturnType<typeof getScoutNotesByPlayer>>, TError = unknown>(
  projectId: string,
  params?: GetScoutNotesByPlayerParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getScoutNotesByPlayer>>, TError, TData>>;
    request?: SecondParameter<typeof getScoutNotesByPlayerMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetScoutNotesByPlayerQueryOptions(projectId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};
