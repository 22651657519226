import DataSourcesFiltersProvider from 'contexts/dataSourcesFilters/DataSourcesFiltersProvider';
import { FilteredDataSourcesProvider } from 'contexts/filteredDataSources/FilteredDataSourcesProvider';
import useActiveProject from 'contexts/project/projectContext';
import { SelectedDataSource, SelectedDataSourcesContext } from 'contexts/selectedDataSourcesContext';
import { useGetProjectProjectIdDataTable } from 'lib/data-table/data-table';
import { useGetDatasource } from 'lib/datasource/datasource';
import { ProjectConfigurationNestedSchema } from 'lib/model';
import { useGetProjectProjectIdConfigurationProjectConfigurationId } from 'lib/project-configuration/project-configuration';
import { extractDatasourceConfigOptions } from 'modules/dataSources/helpers';
import { memo, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router';
import DataSourcesHeader from './components/DataSourcesHeader';

const DataSourcesLayout = memo(function DataSourcesLayout() {
  const { project } = useActiveProject();
  const [selectedDataSources, setSelectedDataSources] = useState<SelectedDataSource[]>([]);

  // DATA FETCHING
  const { data: dataSourcesData, isFetching: isFetchingDatasources } = useGetDatasource(undefined, {
    query: { queryKey: ['dataSources'], staleTime: Infinity }
  });

  const { data: projectConfiguration, isFetching: isFetchingConfiguration } =
    useGetProjectProjectIdConfigurationProjectConfigurationId<ProjectConfigurationNestedSchema>(
      project.id!,
      project.project_configuration_latest!,
      { nested: true },
      {
        query: {
          queryKey: ['project', project.id, 'configuration', project.project_configuration_latest],
          staleTime: Infinity,
          enabled: !!project.project_configuration_latest
        }
      }
    );

  const isLoading = isFetchingDatasources || isFetchingConfiguration;

  useGetProjectProjectIdDataTable(project.id!, {
    query: {
      queryKey: ['project', project.id, 'data-tables'],
      staleTime: Infinity
    }
  });

  // DATA TRANSFORMATION
  const defaultSelectedDatasources: SelectedDataSource[] = useMemo(() => {
    const dataSources = dataSourcesData?.objects;
    const datasourceConfigurations = projectConfiguration?.datasource_configurations;
    const defaultSelectedDataSources: SelectedDataSource[] = [];
    if (dataSources && datasourceConfigurations) {
      for (const dsConfig of datasourceConfigurations) {
        const dataSource = dataSources.find((ds) => ds.id === dsConfig.datasource);
        if (dataSource) {
          const selectedDatasource: SelectedDataSource = {
            dataSource,
            state: {
              authenticated: true,
              expanded: true,
              configured: true,
              configuration: extractDatasourceConfigOptions(dataSource, dsConfig)
            }
          };
          defaultSelectedDataSources.push(selectedDatasource);
        }
      }
    }
    return defaultSelectedDataSources;
  }, [dataSourcesData, projectConfiguration]);

  useEffect(() => {
    setSelectedDataSources(defaultSelectedDatasources);
  }, [defaultSelectedDatasources, setSelectedDataSources]);

  function resetSelectedDataSources() {
    setSelectedDataSources(defaultSelectedDatasources);
  }

  return (
    <DataSourcesFiltersProvider>
      <FilteredDataSourcesProvider>
        <div className="flex w-full flex-col items-center">
          <DataSourcesHeader />
          <Outlet
            context={
              {
                selectedDataSources,
                setSelectedDataSources,
                resetSelectedDataSources,
                isLoading
              } satisfies SelectedDataSourcesContext
            }
          />
        </div>
      </FilteredDataSourcesProvider>
    </DataSourcesFiltersProvider>
  );
});

export default DataSourcesLayout;
