import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react';
import MenuContext from 'assets/menu-context.svg?react';
import { Fragment, PropsWithChildren } from 'react';
import { stopPropagation } from 'utils/helpers';

interface Props {
  test?: string;
}

function DropdownWrapper({ children }: PropsWithChildren<Props>) {
  return (
    <Menu as={'div'} className="relative flex h-6 items-center text-left">
      <MenuButton onClick={stopPropagation} className="rounded-md p-1 hover:bg-gray-200">
        <MenuContext width={24} height={24} className="fill-gray-700" />
      </MenuButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          anchor="bottom end"
          className="absolute z-50 mt-2 h-fit w-40 whitespace-nowrap rounded-md bg-white py-3 shadow-card focus:outline-none"
        >
          {children}
        </MenuItems>
      </Transition>
    </Menu>
  );
}

export default DropdownWrapper;
