import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useActiveProject from 'contexts/project/projectContext';
import { PlayerScoutNotes, ReportCreateSchemaReportType } from 'lib/model';
import { useGetReportGetPlayersInfinite, usePostReport } from 'lib/report/report';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import ErrorMessage from 'modules/common/Form/ErrorMessage';
import Input from 'modules/common/Form/Input';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { AutocompleteOption, DialogProps } from 'utils/interfaces';
import { playerToAutocompleteOption } from 'utils/mappings';

interface NewPlayerReportFormValues {
  name: string;
  player: AutocompleteOption | null;
}

const NewPlayerReportDialog = memo(function NewPlayerReportDialog({ open, setOpen }: DialogProps) {
  const [query, setQuery] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();
  const defaultValues: Partial<NewPlayerReportFormValues> = {
    name: '',
    player: null
  };
  if (location.state) {
    if (location.state.player) {
      defaultValues.player = playerToAutocompleteOption(location.state.player as Required<PlayerScoutNotes>);
    }
  }

  const {
    handleSubmit: formSubmit,
    register,
    control,
    watch,
    setValue,
    setError,
    formState: { errors }
  } = useForm<NewPlayerReportFormValues>({
    defaultValues: defaultValues
  });

  const queryClient = useQueryClient();
  const { project } = useActiveProject();

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, status } = useGetReportGetPlayersInfinite(
    {
      player_name: query,
      project_id: project.id
    },
    {
      query: {
        queryKey: ['players', project.id, query],
        staleTime: 1000 * 60 * 5,
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages, lastPageParam) => {
          if (!lastPage.players || lastPage.players?.length < 100) {
            return undefined;
          }

          return lastPageParam ? lastPageParam + 1 : 1;
        }
      }
    }
  );

  const infinitePlayersOptions = useMemo(() => {
    const allRows = data ? data.pages.flatMap((d) => d.players) : [];
    return allRows.map(playerToAutocompleteOption) ?? [];
  }, [data]);

  const { mutate: createReport, isPending: isCreating } = usePostReport();

  function handleSubmit(data: NewPlayerReportFormValues) {
    if (!data.player) return;
    createReport(
      {
        data: {
          name: data.name,
          report_type: ReportCreateSchemaReportType.player,
          project: project!.id!,
          report_entities: [data.player.id as number]
        }
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({ queryKey: ['reports'] });
          navigate(`/reports/${res.id!}`);
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            setError('root', { message: err.response?.data.error, type: 'backend-validation' });
          }
        }
      }
    );
  }

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  const selectedPlayer = watch('player');
  useEffect(() => {
    if (selectedPlayer) {
      setValue('name', `${selectedPlayer.label} - ${selectedPlayer.secondaryLabel}`);
    } else {
      setValue('name', '');
    }
  }, [selectedPlayer, setValue]);

  return (
    <DialogBase title="New Player Report" onCancel={handleCancel} open={open}>
      <DialogContent>
        <form className="flex w-full flex-col gap-10" id="new-player-report-form" onSubmit={formSubmit(handleSubmit)}>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Find Player</span>
            <SelectInput
              loading={status === 'pending'}
              label={'Player'}
              placeholder="Start typing: Player name / Team / Player country"
              options={infinitePlayersOptions}
              onInputChange={setQuery}
              searchable
              infiniteQuery={{
                hasNextPage: hasNextPage,
                fetchNextPage: fetchNextPage,
                isFetchingNextPage: isFetchingNextPage
              }}
              error={errors.player}
              formProps={{
                name: 'player',
                rules: { required: { value: true, message: 'Player is required.' } },
                control: control
              }}
            />
          </div>
          <div className="flex flex-col gap-6">
            <span className="text-md font-semibold">Report Name</span>
            <Input
              label="Enter report name"
              registerReturn={register('name', { required: 'Report Name is required' })}
              error={errors.name}
            />
          </div>
          {errors?.root && <ErrorMessage error={errors.root} />}
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" disabled={isCreating} onClick={handleCancel}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton form="new-player-report-form" loading={isCreating}>
          <span>Create Player Report</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default NewPlayerReportDialog;
