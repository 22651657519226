import { ScoutReportSettings } from 'lib/model';
import { SCOUT_REPORT_VARIANT } from './constants';
import { ScoutReportFormValues } from './interfaces';

export function scoutFormToScoutSettings(data: ScoutReportFormValues): ScoutReportSettings {
  const variant = data.variant.id! as string;
  const scoutReportSettings: ScoutReportSettings = {
    variant: variant,
    seasons: data.seasons?.map((option) => option.id as string),
    player_position: data.position?.id as string,
    player_sub_position: data.subPositions?.map((option) => option.id as string),
    num_filtered_players: data.numberOfFilteredPlayers
  };

  switch (variant) {
    case SCOUT_REPORT_VARIANT.ranking:
      scoutReportSettings.removed_players = data.removedPlayers;
      scoutReportSettings.metric_criteria = data.metric!.id! as string;
      scoutReportSettings.aggregation_metric = data.aggregation_metric!.id! as string;
      break;
    case SCOUT_REPORT_VARIANT.similar:
      scoutReportSettings.similar_player = data.similarPlayer!.id! as number;
      scoutReportSettings.similar_metrics = data.similarMetrics?.map((option) => option.id as string);
      break;
  }

  if (data.isCompetitionsAndTeamsEnabled) {
    scoutReportSettings.competitions = data.competitions?.map((option) => option.id as number) ?? undefined;
    scoutReportSettings.teams = data.teams?.map((option) => option.id as number) ?? undefined;
  }
  if (data.isPlaytimeExperienceEnabled) {
    scoutReportSettings.min_games_played = data.minPlayedGames ?? undefined;
    scoutReportSettings.avg_min_played_per_match_min = data.avgMinutesPlayed?.at(0) ?? undefined;
    scoutReportSettings.avg_min_played_per_match_max = data.avgMinutesPlayed?.at(1) ?? undefined;
  }
  if (data.isMarketValueEnabled) {
    scoutReportSettings.market_value_min = data.avgMarketValue?.at(0) ?? undefined;
    scoutReportSettings.market_value_max = data.avgMarketValue?.at(1) ?? undefined;
  }
  if (data.isPhysicalAttributesEnabled) {
    scoutReportSettings.age_min = data.age?.at(0) ?? undefined;
    scoutReportSettings.age_max = data.age?.at(1) ?? undefined;
    scoutReportSettings.weight_min = data.weight?.at(0) ?? undefined;
    scoutReportSettings.weight_max = data.weight?.at(1) ?? undefined;
    scoutReportSettings.height_min = data.height?.at(0) ?? undefined;
    scoutReportSettings.height_max = data.height?.at(1) ?? undefined;
  }
  if (data.isNationalityEnabled) {
    scoutReportSettings.birth_country = data.countryOfBirth?.map((option) => option.id as string);
    scoutReportSettings.nationality = data.nationality?.map((option) => option.id as string);
  }

  return scoutReportSettings;
}
