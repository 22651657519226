import { ProjectTableCell } from 'modules/admin/interfaces';
import LoadingCell from 'modules/common/Table/cells/LoadingCell';
import { twJoin } from 'tailwind-merge';
import { useProjectSubscriptionExpiration } from '../../../hooks/useGetProjectSubscription';

const SubscriptionCell = ({ project }: ProjectTableCell) => {
  const { expirationString, expired, isPending } = useProjectSubscriptionExpiration(project.id!);
  if (isPending) {
    return <LoadingCell />;
  }
  return <div className={twJoin('mx-2 text-xs', expired && 'text-red-600')}>{expirationString}</div>;
};

export default SubscriptionCell;
