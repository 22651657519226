import { useQueryClient } from '@tanstack/react-query';
import Change from 'assets/change.svg?react';
import Cancel from 'assets/close.svg?react';
import { AxiosError } from 'axios';
import { newProjectConfigDefault } from 'constants/newProjectConfigDefault';
import useActiveProject from 'contexts/project/projectContext';
import { useSelectedDataSources } from 'contexts/selectedDataSourcesContext';
import {
  DatasourceConfigurationCreateSchema,
  DatasourceSchema,
  DatasourcesSchema,
  ProjectConfigurationCreateSchema,
  ProjectConfigurationNestedSchema,
} from 'lib/model';
import {
  useGetProjectProjectIdConfigurationProjectConfigurationId,
  usePostProjectProjectIdConfiguration
} from 'lib/project-configuration/project-configuration';
import Button from 'modules/common/Button';
import DataSourceTitle from 'modules/common/DataSourceTitle';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import DataSourcePriorityOrder from 'modules/dataSources/components/DataSourcePriorityOrder';
import { memo, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DialogProps } from 'utils/interfaces';

const ConnectDataSourcesDialog = memo(function ConnectDataSourcesDialog({ open, setOpen }: DialogProps) {
  const navigate = useNavigate();
  const { selectedDataSources } = useSelectedDataSources();
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const dataSources = queryClient.getQueryData<DatasourcesSchema>(['dataSources'])!;
  const { data: projectConfiguration, isFetching: isFetchingProjectConfiguration } =
    useGetProjectProjectIdConfigurationProjectConfigurationId<ProjectConfigurationNestedSchema>(
      project.id!,
      project.project_configuration_latest!,
      { nested: true },
      {
        query: {
          queryKey: ['project', project.id, 'configuration', project.project_configuration_latest],
          staleTime: Infinity,
          enabled: !!project.project_configuration_latest
        }
      }
    );

  const [loading, setLoading] = useState(false);
  const [showEditOrder, setShowEditOrder] = useState(false);
  const [dataSourceOrder, setDataSourceOrder] = useState<DatasourceSchema[]>([]);

  const defaultOrder = useMemo(() => {
    if (!dataSources.objects || isFetchingProjectConfiguration) return [];

    const priorityOrderOption = projectConfiguration!.project_configuration_options!.find(
      (option) => option.ssm_parameter_name === 'priority_order'
    );

    let priorityOrder: string[] = [];
    if (priorityOrderOption) {
      priorityOrder = priorityOrderOption.value!.split(',').map((name) => name.trim());
    }

    const activeDataSources = [...selectedDataSources.map((selected) => selected.dataSource)];

    const order: DatasourceSchema[] = [
      ...activeDataSources
        .filter((ds) => priorityOrder.includes(ds.name!.toLowerCase()))
        .sort((a, b) => {
          return priorityOrder.indexOf(a.name!.toLowerCase()) - priorityOrder.indexOf(b.name!.toLowerCase());
        }),
      ...activeDataSources.filter((ds) => !priorityOrder.includes(ds.name!.toLowerCase()))
    ];

    setDataSourceOrder(order);
    return order;
  }, [projectConfiguration, dataSources, selectedDataSources, isFetchingProjectConfiguration]);

  const { mutate: postProjectConfig } = usePostProjectProjectIdConfiguration();

  function convertSelectedDataSourcesToConfigs() {
    return selectedDataSources.map((x) => {
      return {
        datasource: x.dataSource.id,
        project_configuration_id: projectConfiguration?.id,
        datasource_configuration_areas: x.state.configuration?.areas.map((area) => ({
          datasource_area_id: area.id,
          start_season: x.state.configuration!.min_season!.id
        })),
        datasource_configuration_competitions: x.state.configuration?.leagues.map((l) => ({
          datasource_competition_id: l.id,
          start_season: x.state.configuration!.min_season!.id
        })),
        datasource_configuration_endpoints: x.dataSource.datasource_endpoints?.map((e) => ({
          datasource_endpoint_id: e.id,
          datasource_configuration_id: undefined
        })),
        datasource_configuration_options: x.dataSource
          .datasource_options!.filter((o) => o.required)
          .map((o) => ({
            datasource_option_id: o.id,
            value: null // TODO: fix this at some point! currently params are stored  on backend when being checked in the previous step
          })),
        frequency: x.state.configuration!.time.id + ' ' + x.state.configuration!.refreshFrequency.id
      } as DatasourceConfigurationCreateSchema;
    });
  }

  function connectDataSources() {
    setLoading(true);

    let newProjectConfig: ProjectConfigurationCreateSchema;
    const newSelectedDataSources = convertSelectedDataSourcesToConfigs();
    const configOptions = [
      {
        project_option_id: '2bf8b9dc-424c-41fd-a06e-78478be73743',
        value: dataSourceOrder.map((dataSource) => dataSource.name!.toLowerCase()).join(', ')
      }
    ];

    if (projectConfiguration) {
      newProjectConfig = {
        ...projectConfiguration,
        id: undefined,
        project: projectConfiguration.project!,
        datasource_configurations: newSelectedDataSources,
        project_configuration_options: configOptions
      };
    } else {
      newProjectConfig = {
        ...newProjectConfigDefault,
        project: project!.id!,
        datasource_configurations: newSelectedDataSources,
        project_configuration_options: configOptions
      };
    }

    postProjectConfig(
      {
        projectId: newProjectConfig.project!,
        data: newProjectConfig
      },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({ queryKey: ['project', project.id, 'data-sources', 'count'] });
          // Project configuration is updated, so we need to refetch the project
          // When project updates, the project configuration is also updated to it is fetched in the background
          await queryClient.invalidateQueries({ queryKey: ['project', project.id], exact: true, refetchType: 'all' });

          setLoading(false);
          navigate('/data-sources/connected');
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const data = err.response?.data;
            let errorMessage: string | JSX.Element = '';
            if (data.error) {
              errorMessage = data.error;
            }
            if (data._schema) {
              const errors = data._schema as string[];
              errorMessage = (
                <div>
                  {errors.map((error, index) => (
                    <div key={index}>{error}</div>
                  ))}
                </div>
              );
            }
            toast.error(errorMessage);
          }
          setLoading(false);
        }
      }
    );
  }

  function handleCancel() {
    setOpen(false);
    setShowEditOrder(false);
  }

  function handleEditOrder() {
    setShowEditOrder(true);
    setDataSourceOrder(defaultOrder);
  }

  function cancelChangeOrder() {
    setShowEditOrder(false);
    setDataSourceOrder(defaultOrder);
  }

  return (
    <DialogBase title="Connect Data Sources" open={open} onCancel={handleCancel} narrower>
      <DialogContent>
        {showEditOrder ? (
          <div className="flex flex-col gap-3">
            <div className="flex items-center justify-between">
              <div className="text-sm font-semibold">Priority order</div>
              <button className="flex items-center justify-center gap-2" onClick={cancelChangeOrder}>
                <Cancel width={20} height={20} className="fill-brand-800" />
                <span className="text-sm font-medium text-brand-800">Cancel changes</span>
              </button>
            </div>
            <div className="bg-gray-50 p-3">
              <DataSourcePriorityOrder
                dataSourceOrder={dataSourceOrder}
                setDataSourceOrder={setDataSourceOrder}
                editing={true}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <div className="text-sm font-semibold">New data sources</div>
                <button className="flex items-center justify-center gap-2" onClick={handleEditOrder}>
                  <Change width={20} height={20} className="fill-brand-800" />
                  <span className="text-sm font-medium text-brand-800">Edit priority order</span>
                </button>
              </div>
              {defaultOrder.map((dataSource) => {
                return (
                  <div key={dataSource.id} className="rounded-md bg-gray-50 p-3">
                    <DataSourceTitle name={dataSource.name!} size="md" image={dataSource?.logo_image_path} />
                  </div>
                );
              })}
            </div>
          </>
        )}

        <div className="flex flex-col gap-6">
          <p className="flex flex-col text-sm">
            <span>Ready to activate chosen Data Sources?</span>
            <span>
              Keep in mind that data Synchronization may take up to <b>24 hours.</b>
            </span>
          </p>
          <p className="flex flex-col text-sm">
            <span>Important:</span>
            <span className="font-semibold">Once activated, you can't remove them for 30 days.</span>
            <span className="font-semibold">Confirm activation?</span>
          </p>
        </div>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="xl"
          onClick={connectDataSources}
          loading={loading || isFetchingProjectConfiguration}
        >
          Connect Data Source
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default ConnectDataSourcesDialog;
