/* eslint-disable react/jsx-no-bind */
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import Link from 'assets/link.svg?react';
import Spinner from 'assets/loading.svg?react';
import MenuContext from 'assets/menu-context.svg?react';
import { defaultPageIndex, defaultPageSize } from 'constants/tableDefaults';
import useAuth from 'contexts/auth/authContext';
import { BiConnection, ProjectGetSchema } from 'lib/model';
import {
  getProjectProjectIdSettingsBiConnectionBiConnectionIdPassword,
  useDeleteProjectProjectIdSettingsBiConnection,
  useDeleteProjectProjectIdSettingsBiConnectionBiConnectionId,
  useGetProjectProjectIdSettingsBiConnection
} from 'lib/settings/settings';
import Button from 'modules/common/Button';
import GenericTable from 'modules/common/Table/GenericTable';
import PaginationWithCount from 'modules/common/PaginationWithCount';
import { Fragment, memo, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { twJoin } from 'tailwind-merge';
import { stopPropagation } from 'utils/helpers';
import CreateConnectionDialog from './dialogs/CreateConnectionDialog';
import ViewCredentialsDialog from './dialogs/ViewCredentialsDialog';
import useActiveProject from 'contexts/project/projectContext';

const columnHelper = createColumnHelper<BiConnection>();

function BiToolIntegration() {
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const [createConnectionOpen, setCreateConnectionOpen] = useState(false);
  const [credentialsOpen, setCredentialsOpen] = useState(false);
  const { isViewer, user } = useAuth();
  if (isViewer) {
    throw new Error('403 Forbidden');
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const [credentialsPassword, setCredentialsPassword] = useState('');
  const [loadingRow, setLoadingRow] = useState('');

  const { data: connections } = useGetProjectProjectIdSettingsBiConnection(project!.id!, {
    query: { queryKey: ['biToolConnections'] }
  });
  const { mutate: deleteConnection, isPending: isConnectionBeingDeleted } =
    useDeleteProjectProjectIdSettingsBiConnectionBiConnectionId();
  const { mutate: deleteConnections, isPending: areConnectionsBeingDeleted } =
    useDeleteProjectProjectIdSettingsBiConnection();

  const pagination = {
    pageIndex: Number(searchParams.get('pageIndex') ?? defaultPageIndex),
    pageSize: Number(searchParams.get('pageSize') ?? defaultPageSize)
  };

  const columns = [
    columnHelper.display({
      id: 'select-col',
      header: ({ table }) => (
        <input
          type="checkbox"
          className={twJoin(
            'ml-6 mr-3 cursor-pointer text-brand-800 focus:ring-0',
            !table.getIsSomeRowsSelected() && 'hidden'
          )}
          checked={table.getIsAllRowsSelected()}
          ref={(input) => {
            if (input) {
              input.indeterminate = table.getIsSomeRowsSelected();
            }
          }}
          onChange={() => table.resetRowSelection(true)}
        />
      ),
      cell: ({ row }) => (
        <input
          type="checkbox"
          className="ml-6 mr-3 cursor-pointer text-brand-800 focus:ring-0"
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      )
    }),
    columnHelper.accessor('user_account', {
      id: 'user',
      cell: (info) => (
        <span className="mx-3 ml-6 text-xs font-medium">
          {info.row.original.user_firstname + ' ' + info.row.original.user_lastname}
        </span>
      ),
      header: () => (
        <span className="mx-3 ml-6 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">NAME</span>
      ),
      meta: 'w-[20%]'
    }),
    columnHelper.accessor('db_username', {
      id: 'username',
      cell: (info) => <span className="mx-3 text-xs font-medium">{info.renderValue()}</span>,
      header: () => (
        <span className="mx-3 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">USER NAME</span>
      ),
      meta: 'w-[45%]'
    }),
    columnHelper.accessor('timestamp_created', {
      id: 'created',
      cell: (info) => (
        <span className="mx-3 text-xs font-medium">{new Date(info.renderValue() as string).toLocaleDateString()}</span>
      ),
      header: () => (
        <span className="mx-3 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">CONNECTION CREATED</span>
      ),
      meta: 'w-[10%]'
    }),
    columnHelper.accessor('timestamp_last_used', {
      id: 'last_active',
      cell: (info) => <span className="mx-3 text-xs font-medium">{info.renderValue()}</span>,
      header: () => (
        <span className="mx-3 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">LAST ACTIVE</span>
      ),
      meta: 'w-[10%]'
    }),
    columnHelper.accessor('is_active', {
      id: 'status',
      cell: (info) => (
        <span className="mx-3 flex items-center justify-end gap-1 text-xs font-medium">
          {!info.row.original.credentials_shown && user.id === info.row.original.user_account ? (
            <>
              <Button
                variant="primary"
                size="tiny"
                isFullWidth={false}
                loading={loadingRow === info.row.id}
                onClick={() => showCredentials(info.row)}
              >
                Show credentials
              </Button>
              <ViewCredentialsDialog
                port={info.row.original.db_port!}
                database={info.row.original.db_host!}
                username={info.row.original.db_username!}
                password={credentialsPassword}
                open={credentialsOpen}
                setOpen={setCredentialsOpen}
              />
            </>
          ) : info.getValue() ? (
            <>
              Active <span className="size-2 rounded-full bg-green-600" />
            </>
          ) : (
            <>
              Inactive <span className="size-2 rounded-full bg-gray-400" />
            </>
          )}
        </span>
      ),
      header: () => (
        <span className="mx-3 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">STATUS</span>
      ),
      meta: 'text-end w-[15%]'
    }),
    columnHelper.display({
      id: 'action',
      cell: ({ row }) => (
        <Menu as={'div'} className="relative mx-3 mr-6 flex h-6 items-center text-left">
          <MenuButton
            onClick={stopPropagation}
            className="rounded-md p-1 hover:bg-gray-200"
            disabled={isConnectionBeingDeleted && row.id === loadingRow}
          >
            {(isConnectionBeingDeleted && row.id === loadingRow) ||
            (areConnectionsBeingDeleted && row.getIsSelected()) ? (
              <Spinner className="size-5 animate-spin fill-brand-800" />
            ) : (
              <MenuContext width={24} height={24} className="fill-gray-700" />
            )}
          </MenuButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems
              anchor="bottom end"
              className="absolute z-50 mt-2 size-fit rounded-md bg-white py-3 shadow-card focus:outline-none"
            >
              <MenuItem>
                <button
                  className="flex w-full px-4 py-2 text-sm ui-active:bg-gray-50"
                  onClick={() => removeConnectionFromTable(row)}
                >
                  Remove connection
                </button>
              </MenuItem>
            </MenuItems>
          </Transition>
        </Menu>
      )
    })
  ];

  const table = useReactTable({
    data: connections?.objects ?? [],
    columns,
    rowCount: connections?.objects?.length ?? 0,
    state: {
      pagination
    },
    onPaginationChange: (updater) => {
      let newState;
      if (updater instanceof Function) {
        newState = updater(pagination);
      } else {
        newState = updater;
      }
      searchParams.set('pageIndex', String(newState.pageIndex));
      searchParams.set('pageSize', String(newState.pageSize));
      setSearchParams(searchParams);
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    renderFallbackValue: '-'
  });

  const removeConnection = useCallback(
    function removeConnection() {
      if (table.getSelectedRowModel().rows.length > 1) {
        deleteConnections(
          {
            projectId: project!.id!,
            data: { connection_ids: table.getSelectedRowModel().rows.map((x) => x.original.id!) }
          },
          {
            onSuccess: () => {
              toast.success('Connections successfully deleted');
              queryClient.invalidateQueries({ queryKey: ['biToolConnections'] });
              setLoadingRow('');
            }
          }
        );
      } else {
        setLoadingRow(table.getSelectedRowModel().rows[0].id);
        deleteConnection(
          { projectId: project!.id!, biConnectionId: table.getSelectedRowModel().rows[0].original.id! },
          {
            onSuccess: () => {
              toast.success('Connection successfully deleted');
              queryClient.invalidateQueries({ queryKey: ['biToolConnections'] });
              setLoadingRow('');
            }
          }
        );
      }
    },
    [deleteConnection, project, table, queryClient, deleteConnections]
  );

  const removeConnectionFromTable = useCallback(
    function removeConnectionFromTable(row: Row<BiConnection>) {
      setLoadingRow(row.id);
      deleteConnection(
        { projectId: project!.id!, biConnectionId: row.original.id! },
        {
          onSuccess: () => {
            toast.success('Connection successfully deleted');
            queryClient.invalidateQueries({ queryKey: ['biToolConnections'] });
            setLoadingRow('');
          }
        }
      );
    },
    [deleteConnection, project, queryClient]
  );

  function createConnection() {
    setCreateConnectionOpen(true);
  }

  async function showCredentials(row: Row<BiConnection>) {
    setLoadingRow(row.id);
    const res = await getProjectProjectIdSettingsBiConnectionBiConnectionIdPassword(project!.id!, row.original.id!);
    setCredentialsPassword(res.password!);
    setCredentialsOpen(true);
    setLoadingRow('');
  }

  return (
    <section className="flex flex-col justify-around gap-6 pt-6">
      <div className="flex flex-wrap justify-between gap-2 overflow-x-auto px-6">
        <span className="my-auto text-sm font-medium">Connections</span>
        <div className="flex gap-4">
          <Button
            variant="secondary"
            size="sm"
            isFullWidth={false}
            disabled={table.getSelectedRowModel().flatRows.length < 1}
            onClick={removeConnection}
          >
            Remove connection
          </Button>
          <Button variant="primary" size="sm" isFullWidth={false} onClick={createConnection}>
            Create connection
          </Button>
        </div>
      </div>
      {connections?.objects?.length ? (
        <>
          <GenericTable table={table} />
          <PaginationWithCount rowCount={connections?.objects?.length} />
        </>
      ) : (
        <div className="mx-6 mb-6 flex items-center justify-center rounded-xl bg-gray-50 py-16">
          <div className="flex flex-col items-center gap-3">
            <Link className="size-16 fill-gray-300" />
            <span className="text-sm font-medium text-gray-500">No connections</span>
          </div>
        </div>
      )}
      <CreateConnectionDialog open={createConnectionOpen} setOpen={setCreateConnectionOpen} />
    </section>
  );
}

export default memo(BiToolIntegration);
