import Invited from 'assets/mail-sent.svg?react';
import User from 'assets/user.svg?react';
import { ProjectTableCell } from 'modules/admin/interfaces';
import LoadingCell from 'modules/common/Table/cells/LoadingCell';
import { useProjectUsersStatus } from '../../../hooks/useProjectUsersStatus';

const UsersCell = ({ project }: ProjectTableCell) => {
  const { activeUsers, invitedUsers, isProjectUsersPending } = useProjectUsersStatus(project.id!);
  if (isProjectUsersPending) {
    return <LoadingCell />;
  }
  return (
    <div className="mx-2 flex items-center justify-center gap-2 text-xs">
      <span className="flex items-center gap-1">
        <User className="size-5 fill-gray-700" /> {activeUsers.length}
      </span>
      <span className="flex items-center gap-1">
        <Invited className="size-5 fill-gray-700" /> {invitedUsers.length}
      </span>
    </div>
  );
};

export default UsersCell;
