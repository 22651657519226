import useAuth from "contexts/auth/authContext";
import { Navigate } from "react-router-dom";

export function RedirectUser(){
  const {user} = useAuth();
  if(user.is_admin){
    return <Navigate to="/admin" />
  }else{
    return <Navigate to="/dashboard" />
  }
}
