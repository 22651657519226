import { useMemo } from 'react';
import { snakeCaseToWords } from 'utils/helpers';
import {
  ChartEntityTooltipProps,
  EventMapTooltipProps,
  LineChartTooltipProps,
  PositionMapTooltipProps,
  RadarChartTooltipProps,
  ScatterPointTooltipProps
} from './interfaces';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function roundIfNumber(value: any, precision = 2) {
  if (typeof value === 'number') {
    return Number(value.toFixed(precision));
  }
  return value;
}

function RadarChartTooltip({ entity, metric }: RadarChartTooltipProps) {
  if (!entity) {
    return null;
  }

  return (
    <>
      <span>{entity.team_name}</span>
      {entity.player_name && <span>{entity.player_name}</span>}
      <span>
        {snakeCaseToWords(metric)}: {roundIfNumber(entity[metric])}
      </span>
    </>
  );
}

function ScatterChartTooltip({ xField, yField, entity }: ScatterPointTooltipProps) {
  if (!entity) {
    return null;
  }

  const metrics = (
    <>
      <span>
        {`${snakeCaseToWords(xField)}: `}
        {roundIfNumber(entity[xField])}
      </span>
      <span>
        {`${snakeCaseToWords(yField)}: `}
        {roundIfNumber(entity[yField])}
      </span>
    </>
  );

  return (
    <>
      <span>{entity.team_name}</span>
      {entity.player_name && <span>{entity.player_name}</span>}
      {metrics}
    </>
  );
}

function LineChartTooltip({ entity, xName, yName }: LineChartTooltipProps) {
  const entityData = useMemo(() => {
    if (!entity) {
      return '';
    }

    return (
      <>
        {entity.competition_name && <span>{entity.competition_name}</span>}
        {entity.team_name && <span>{entity.team_name}</span>}
        {entity.player_name && <span>{entity.player_name}</span>}
      </>
    );
  }, [entity]);

  if (!entity) {
    return null;
  }

  return (
    <>
      {entityData}
      <span>
        {xName}:{' '}
        {typeof entity.x_value === 'number' || typeof entity.x_value === 'string'
          ? entity.x_value
          : entity.x_value.toLocaleDateString()}
      </span>
      <span>
        {yName}: {Number(entity.y_value.toFixed(2))}
      </span>
    </>
  );
}

function PositionMapTooltip({ entity }: PositionMapTooltipProps) {
  if (!entity) {
    return null;
  }

  return (
    <span className="shrink-0">
      {entity.player_name} {entity.shirt_number}
    </span>
  );
}

function EventMapTooltip({ entity }: EventMapTooltipProps) {
  if (!entity) {
    return null;
  }

  return (
    <span className="shrink-0">
      {entity.player_name} {entity.shirt_number}
    </span>
  );
}

export function ChartEntityTooltip(props: ChartEntityTooltipProps) {
  switch (props.chart) {
    case 'scatter':
      return <ScatterChartTooltip {...props} />;
    case 'line':
      return <LineChartTooltip {...props} />;
    case 'radar':
      return <RadarChartTooltip {...props} />;
    case 'position':
      return <PositionMapTooltip {...props} />;
    case 'event':
      return <EventMapTooltip {...props} />;
    default:
      return null;
  }
}
