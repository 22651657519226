import Logo from 'assets/logo.svg?react';
import useIsMobile from 'hooks/useIsMobile';
import { memo } from 'react';
import { Link, Navigate, Outlet } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const HeadlineLayout = memo(function HeadlineLayout() {
  const isMobile = useIsMobile();

  if (localStorage.getItem('token')) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="brand-gradient flex min-h-screen w-full flex-col items-center">
      <div
        className={twMerge('sticky top-0 flex h-[72px] w-full items-center bg-white px-8', isMobile && 'bg-gray-950')}
      >
        <Link to={'/dashboard'}>
          <Logo width={86} height={28} className={isMobile ? 'fill-white' : 'fill-gray-900'} />
        </Link>
      </div>
      <div className={twMerge('flex h-full w-full flex-col items-center justify-center pt-10', isMobile && 'pt-0')}>
        <Outlet />
      </div>
    </div>
  );
});

export default HeadlineLayout;
