import Loading from 'assets/loading.svg?react';
import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
}

function Spinner({ className }: Props) {
  return <Loading className={twMerge('size-6 animate-spin fill-brand-800', className)} />;
}

export default Spinner;
