import { memo, useState } from 'react';
import { ScoutDisplayType } from '../interfaces';
import ScoutNotesData from './components/ScoutNotesData';
import ScoutNotesHeader from './components/ScoutNotesHeader';

const ScoutNotesPage = memo(function ScoutNotesPage() {
  const [display, setDisplay] = useState<ScoutDisplayType>('player');

  return (
    <>
      <ScoutNotesHeader display={display} setDisplay={setDisplay} />
      <div className="mt-6 flex w-full flex-col gap-6">
        <ScoutNotesData display={display} />
      </div>
    </>
  );
});

export default ScoutNotesPage;
