import Trash from 'assets/trash-remove.svg?react';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import { memo} from 'react';
import { DialogProps } from 'utils/interfaces';

interface Props extends Omit<DialogProps, 'setOpen'> {
  handleDelete: () => void;
  isDeleting: boolean;
  handleCancel: () => void;
  objectName: string;
}

const ConfirmDeleteDialog = memo(function DeleteReportDialog({
  open,
  handleCancel,
  handleDelete,
  isDeleting,
  objectName
}: Props) {
  return (
    <DialogBase title="Delete Report" open={open} onCancel={handleCancel} narrower>
      <DialogContent>
        <div className="flex h-36 items-center justify-center">
          <Trash className="size-16 fill-red-600" />
        </div>

        <p className="flex flex-col gap-2">
          <span className="text-sm">
            You are about to delete this: <strong className="font-semibold">{objectName}</strong>
          </span>
          <span className="text-sm">Are you sure you want to proceed with deletion?</span>
        </p>
      </DialogContent>
      <DialogFooter>
        <Button size="lg" variant="secondary" onClick={handleCancel} disabled={isDeleting}>
          Cancel
        </Button>
        <Button variant="red" size="lg" onClick={handleDelete} loading={isDeleting}>
          Delete
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default ConfirmDeleteDialog;
