import { useProjectUsersStatus } from 'modules/admin/hooks/useProjectUsersStatus';
import { ProjectTableCell } from 'modules/admin/interfaces';
import { getLastLoginDateString } from 'modules/admin/util';
import LoadingCell from 'modules/common/Table/cells/LoadingCell';

export function LastLoginCell({ project }: ProjectTableCell) {
  const { activeUsers, isProjectUsersPending } = useProjectUsersStatus(project.id!);
  if (isProjectUsersPending) {
    return <LoadingCell />;
  }

  const lastLogin = activeUsers.reduce((acc, user) => {
    if (!acc) return user.last_login;
    if (user.last_login) {
      return user.last_login > acc ? user.last_login : acc;
    }
    return acc;
  }, activeUsers[0]?.last_login);

  if (!lastLogin) {
    return null;
  }

  return <div className={'mx-2 text-xs'}>{getLastLoginDateString(lastLogin)}</div>;
}
