import { useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useActiveProject from 'contexts/project/projectContext';
import { ScoutNoteCreateSchema } from 'lib/model';
import { useUpdateProjectScoutNote } from 'lib/scout-note/scout-note';
import { ScoutNoteLayoutContext } from 'modules/layouts/scoutNotesLayout/ScoutNoteLayout';
import { Navigate, useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CREATE_SCOUT_NOTE_ROLES, DEFAULT_NUMBER_OF_SCOUT_METRICS } from '../constants';
import { loadScoutNoteFormValues, parseScoutNoteFormValues } from '../helpers';
import { ScoutNoteFormValues } from '../interfaces';
import ScoutNoteForm from '../newNote/ScoutNoteForm';
import useAuth from 'contexts/auth/authContext';
import { parseErrorMessage } from 'utils/error_handlers';

const EditScoutNotePage = () => {
  const { project } = useActiveProject();
  const { user, userRole } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { scoutNote } = useOutletContext<ScoutNoteLayoutContext>();

  const { mutate } = useUpdateProjectScoutNote({
    mutation: {
      onMutate: async (body) => {
        const loadingToastId = toast.info('Saving changes...', { autoClose: false });
        // TODO: Optimistic update
        return { loadingToastId };
      },
      onError(error, variables, context) {
        toast.dismiss(context?.loadingToastId);
        const errorMessage = parseErrorMessage(error, 'Failed to update scout note. Please try again later.');
        toast.error(errorMessage);
        // TODO: Rollback optimistic update
      },
      onSuccess(data, variables, context) {
        toast.dismiss(context?.loadingToastId);
        queryClient.setQueryData(['project', project.id, 'scout-note', data.id], data);
        queryClient.invalidateQueries({ queryKey: ['project', project.id, 'scout-notes'], exact: false });
        toast.success('Scout note updated successfully!');
        navigate(`/scout-notes/${data.id}`);
      }
    }
  });

  function onSubmit(formData: ScoutNoteFormValues) {
    if (!scoutNote) return;
    const data: ScoutNoteCreateSchema = parseScoutNoteFormValues(formData);
    mutate({ projectId: project.id!, scoutNoteId: scoutNote.id!, data });
  }

  if (!scoutNote) {
    return (
      <div className="mt-10 flex h-full w-full max-w-4xl flex-col gap-4">
        {Array.from({ length: 3 }).map((_, index) => (
          <div key={index} className="h-24 w-full animate-pulse rounded-xl bg-gray-200" />
        ))}

        {Array.from({ length: DEFAULT_NUMBER_OF_SCOUT_METRICS }).map((_, index) => (
          <div key={index} className="h-32 w-full animate-pulse rounded-xl bg-gray-200" />
        ))}
      </div>
    );
  }

  const defaultValues: Partial<ScoutNoteFormValues> = loadScoutNoteFormValues(scoutNote);
  if (!user.is_admin && !CREATE_SCOUT_NOTE_ROLES.includes(userRole!)) {
    toast.warning('You do not have permission to update a scout note', {
      toastId: 'edit-scout-note-access-permission'
    });
    Navigate({ to: `/scout-notes/${scoutNote?.id}` });
  }

  return (
    <div className="mt-10 flex w-full max-w-4xl justify-center">
      <ScoutNoteForm defaultValues={defaultValues} onSubmit={onSubmit} confirmText="Update Scout Note" />
    </div>
  );
};

export default EditScoutNotePage;
