import { UserAccountGetSchema } from 'lib/model';
import { createContext, useContext } from 'react';

export interface AuthContext {
  logout: () => void;
  user: UserAccountGetSchema;
  userRole: string | undefined;
  isViewer: boolean;
  isOwner: boolean;
  isAdmin: boolean;
}

export const AuthContext = createContext<Partial<AuthContext>>({});

export default function useAuth() {
  const context = useContext(AuthContext) as AuthContext;

  if (context === undefined) {
    throw new Error('AuthContext was used outside of AuthContextProvider');
  }

  return context;
}
