import { memo, useState } from 'react';

import { CreateReportButtonProps } from '../interfaces';

const CreateReportButton = memo(function CreateReportButton({
  title,
  description,
  Dialog,
  Icon,
  open
}: CreateReportButtonProps) {
  const [showDialog, setShowDialog] = useState<boolean>(open ?? false);

  function handleShowDialog() {
    setShowDialog(true);
  }

  return (
    <>
      {showDialog && <Dialog open={showDialog} setOpen={setShowDialog} />}
      <button
        onClick={handleShowDialog}
        className="flex w-full flex-col items-center justify-center gap-2 rounded-xl border border-gray-300 p-6"
      >
        <Icon width={32} height={32} className="fill-gray-300" />
        <div className="flex flex-col items-center gap-1">
          <span className="text-md font-semibold">{title}</span>
          <span className="text-xs">{description}</span>
        </div>
      </button>
    </>
  );
});

export default CreateReportButton;
