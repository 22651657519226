import { useQueryClient } from '@tanstack/react-query';
import useAuth from 'contexts/auth/authContext';
import { useGetProject, useGetProjectProjectId } from 'lib/project/project';
import WelcomeScreen from 'modules/layouts/sidebarLayout/components/WelcomeScreen';
import { PropsWithChildren, useMemo, useState } from 'react';
import { ProjectContext } from './projectContext';

export function ProjectProvider({ children }: PropsWithChildren) {
  const [projectId, setProjectId] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const { user } = useAuth();

  const { data: projects } = useGetProject(
    { unverified: false },
    {
      query: { queryKey: ['projects'], staleTime: Infinity, enabled: !!user.is_admin, refetchInterval: 1000 * 60 * 60 }
    }
  );

  
  const defaultProjectId = useMemo(() => {
    let projectId = null;
    const localStorageProjectId = localStorage.getItem('active_project');

    if (user.is_admin) {
      if (localStorageProjectId) {
        projectId = localStorageProjectId;
      } else if (user.user_projects && user.user_projects.length > 0) {
        projectId = user.user_projects[0].project!;
      } else if (projects?.objects) {
        projectId = projects.objects[0].id!;
      }
    } else if (user.user_projects && user.user_projects.length > 0) {
      if (localStorageProjectId && user.user_projects.some((project) => project.project === localStorageProjectId)) {
        projectId = localStorageProjectId;
      } else {
        projectId = user.user_projects[0].project!;
      }
    }

    if (projectId) {
      localStorage.setItem('active_project', projectId);
    }

    return projectId;
  }, [queryClient, user, projects]);

  const {
    isFetching: isProjectFetching,
    isPending: isProjectPending,
    data: project
  } = useGetProjectProjectId((projectId ?? defaultProjectId)!, {
    query: {
      queryKey: ['project', projectId ? projectId : defaultProjectId],
      enabled: !!projectId || !!defaultProjectId,
      staleTime: Infinity
    }
  });

  function changeProject(projectId: string) {
    localStorage.setItem('active_project', projectId);
    setProjectId(projectId);
  }

  return (
    <ProjectContext.Provider value={{ project, changeActiveProject: changeProject }}>
      {(isProjectPending || isProjectFetching) ? <WelcomeScreen /> : children}
    </ProjectContext.Provider>
  );
}
