/* eslint-disable react-refresh/only-export-components */
import { autoUpdate, size, SizeOptions, useFloating } from '@floating-ui/react-dom';
import { Button, Field, Input, Label, Portal, Transition } from '@headlessui/react';
import { useVirtualizer } from '@tanstack/react-virtual';
import { useClickAway, useDebounce } from '@uidotdev/usehooks';
import Check from 'assets/check.svg?react';
import ChevronDown from 'assets/chevron-down.svg?react';
import Loader from 'assets/loading.svg?react';
import Search from 'assets/search-eye.svg?react';
import { Fragment, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  Control,
  FieldError,
  FieldErrorsImpl,
  FieldValues,
  Merge,
  Path,
  useController,
  UseControllerProps
} from 'react-hook-form';
import { twJoin, twMerge } from 'tailwind-merge';
import { ValueOption } from 'utils/interfaces';
import ErrorMessage from '../ErrorMessage';
import InfoMessage from '../InfoMessage';
import ValueBadge from '../ValueBadge';
import { conditionallyRender } from 'utils/helpers';

interface FormProps<T extends FieldValues> extends UseControllerProps<T> {
  control: Control<T>;
  name: Path<T>;
}

interface SelectInputProps<T extends FieldValues> {
  formProps: FormProps<T>;
  label: string;
  options: ValueOption[];
  // Optional props
  loading?: boolean;
  placeholder?: string;
  disabled?: boolean;

  // Functionality props
  debounce?: number;
  searchable?: boolean;
  multiple?: boolean;
  onInputChange?: React.Dispatch<React.SetStateAction<string>>;
  infiniteQuery?: {
    hasNextPage: boolean;
    isFetchingNextPage: boolean;
    fetchNextPage: () => void;
  };

  // Error and info messages
  error?: Merge<FieldError, FieldErrorsImpl<ValueOption>>;
  info?: string;
}

function displayOption(option: ValueOption) {
  return option ? `${option.label}${conditionallyRender(!!option.secondaryLabel, `- ${option.secondaryLabel}`)}` : '';
}

function SelectInput<T extends FieldValues>({
  label,
  loading,
  placeholder,
  options,
  error,
  info,
  searchable,
  debounce = 300,
  multiple,
  disabled,
  infiniteQuery,
  onInputChange,
  formProps
}: SelectInputProps<T>) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const virtualizerRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useClickAway((e) => {
    if (e.target !== inputRef.current && !inputRef.current?.contains(e.target as Node)) {
      setOpen(false);
    }
  }) as React.RefObject<HTMLDivElement>;

  const [open, setOpen] = useState(false);
  const [internalQuery, setInternalQuery] = useState('');
  const debouncedInternalQuery = useDebounce(internalQuery, debounce);

  const {
    field: { onChange: formOnChange, value: formValue }
  } = useController(formProps);

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    strategy: 'fixed',
    middleware: [
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            minWidth: 'fit-content'
          });
        }
      } as SizeOptions)
    ]
  });

  if (searchable === undefined && options.length > 10) {
    searchable = true;
  }

  const filteredRows = useMemo(() => {
    if (onInputChange || !searchable) {
      return options ?? [];
    } else {
      return (
        options?.filter((option) => option.label.toLowerCase().includes(debouncedInternalQuery.toLowerCase())) ?? []
      );
    }
  }, [options, debouncedInternalQuery, onInputChange]);
  const noOptions = !loading && filteredRows.length === 0;

  const virtualizer = useVirtualizer({
    count: infiniteQuery?.hasNextPage ? filteredRows.length + 1 : filteredRows.length,
    getScrollElement: () => virtualizerRef.current,
    estimateSize: (index) => {
      const LARGE_SIZE = 66;
      const SMALL_SIZE = 46;
      if (index >= filteredRows.length) {
        return SMALL_SIZE;
      }
      return filteredRows[index].secondaryLabel ? LARGE_SIZE : SMALL_SIZE;
    }
  });

  // If infinite query is enabled, fetch next page when the last item is visible
  useEffect(() => {
    if (infiniteQuery) {
      const { hasNextPage, isFetchingNextPage, fetchNextPage } = infiniteQuery;
      const [lastItem] = [...virtualizer.getVirtualItems()].reverse();

      if (!lastItem || !filteredRows.length) {
        return;
      }

      if (lastItem.index >= filteredRows.length - 1 && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  }, [infiniteQuery, filteredRows.length, virtualizer.getVirtualItems()]);

  // If onInputChange is provided, update the query with the debounced internal query
  useEffect(() => {
    if (onInputChange) {
      if (multiple) {
        onInputChange(debouncedInternalQuery);
      } else if (debouncedInternalQuery === '' || debouncedInternalQuery !== displayOption(formValue as ValueOption)) {
        onInputChange(debouncedInternalQuery);
      }
    }
  }, [debouncedInternalQuery, onInputChange, formValue, multiple]);

  useEffect(() => {
    if (!multiple && (formValue === undefined || formValue === null)) {
      setInternalQuery('');
    }
  }, [formValue, multiple]);

  const handleRemove = useCallback(
    function handleRemove(removedValue: string) {
      formOnChange((formValue as ValueOption[]).filter((x) => x.label !== removedValue));
    },
    [formOnChange, formValue]
  );

  const handleInputChange = useCallback(function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setInternalQuery(value);
    if (value !== '') {
      setOpen(true);
    } else if (!multiple) {
      formOnChange(null);
    }
  }, []);

  const handleFormChange = useCallback(
    (newOption: ValueOption) => {
      // If multiple, add or remove the option from the form value
      if (multiple) {
        const newFormValue = (formValue as ValueOption[]) ?? [];
        if (newFormValue.some((value: ValueOption) => value.id === (newOption as ValueOption).id)) {
          handleRemove(newOption.label);
        } else {
          newFormValue.push(newOption);
          formOnChange(newFormValue);
        }
      } else {
        setOpen(false);
        formOnChange(newOption);
      }
    },
    [formValue, formOnChange, handleRemove, multiple]
  );

  const inputDisplayValue = useMemo(() => {
    if (!multiple) {
      return internalQuery || displayOption(formValue as ValueOption);
    }
    return internalQuery;
  }, [internalQuery, multiple, formValue]);

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="relative w-full grow">
        <div
          ref={refs.setReference}
          onClick={(e) => {
            if (!disabled) {
              setOpen(!open);
              e.stopPropagation();
            }
          }}
        >
          <Field>
            <div
              className={twMerge(
                'w-full rounded-lg border border-gray-300 bg-white text-md focus-within:border-blue-600 focus:ring-0',
                error && 'border-red-600'
              )}
              ref={inputRef}
            >
              <Label
                className={twMerge(
                  'pointer-events-none absolute start-3 top-1.5 z-10 origin-[0] translate-y-2.5 transform text-gray-500 duration-300',
                  (Boolean(placeholder) || inputDisplayValue) && '-translate-y-0 text-xs'
                )}
              >
                {label}
              </Label>
              <Input
                className={twMerge(
                  'peer h-14 w-full appearance-none rounded-lg border-none bg-white px-3 pb-1.5 pt-6 text-md placeholder:text-gray-400 focus:outline-none focus:ring-0',
                  !searchable && 'cursor-pointer caret-transparent',
                  error && 'placeholder:text-red-600',
                  disabled && 'bg-gray-50'
                )}
                disabled={disabled}
                value={inputDisplayValue}
                onChange={searchable ? handleInputChange : undefined}
                onBlur={() => {
                  if (!multiple) {
                    setInternalQuery('');
                  }
                }}
                placeholder={placeholder}
              />
              {loading ? (
                <Loader
                  aria-hidden="true"
                  className="pointer-events-none absolute inset-y-4.5 right-3 z-10 size-5 animate-spin fill-gray-700"
                />
              ) : (
                <Button
                  className="absolute right-3 top-4.5 z-10"
                  onClick={(e) => {
                    setOpen(!open);
                    e.stopPropagation();
                  }}
                >
                  <ChevronDown aria-hidden="true" width={20} height={20} className="fill-gray-700" />
                </Button>
              )}
              {multiple && formValue && formValue.length > 0 && (
                <div className="flex w-full flex-wrap gap-2 p-2">
                  {formValue.map((selectedValue: ValueOption) => (
                    <ValueBadge value={selectedValue.label} key={selectedValue.id} onRemove={handleRemove} />
                  ))}
                </div>
              )}
            </div>
          </Field>
        </div>

        {!loading && open && (
          <Portal>
            <div ref={refs.setFloating} className="z-50 w-full" style={floatingStyles}>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div ref={dropdownRef}>
                  <div
                    className="max-h-60 overflow-y-auto rounded-md bg-white py-3 shadow-card ring-0 focus:outline-none"
                    ref={virtualizerRef}
                  >
                    {noOptions ? (
                      <div className="mx-3 flex items-center justify-center gap-2 p-3">
                        <Search className="size-5 fill-gray-600" />
                        <div className="text-center text-sm">
                          Unfortunately, there are no options available for the given search input.
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`relative w-full`}
                        style={{
                          height: `${virtualizer.getTotalSize()}px`
                        }}
                      >
                        {virtualizer.getVirtualItems().map((virtualRow) => {
                          const isLoaderRow = virtualRow.index > filteredRows.length - 1;
                          if (isLoaderRow) {
                            return (
                              <div
                                key={virtualRow.index}
                                className={twJoin(
                                  'absolute left-0 top-0 w-full cursor-pointer select-none px-2 py-1 hover:bg-gray-50'
                                )}
                                style={{
                                  height: `${virtualRow.size}px`,
                                  transform: `translateY(${virtualRow.start}px)`
                                }}
                              >
                                <div className="flex w-full justify-center">
                                  <Loader
                                    aria-hidden="true"
                                    className="pointer-events-none animate-spin fill-gray-700"
                                  />
                                </div>
                              </div>
                            );
                          }
                          const option = filteredRows[virtualRow.index];
                          let activeOption = false;
                          if (multiple) {
                            activeOption = formValue?.some((value: ValueOption) => value.id === option.id) ?? false;
                          } else {
                            activeOption = formValue?.id === option.id;
                          }

                          return (
                            <div
                              key={virtualRow.index}
                              className={
                                'absolute left-0 top-0 w-full cursor-pointer select-none px-2 py-1 hover:bg-gray-50'
                              }
                              style={{
                                height: `${virtualRow.size}px`,
                                transform: `translateY(${virtualRow.start}px)`
                              }}
                              onClick={() => {
                                handleFormChange(option);
                              }}
                            >
                              <div
                                className={twJoin(
                                  'flex items-center justify-between gap-3 rounded-md px-1.5 py-2',
                                  activeOption && 'bg-brand-50'
                                )}
                              >
                                <div className="flex items-center gap-2">
                                  <div className="flex flex-col">
                                    <span
                                      className={twJoin(
                                        'block truncate text-sm font-medium',
                                        activeOption && 'font-semibold text-brand-800'
                                      )}
                                    >
                                      {option.label}
                                    </span>
                                    {option.secondaryLabel && (
                                      <span
                                        className={twMerge(
                                          'block truncate text-xs text-gray-500',
                                          activeOption && 'font-semibold text-brand-800'
                                        )}
                                      >
                                        {option.secondaryLabel}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <Check
                                  width={20}
                                  height={20}
                                  className={twMerge('hidden fill-brand-800', activeOption && 'inline')}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </Transition>
            </div>
          </Portal>
        )}
      </div>
      {error && <ErrorMessage error={error} />}
      {info && <InfoMessage info={info} />}
    </div>
  );
}

export default memo(SelectInput) as typeof SelectInput;
