import { FieldValues, useController } from 'react-hook-form';
import SelectInputBase from '../components/SelectInputBase';
import { SelectInputFormProps } from '../interfaces';
import { memo } from 'react';

function SelectFormInput<T extends FieldValues>({ formProps, error, ...rest }: SelectInputFormProps<T>) {
  const {
    field: { onChange: formOnChange, value: formValue }
  } = useController(formProps);

  return <SelectInputBase {...rest} error={undefined} inputValue={formValue} onValueChange={formOnChange} />;
}

export default memo(SelectFormInput);
