import {
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  PaginationState,
  Row,
  useReactTable
} from '@tanstack/react-table';
import Note from 'assets/draft.svg?react';
import Jersey from 'assets/jersey.svg?react';
import Plus from 'assets/plus.svg?react';
import Rating from 'assets/star-full.svg?react';
import { PlayerScoutNotes } from 'lib/model';
import Button from 'modules/common/Button';
import RowTable from 'modules/common/Table/RowTable';
import useScoutMetrics from 'modules/scoutNotes/hooks/useScoutMetrics';
import { ScoutNoteByPlayerDisplayProps } from 'modules/scoutNotes/interfaces';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ScoutNotesList from './ScoutNotesList';

const columnHelper = createColumnHelper<PlayerScoutNotes>();

function PlayerNotes({ row }: { row: Row<PlayerScoutNotes> }) {
  const notes = row.original.notes;
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5
  });
  return (
    <div className="-mt-3 rounded-b-2xl bg-gray-200 px-6">
      <ScoutNotesList
        data={notes}
        pagination={{
          pagination,
          setPagination,
          rowCount: notes?.length ?? 0
        }}
      />
    </div>
  );
}

function ScoutNotesPlayerList({ data, loading }: ScoutNoteByPlayerDisplayProps) {
  const { overall } = useScoutMetrics();
  const navigate = useNavigate();

  const columns = useMemo<ColumnDef<PlayerScoutNotes>[]>(() => {
    return [
      columnHelper.accessor('player_name', {
        cell: ({ getValue }) => {
          const name = getValue();
          return (
            <div className="flex items-center gap-3">
              <div className="flex size-10 items-center justify-center rounded-md bg-gray-50 p-2">
                <Jersey className="fill-gray-600" />
              </div>
              <div className="flex flex-col gap-1">
                <span className="text-xs font-semibold">{name}</span>
              </div>
            </div>
          );
        }
      }),
      columnHelper.display({
        id: 'overall',
        cell: ({ row }) => {
          // Calculate the average rating for the overall metric
          const notes = row.original.notes;
          let rating = 'N/A';
          if (notes) {
            const overallRating =
              notes.reduce((rating, note) => {
                const metric = note.ratings?.find((metric) => metric.scout_metric === overall?.id);
                return rating + (metric?.rating ?? 0);
              }, 0) / notes.length;

            rating = overallRating.toFixed(2);
          }

          return (
            <div className="flex items-center gap-2">
              <Rating className="fill-amber-400" />
              <span className="text-xs font-semibold">{rating}</span>
            </div>
          );
        }
      }),
      columnHelper.accessor('notes', {
        cell: ({ getValue }) => {
          const notes = getValue();

          return (
            <div className="flex items-center gap-2">
              <Note className="fill-gray-600" />
              <span className="text-xs font-semibold">{notes?.length ?? 0}</span>
            </div>
          );
        }
      }),
      columnHelper.display({
        id: 'last_edited',
        cell: ({ row }) => {
          const notes = row.original.notes;
          let lastUpdated = 'N/A';
          if (notes) {
            const lastDate = notes.reduce((acc, note) => {
              if (!acc) return note.date_last_updated;
              if (note.date_last_updated) {
                return note.date_last_updated > acc ? note.date_last_updated : acc;
              }
              return acc;
            }, notes[0].date_last_updated)!;
            const date = new Date(lastDate);
            lastUpdated = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
          }

          return (
            <div className="flex items-center gap-2">
              <span className="text-tiny font-medium text-gray-500">LAST UPDATED:</span>
              <span className="text-xs font-medium">{lastUpdated}</span>
            </div>
          );
        }
      }),
      columnHelper.display({
        id: 'actions',
        cell: (info) => {
          return (
            <div className="flex gap-2">
              <Button
                variant="secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate('/reports/new', { state: { player: info.row.original, open: 'player' } });
                }}
              >
                <Plus className="size-4" />
                New report
              </Button>
              <Button
                variant="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate('/scout-notes/new', { state: { player: info.row.original } });
                }}
              >
                <Plus className="size-4" />
                New note
              </Button>
            </div>
          );
        }
      })
    ];
  }, [overall]);

  const table = useReactTable({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowCanExpand: ({ original }) => !!original.notes,
    getExpandedRowModel: getExpandedRowModel(),
    renderFallbackValue: '-'
  });

  return (
    <RowTable
      table={table}
      loading={loading || !overall}
      SubComponent={PlayerNotes}
      onRowClick={(row) => {
        if (row.getCanExpand()) {
          row.toggleExpanded();
        }
      }}
    />
  );
}

export default ScoutNotesPlayerList;
