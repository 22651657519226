import useActiveProject from 'contexts/project/projectContext';
import { PlayerQuerySchema } from 'lib/model';
import {
  useGetPlayersSeasonMetrics,
  useGetReportGetPlayerPositions,
  useGetReportGetPlayersInfinite,
  useGetReportGetPlayerSubpositions
} from 'lib/report/report';
import Fieldset from 'modules/common/Form/Fieldset';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { getMetricsListFromSeasons } from 'modules/reports/reportPage/components/dialogs/helpers';
import { useMemo, useState } from 'react';
import { Control, useFormState, useWatch } from 'react-hook-form';
import { playerToAutocompleteOption, stringToValueOption, subpositionToValueOption } from 'utils/mappings';
import { ScoutReportFormValues } from '../../interfaces';

interface Props {
  control: Control<ScoutReportFormValues>;
}

function SimilarPlayersFieldset({ control }: Props) {
  const [query, setQuery] = useState<string>('');
  const { project } = useActiveProject();

  const formState = useFormState({ control, name: 'metric' });
  const player = useWatch({ control, name: 'similarPlayer' });
  const seasons = useWatch({ control, name: 'seasons' });
  const position = useWatch({
    control: control,
    name: 'position'
  });

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage, status } = useGetReportGetPlayersInfinite(
    {
      player_name: query,
      player_position: position?.id as string,
      project_id: project.id
    },
    {
      query: {
        queryKey: ['players', project.id, query, position?.id],
        staleTime: 1000 * 60 * 5,
        initialPageParam: 0,
        getNextPageParam: (lastPage, pages, lastPageParam) => {
          if (!lastPage.players || lastPage.players?.length < 100) {
            return undefined;
          }

          return lastPageParam ? lastPageParam + 1 : 1;
        }
      }
    }
  );

  const { data: metricsData, isFetching: isFetchingMetrics } = useGetPlayersSeasonMetrics(
    { project_id: project.id!, 'players[]': player?.id ? [player.id as string] : undefined },
    {
      query: {
        queryKey: ['playerSeasonMetrics', project.id, player?.id],
        staleTime: Infinity,
        enabled: !!player?.id
      }
    }
  );

  const playerPositions = useGetReportGetPlayerPositions(
    { project_id: project.id! },
    {
      query: {
        queryKey: ['playerPositions', project.id],
        staleTime: Infinity
      }
    }
  );
  const playerSubPositions = useGetReportGetPlayerSubpositions(
    { project_id: project.id! },
    {
      query: {
        queryKey: ['playerSubPositions', project.id],
        staleTime: Infinity
      }
    }
  );

  const metricSeasons = metricsData?.metrics ? Object.keys(metricsData.metrics) : [];

  const metrics = useMemo(() => {
    if (!metricsData?.metrics) {
      return [];
    }

    const metricsBySeason = metricsData.metrics;
    const allMetrics = getMetricsListFromSeasons(seasons, metricsBySeason, true);
    return allMetrics;
  }, [metricsData, seasons]);

  const players: PlayerQuerySchema[] = useMemo(() => {
    const allPlayers = (data ? data.pages.flatMap((d) => d.players) : []) as PlayerQuerySchema[];
    return allPlayers;
  }, [data]);

  const playerPositionOptions = useMemo(() => playerPositions.data?.map(stringToValueOption) ?? [], [playerPositions]);
  const playerSubPositionOptions = useMemo(
    () =>
      !position
        ? []
        : (playerSubPositions.data?.positions
            ?.filter((subpos) => subpos.primary_position! === position.id)
            .map(subpositionToValueOption) ?? []),
    [playerSubPositions, position]
  );

  // TODO: Filter active metrcis when seasons changed

  return (
    <Fieldset legend="Similar Players">
      <SelectInput
        formProps={{
          control: control,
          name: 'position',
          rules: {
            required: false
          }
        }}
        label="Player Position"
        options={playerPositionOptions}
        loading={playerPositions.isPending}
        error={formState.errors.position}
      />
      <SelectInput
        formProps={{
          control: control,
          name: 'subPositions'
        }}
        multiple
        label="Player Sub Positions"
        disabled={!position}
        options={playerSubPositionOptions}
        loading={playerSubPositions.isPending}
      />
      <SelectInput
        loading={status === 'pending'}
        label={'Player'}
        placeholder="Start typing: Player name / Team / Player country"
        options={players.map(playerToAutocompleteOption)}
        onInputChange={setQuery}
        searchable
        infiniteQuery={{
          hasNextPage: hasNextPage,
          fetchNextPage: fetchNextPage,
          isFetchingNextPage: isFetchingNextPage
        }}
        formProps={{
          name: 'similarPlayer',
          rules: { required: { value: true, message: 'Player is required.' } },
          control: control
        }}
        error={formState.errors.similarPlayer}
      />

      <div className="flex flex-col gap-3">
        <span className="text-md font-medium">Player metrics</span>
        <p className="text-sm">
          Similar players will be based on the similarity to the chosen
          <b className="font-semibold"> metrics</b>
        </p>
      </div>
      <SelectInput
        formProps={{
          control: control,
          name: 'seasons'
        }}
        multiple
        label={'Seasons'}
        loading={isFetchingMetrics}
        placeholder="All Seasons"
        options={metricSeasons.map(stringToValueOption)}
        error={formState.errors.seasons}
      />
      <SelectInput
        formProps={{
          control: control,
          name: 'similarMetrics'
        }}
        multiple
        label="Metric Criteria"
        placeholder="All metrics"
        options={metrics.map(stringToValueOption)}
        loading={isFetchingMetrics}
        disabled={metrics.length === 0}
        error={formState.errors.similarMetrics}
      />
    </Fieldset>
  );
}

export default SimilarPlayersFieldset;
