/* eslint-disable react/jsx-no-bind */
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Column,
  ColumnDef,
  Row,
  SortingState,
  Table,
  createColumnHelper,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table';
import ArrowLeft from 'assets/arrow-left.svg?react';
import ArrowRight from 'assets/arrow-right.svg?react';
import Change from 'assets/change.svg?react';
import Clear from 'assets/close-circle.svg?react';
import Filter from 'assets/filter.svg?react';
import List from 'assets/list-framed.svg?react';
import MenuContext from 'assets/menu-context.svg?react';
import { defaultDataTablePageSize, defaultPageIndex } from 'constants/tableDefaults';
import useAuth from 'contexts/auth/authContext';
import { getProjectProjectIdDataTableDataTableIdDownload } from 'lib/client-data/client-data';
import {
  ClientDataSchema,
  ClientDataSchemaObjectsItem,
  DataTablesSchema,
  ProjectDataColumn,
  ProjectGetSchema
} from 'lib/model';
import Button from 'modules/common/Button';
import Tooltip from 'modules/common/Tooltip';
import { Fragment, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { twJoin } from 'tailwind-merge';
import { downloadCSV, stopPropagation } from 'utils/helpers';
import GenericTable from '../../common/Table/GenericTable';
import PaginationWithCount from '../../common/PaginationWithCount';
import DuplicatesDialog from './dialogs/DuplicatesDialog';
import IncorrectDataDialog from './dialogs/IncorrectDataDialog';
import FiltersDialog from './dialogs/filters/DataTableFiltersDialog';
import { useGetProjectProjectIdDashboardDatasourcesCount } from 'lib/dashboard/dashboard';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import useActiveProject from 'contexts/project/projectContext';

interface DataTableProps {
  dataTableColumns: ProjectDataColumn[];
  data?: ClientDataSchema;
  isDataFetching: boolean;
}

const columnHelper = createColumnHelper<ClientDataSchemaObjectsItem>();

const DataTable = memo(function DataTable({ dataTableColumns, data, isDataFetching }: DataTableProps) {
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const dataTables = queryClient.getQueryData<DataTablesSchema>(['project', project.id, 'data-tables'])!;
  const { isViewer } = useAuth();
  const { dataTableId } = useParams();
  const tableData = data?.objects;
  const [showDuplicatesDialog, setShowDuplicatesDialog] = useState<boolean>(false);
  const [showIncorrectDataDialog, setShowIncorrectDataDialog] = useState<boolean>(false);
  const [showFiltersDialog, setShowFiltersDialog] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [csvPending, setCsvPending] = useState(false);

  const { data: dataSourcesCount } = useGetProjectProjectIdDashboardDatasourcesCount(project.id!, {
    query: { queryKey: ['project', project.id, 'data-sources', 'count'], enabled: !!project.id, staleTime: Infinity }
  });
  const useDummyData = dataSourcesCount?.count === 0 || dataTables.dummy_data;

  function handleShowDuplicatesDialog() {
    setShowDuplicatesDialog(true);
  }

  function handleShowIncorrectDataDialog() {
    setShowIncorrectDataDialog(true);
  }

  function handleShowFiltersDialog() {
    setShowFiltersDialog(true);
  }

  const handleReportIncorrectDataFromTable = useCallback(function handleReportIncorrectDataFromTable(
    row: Row<ClientDataSchemaObjectsItem>,
    table: Table<ClientDataSchemaObjectsItem>
  ) {
    table.setRowSelection(() => ({ [row.index]: true }));
    handleShowIncorrectDataDialog();
  }, []);

  const sortColumn = useCallback(function sortColumn(column: Column<ClientDataSchemaObjectsItem, unknown>) {
    if (!column.getIsSorted()) {
      column.toggleSorting(true, true);
    } else if (column.getIsSorted() === 'desc') {
      column.toggleSorting(false, true);
    } else {
      column.clearSorting();
    }
  }, []);

  const dataColumns = useMemo<ColumnDef<ClientDataSchemaObjectsItem>[]>(
    () =>
      dataTableColumns
        .filter((x) => x.visible)
        .map((col) =>
          columnHelper.accessor(col.column_name!, {
            id: col.column_name,
            cell: (info) => (
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              <span className="mx-3 text-xs font-medium">{info.renderValue() as any}</span>
            ),
            header: ({ column }) => (
              <div className="flex items-center" onClick={() => sortColumn(column)}>
                <span className="whitespace-nowrap pl-3 pr-1 text-tiny font-medium uppercase text-gray-500">
                  {col.display_name}
                </span>
                {column.getCanSort() && column.getIsSorted() ? (
                  column.getIsSorted() === 'asc' ? (
                    <ArrowRight className="size-3 rotate-90 fill-brand-800" />
                  ) : (
                    <ArrowLeft className="size-3 rotate-90 fill-brand-800" />
                  )
                ) : (
                  <Change className="size-3 rotate-90 fill-gray-300" />
                )}
              </div>
            ),
            meta: 'cursor-pointer'
          })
        ),
    [dataTableColumns, sortColumn]
  );

  const columns = useMemo<ColumnDef<ClientDataSchemaObjectsItem>[]>(
    () => [
      columnHelper.display({
        id: 'select-col',
        header: ({ table }) => (
          <input
            type="checkbox"
            className={twJoin(
              'ml-6 mr-3 cursor-pointer text-brand-800 focus:ring-0',
              !table.getIsSomeRowsSelected() && 'hidden'
            )}
            checked={table.getIsAllRowsSelected()}
            // https://css-tricks.com/indeterminate-checkboxes/
            // indeterminate can't be set through html
            ref={(input) => {
              if (input) {
                input.indeterminate = table.getIsSomeRowsSelected();
              }
            }}
            onChange={() => table.resetRowSelection(true)}
          />
        ),
        cell: ({ row }) => (
          <input
            type="checkbox"
            className="ml-6 mr-3 cursor-pointer text-brand-800 focus:ring-0"
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        )
      }),
      ...dataColumns,
      columnHelper.display({
        id: 'actions',
        cell: ({ row, table }) => (
          <Menu as={'div'} className="relative mx-3 mr-6 flex h-6 items-center text-left">
            <MenuButton onClick={stopPropagation} className="rounded-md p-1 hover:bg-gray-200">
              <MenuContext width={24} height={24} className="fill-gray-700" />
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems
                anchor="bottom end"
                className="absolute z-50 mt-2 size-fit rounded-md bg-white py-3 shadow-card focus:outline-none"
              >
                <MenuItem>
                  <button
                    className="flex w-full px-4 py-2 text-sm ui-active:bg-gray-50"
                    onClick={() => handleReportIncorrectDataFromTable(row, table)}
                  >
                    Report incorrect data
                  </button>
                </MenuItem>
              </MenuItems>
            </Transition>
          </Menu>
        )
      })
    ],
    [dataColumns, handleReportIncorrectDataFromTable]
  );

  const pagination = {
    pageIndex: Number(searchParams.get('pageIndex')!),
    pageSize: Number(searchParams.get('pageSize')!)
  };

  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data: tableData ?? [],
    columns,
    rowCount: data?.row_count ?? undefined,
    state: {
      sorting,
      pagination,
      columnPinning: {
        left: ['select-col'],
        right: ['actions']
      }
    },
    onPaginationChange: (updater) => {
      let newState;
      if (updater instanceof Function) {
        newState = updater(pagination);
      } else {
        newState = updater;
      }
      searchParams.set('pageIndex', String(newState.pageIndex));
      searchParams.set('pageSize', String(newState.pageSize));
      setSearchParams(searchParams);
    },
    onSortingChange: (updater) => {
      let newState;
      if (updater instanceof Function) {
        newState = updater(sorting);
      } else {
        newState = updater;
      }
      setSorting(newState);
      searchParams.set(
        'orderBy',
        JSON.stringify(newState.reduce((o, column) => ({ ...o, [column.id]: column.desc ? 'desc' : 'asc' }), {}))
      );
      setSearchParams(searchParams);
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    enableMultiRemove: true,
    enableSortingRemoval: true,
    isMultiSortEvent: (_) => true,
    renderFallbackValue: '-'
  });

  async function handleExport() {
    try {
      setCsvPending(true);
      const csvData = await getProjectProjectIdDataTableDataTableIdDownload(project!.id!, dataTableId!, {
        filters: searchParams.get('filters') ?? ''
      });
      downloadCSV(csvData, dataTables.objects?.find((x) => x.id === dataTableId)?.display_name + '.csv');
      setCsvPending(false);
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data.error ?? 'Failed to export data.';
        toast.error(error);
      }
      setCsvPending(false);
    }
  }

  useEffect(() => {
    table.resetRowSelection();
  }, [table, tableData]);

  useEffect(() => {
    setSorting([]);
  }, [dataTableColumns]);

  const clearFilters = useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.delete('filters');
        newParams.set('pageIndex', String(defaultPageIndex));
        newParams.set('pageSize', String(defaultDataTablePageSize));
        return newParams;
      });
    },
    [setSearchParams]
  );

  return (
    <>
      {showIncorrectDataDialog && (
        <IncorrectDataDialog
          table={table}
          open={showIncorrectDataDialog}
          setOpen={setShowIncorrectDataDialog}
          dataTableColumns={dataTableColumns}
        />
      )}
      {showDuplicatesDialog && (
        <DuplicatesDialog
          table={table}
          open={showDuplicatesDialog}
          setOpen={setShowDuplicatesDialog}
          dataTableColumns={dataTableColumns}
        />
      )}
      <FiltersDialog
        open={showFiltersDialog}
        setOpen={setShowFiltersDialog}
        dataTableColumns={dataTableColumns}
        key={searchParams.get('filters')} // resets FiltersDialog on clear
      />
      <div className="flex flex-wrap items-center justify-between gap-6 bg-white p-6">
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium">Total Rows: {data?.row_count ?? 0}</span>
        </div>
        <div className="flex flex-wrap items-center gap-4">
          <Tooltip message="Select only one item in the table." background="" padding="">
            <Button
              variant="secondary"
              size="sm"
              isFullWidth={false}
              onClick={handleShowIncorrectDataDialog}
              disabled={table.getSelectedRowModel().flatRows.length !== 1}
            >
              Report Incorrect Data
            </Button>
          </Tooltip>
          <Tooltip message="Select 2 or more items in the table." background="" padding="">
            <Button
              variant="secondary"
              size="sm"
              isFullWidth={false}
              disabled={table.getSelectedRowModel().flatRows.length < 2}
              onClick={handleShowDuplicatesDialog}
            >
              Report Duplicates
            </Button>
          </Tooltip>
          {!(isViewer || useDummyData) && (
            <Button
              variant="secondary"
              size="sm"
              isFullWidth={false}
              onClick={handleExport}
              loading={csvPending}
              disabled={table.getPreSelectedRowModel().rows.length === 0}
            >
              Export
            </Button>
          )}
          <Button
            variant="secondary"
            size="sm"
            isFullWidth={false}
            onClick={handleShowFiltersDialog}
            disabled={!data || data.row_count === undefined}
          >
            <Filter width={16} height={16} />
            <span>Filter</span>
            {Boolean(searchParams.get('filters')) && <Clear width={20} height={20} onClick={clearFilters} />}
          </Button>
        </div>
      </div>
      {!isDataFetching && (!data?.objects || data.objects.length === 0 || dataTableColumns.length === 0) ? (
        <div className="m-8 flex flex-grow flex-col items-center justify-center gap-4 rounded-lg bg-gray-50">
          <List className="size-24 fill-gray-300" />
          <span className="text-lg font-medium text-gray-400">No available data</span>
        </div>
      ) : (
        <>
          <GenericTable table={table} />
          <PaginationWithCount rowCount={data?.row_count} />
        </>
      )}
    </>
  );
});

export default DataTable;
