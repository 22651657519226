import { ScoutMetric, ScoutNoteRating } from 'lib/model';
import Divider from 'modules/common/Divider';
import { isEditorStateEmpty } from 'modules/common/Editor/util';
import Slider from 'modules/common/Form/Slider/Slider';
import { SCOUT_RATING_MAX } from 'modules/scoutNotes/constants';

import ScoutFormRatingNote from 'modules/scoutNotes/newNote/components/ScoutFormRatingNote';

interface Props {
  rating: ScoutNoteRating;
  metric: ScoutMetric;
}

export const ScoutNoteRatingSkeleton = () => {
  return (
    <div className="flex flex-col gap-3 rounded-xl bg-white p-6">
      <div className="h-5 w-full animate-pulse rounded-md bg-gray-100" />
      <Divider />
      <div className="h-5 w-full animate-pulse rounded-md bg-gray-100" />
      <div className="h-2 w-full animate-pulse rounded-md bg-gray-100" />
      <div className="h-6 w-full animate-pulse rounded-md bg-gray-100" />
    </div>
  );
};

const ScoutRatingCard = ({ rating, metric }: Props) => {
  if (!rating || !metric) {
    return <ScoutNoteRatingSkeleton />;
  }

  const isNoteEmpty = isEditorStateEmpty(rating.formatted_text);

  return (
    <div className="flex flex-col gap-3 rounded-xl bg-white p-6">
      <header className="flex flex-col gap-4">
        <h4 className="text-sm font-medium">{metric.name}</h4>
        <Divider />
      </header>
      <div className="text-sm text-gray-500">{metric.description}</div>
      <Slider defaultValue={rating.rating} step={0.1} min={0} max={SCOUT_RATING_MAX} disabled />
      {!isNoteEmpty && <ScoutFormRatingNote editable={false} rating={rating} />}
    </div>
  );
};

export default ScoutRatingCard;
