import { MenuItem, MenuItemProps } from '@headlessui/react';
import { PropsWithChildren, useCallback } from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  stopPropagation?: boolean;
  className?: string;
}

type DropdownItemProps = MenuItemProps & Props;

function DropdownMenuItem({
  children,
  stopPropagation = true,
  onClick,
  className = 'flex w-full px-4 py-1.5 text-sm ui-active:bg-gray-50',
  disabled,
  ...rest
}: PropsWithChildren<DropdownItemProps>) {
  const handleClick = useCallback(
    function handleRemove(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
      if (stopPropagation) {
        e.stopPropagation();
      }
      if (onClick) {
        onClick(e);
      }
    },
    [stopPropagation]
  );

  return (
    <MenuItem disabled={disabled}>
      <div className={className} onClick={handleClick} {...rest}>
        {children}
      </div>
    </MenuItem>
  );
}

export default DropdownMenuItem;
