import { memo } from 'react';
import ElementComparisonRow from '../ElementComparisonRow';
import PlayerComparisonHeader from './PlayerComparisonHeader';

import { SCOUT_RATING_MAX } from 'modules/scoutNotes/constants';
import { PlayerComparisonGeneralData } from '../interfaces';

const PlayerComparisonElement = memo(function PlayerComparisonElement({
  player1,
  player2
}: PlayerComparisonGeneralData) {
  const p1ScoutNotes = player1.scout_ratings;
  const p2ScoutNotes = player2.scout_ratings;

  return (
    <div className="flex flex-col gap-6">
      <PlayerComparisonHeader player1={player1} player2={player2} textSize="sm" iconSize="md" />
      <div className="flex flex-col gap-2">
        {(p1ScoutNotes || p2ScoutNotes) && (
          <ElementComparisonRow>
            {p1ScoutNotes ? (
              <span>{`${p1ScoutNotes.average.toFixed(2)}/${SCOUT_RATING_MAX} (${p1ScoutNotes.count} notes)`}</span>
            ) : (
              <span>-</span>
            )}
            <span className="text-tiny uppercase">AVERAGE SCOUT RATING</span>
            {p2ScoutNotes ? (
              <span>{`${p2ScoutNotes.average.toFixed(2)}/${SCOUT_RATING_MAX} (${p2ScoutNotes.count} notes)`}</span>
            ) : (
              <span>-</span>
            )}
          </ElementComparisonRow>
        )}
        <ElementComparisonRow>
          <span>{player1.age ?? '-'}</span>
          <span className="text-tiny uppercase">AGE</span>
          <span>{player2.age ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.birth_date ?? '-'}</span>
          <span className="text-tiny uppercase">BIRTH DATE</span>
          <span>{player2.birth_date ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.height ?? '-'}</span>
          <span className="text-tiny uppercase">HEIGHT</span>
          <span>{player2.height ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.weight ?? '-'}</span>
          <span className="text-tiny uppercase">WEIGHT</span>
          <span>{player2.weight ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.position_name ?? '-'}</span>
          <span className="text-tiny uppercase">POSITION</span>
          <span>{player2.position_name ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.team_name ?? '-'}</span>
          <span className="text-tiny uppercase">TEAM</span>
          <span>{player2.team_name ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.country ?? '-'}</span>
          <span className="text-tiny uppercase">NATIONALITY</span>
          <span>{player2.country ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{player1.value ?? '-'}</span>
          <span className="text-tiny uppercase">VALUE</span>
          <span>{player2.value ?? '-'}</span>
        </ElementComparisonRow>
      </div>
      {(player1.current_ability ||
        player1.potential_ability ||
        player1.expected_potential_ability ||
        player2.current_ability ||
        player2.potential_ability ||
        player2.expected_potential_ability) && (
        <div className="flex flex-col gap-2">
          <div className="flex w-full items-center justify-center rounded-md p-3 text-sm font-medium">
            Player Scores
          </div>
          <ElementComparisonRow>
            <span className="text-xs font-bold">{player1.current_ability ?? '-'}</span>
            <span className="text-xs font-semibold uppercase">CURRENT ABILITY</span>
            <span className="text-xs font-bold">{player2.current_ability ?? '-'}</span>
          </ElementComparisonRow>
          <ElementComparisonRow>
            <span className="text-xs font-semibold">{player1.potential_ability ?? '-'}</span>
            <span className="text-xs font-semibold uppercase">POTENTIAL ABILITY</span>
            <span className="text-xs font-semibold">{player2.potential_ability ?? '-'}</span>
          </ElementComparisonRow>
          <ElementComparisonRow>
            <span className="text-xs font-semibold">{player1.expected_potential_ability ?? '-'}</span>
            <span className="text-xs font-semibold uppercase">EXPECTED POTENTIAL ABILITY</span>
            <span className="text-xs font-semibold">{player2.expected_potential_ability ?? '-'}</span>
          </ElementComparisonRow>
        </div>
      )}
    </div>
  );
});

export default PlayerComparisonElement;
