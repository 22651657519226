import useActiveProject from 'contexts/project/projectContext';
import { ScoutMetric } from 'lib/model';
import { useGetProjectScoutMetrics } from 'lib/scout-metric/scout-metric';
import { ScoutNoteLayoutContext } from 'modules/layouts/scoutNotesLayout/ScoutNoteLayout';
import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { DEFAULT_NUMBER_OF_SCOUT_METRICS } from '../constants';
import { sortScoutMetrics } from '../helpers';
import ScoutNoteBasicInfo, { ScoutNoteBasicInfoSkeleton } from './components/ScoutNoteBasicInfo';
import ScoutNoteHeader, { ScoutNoteHeaderSkeleton } from './components/ScoutNoteHeader';
import ScoutRatingCard, { ScoutNoteRatingSkeleton } from './components/ScoutRatingCard';

const ScoutNotePage = () => {
  const { project } = useActiveProject();
  const { scoutNote, loading } = useOutletContext<ScoutNoteLayoutContext>();

  const { data: scoutMetricsData, isFetching: isFetchingMetrics } = useGetProjectScoutMetrics(project.id!, {
    query: {
      queryKey: ['project', project.id, 'scout-metrics'],
      staleTime: Infinity
    }
  });

  const metrics = useMemo(() => {
    const metrics = {} as Record<string, ScoutMetric>;
    const scoutMetrics = scoutMetricsData?.objects;
    if (scoutMetrics) {
      return scoutMetrics.reduce((acc, metric) => {
        if (metric.id && !acc[metric.id]) {
          acc[metric.id] = metric;
        }
        return acc;
      }, metrics);
    }
    return metrics;
  }, [scoutMetricsData]);

  const scoutRatings = useMemo(() => {
    const ratings = scoutNote?.ratings ?? [];

    return ratings.sort((a, b) => {
      if (!a.scout_metric || !b.scout_metric) {
        return 0;
      }
      const metricA = metrics[a.scout_metric];
      const metricB = metrics[b.scout_metric];
      if (metricA && metricB) {
        return sortScoutMetrics(metricA, metricB);
      }
      return 0;
    });
  }, [scoutNote, metrics]);

  if (!scoutNote || isFetchingMetrics || loading) {
    return (
      <div className="flex w-full flex-col">
        <ScoutNoteHeaderSkeleton />
        <div className="relative flex gap-6 max-lg:flex-col">
          <ScoutNoteBasicInfoSkeleton />
          <div className="flex w-full flex-col gap-4">
            {Array.from({ length: DEFAULT_NUMBER_OF_SCOUT_METRICS }).map((rating, index) => (
              <ScoutNoteRatingSkeleton key={index} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col">
      <ScoutNoteHeader scoutNote={scoutNote} />
      <div className="relative flex gap-6 max-lg:flex-col">
        <ScoutNoteBasicInfo scoutNote={scoutNote} />
        <div className="flex w-full flex-col gap-4">
          {scoutRatings.map((rating, index) => (
            <ScoutRatingCard key={index} rating={rating} metric={metrics[rating.scout_metric!]} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScoutNotePage;
