import User from 'assets/user.svg?react';
import useAuth from 'contexts/auth/authContext';
import { useGetAdminProjectsAwsCost } from 'lib/admin/admin';
import { ProjectAwsCost, ReportSchema } from 'lib/model';
import { useGetProject } from 'lib/project/project';
import { useGetReport } from 'lib/report/report';
import { useMemo } from 'react';
import DashboardAdminSummary from './DashboardAdminSummary';
import ProjectsTable from './table/ProjectsTable';

const DashboardAdmin = () => {
  const { user } = useAuth();

  // Data fetching
  const { data: projectData } = useGetProject(undefined, {
    query: { queryKey: ['projects'], enabled: !!user.is_admin, staleTime: Infinity }
  });

  const { data: awsData } = useGetAdminProjectsAwsCost({
    query: { queryKey: ['admin', 'projects', 'aws-cost'], enabled: !!user.is_admin, staleTime: Infinity }
  });

  const { data: reportsData } = useGetReport(
    {},
    {
      query: {
        queryKey: ['reports'],
        staleTime: Infinity,
        retry: false,
        refetchOnWindowFocus: false
      }
    }
  );

  const projects = useMemo(() => {
    if (!projectData?.objects) return [];

    const reports = reportsData?.objects ?? [];
    const awsCosts = awsData?.objects ?? [];

    const reportsByProject = reports.reduce(
      (acc, report: ReportSchema) => {
        const projectId = report.project!;
        if (!acc[projectId]) {
          acc[projectId] = [report];
        } else {
          acc[projectId].push(report);
        }
        return acc;
      },
      {} as { [key: string]: ReportSchema[] }
    );

    const costsByProject = awsCosts.reduce(
      (acc, aws: ProjectAwsCost) => {
        const projectId = aws.id!;
        if (!acc[projectId]) {
          acc[projectId] = aws;
        }
        return acc;
      },
      {} as { [key: string]: ProjectAwsCost }
    );

    const projects = projectData.objects.map((project) => {
      const projectId = project.id!;
      const projectReports = reportsByProject[projectId] ?? [];
      const projectAwsCost = costsByProject[projectId];
      return { ...project, reports: projectReports, 'aws-cost': projectAwsCost };
    });

    return projects;
  }, [projectData, reportsData, awsData]);

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex items-center justify-between gap-6">
        <div className="flex items-center gap-3">
          <User className="size-5 fill-gray-700" />
          <span className="text-sm font-semibold">Admin Dashboard</span>
        </div>
      </div>
      <DashboardAdminSummary />
      <div>
        <ProjectsTable tableData={projects} />
      </div>
    </div>
  );
};

export default DashboardAdmin;
