import { useQueryClient } from '@tanstack/react-query';
import { useSelectedDataSources } from 'contexts/selectedDataSourcesContext';
import useIsMobile from 'hooks/useIsMobile';
import { DatasourceConfigurationsNestedSchema, DatasourceSchema, DatasourcesSchema } from 'lib/model';
import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import { DialogProps, ValueOption } from 'utils/interfaces';
import Button from '../../../common/Button';
import DataSourceTitle from '../../../common/DataSourceTitle';
import DialogBase from '../../../common/Dialog/DialogBase';
import DialogContent from '../../../common/Dialog/DialogContent';
import DialogFooter from '../../../common/Dialog/DialogFooter';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import useActiveProject from 'contexts/project/projectContext';

interface ReplaceDataSourceDialogProps extends DialogProps {
  oldDataSource: DatasourceSchema;
}

interface ReplaceDataSourceFromValues {
  newDataSource: ValueOption | null;
}

const defaultReplaceDataSourceFromValues = {
  newDataSource: null
};

const ReplaceDataSourceDialog = memo(function ReplaceDataSourceDialog({
  open,
  setOpen,
  oldDataSource
}: ReplaceDataSourceDialogProps) {
  const isMobile = useIsMobile();
  const { setSelectedDataSources } = useSelectedDataSources();
  const {
    handleSubmit,
    control,
    formState: { isValid: isFormValid }
  } = useForm<ReplaceDataSourceFromValues>({
    defaultValues: defaultReplaceDataSourceFromValues
  });
  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const dataSources = queryClient.getQueryData<DatasourcesSchema>(['dataSources'])!;
  const dataSourceConfigs = queryClient.getQueryData<DatasourceConfigurationsNestedSchema>([
    'project',
    project.id,
    'configuration',
    project.project_configuration_latest,
    'datasource-configuration'
  ]);
  const dataSourceOptions: ValueOption[] =
    dataSources?.objects
      ?.filter((ds) => ds.id !== oldDataSource.id && !dataSourceConfigs?.objects?.some((x) => x.datasource === ds.id))
      .map((ds) => ({
        label: ds.name!,
        id: ds.id!
      })) ?? [];

  const replaceDataSource = useCallback(
    function replaceDataSource(data: ReplaceDataSourceFromValues) {
      if (data.newDataSource === null) {
        return;
      }
      // there is only 1 selected, so replace it
      setSelectedDataSources([
        {
          dataSource: dataSources!.objects!.find((ds) => ds.id === data.newDataSource!.id)!,
          state: {
            authenticated: false,
            configured: false,
            expanded: true
          }
        }
      ]);

      setOpen(false);
    },
    [setOpen, setSelectedDataSources, dataSources]
  );

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  return (
    <DialogBase title="Replace Data Source" onCancel={handleCancel} open={open}>
      <DialogContent>
        <form
          className={twJoin('flex w-full gap-6', isMobile && 'flex-col')}
          id="replace-data-source-form"
          onSubmit={handleSubmit(replaceDataSource)}
        >
          <div className="flex w-full flex-col gap-4">
            <span className="text-sm font-semibold">Replace this Data Source</span>
            <div className="flex h-14 items-center justify-center bg-gray-50 p-3">
              <DataSourceTitle name={oldDataSource.name!} image={oldDataSource?.logo_image_path} />
            </div>
          </div>
          <div className="flex w-full flex-col gap-4">
            <span className="text-sm font-semibold">With:</span>
            <SelectInput
              formProps={{
                control: control,
                name: 'newDataSource',
                rules: { required: { value: true, message: 'You must select a data source.' } }
              }}
              label="Choose a Data Source"
              options={dataSourceOptions}
            />
          </div>
        </form>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" isFullWidth={true} onClick={handleCancel}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton={true} form="replace-data-source-form" disabled={!isFormValid}>
          <span>Replace Data Source</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default ReplaceDataSourceDialog;
