import useActiveProject from 'contexts/project/projectContext';
import { useGetProjectScoutNotes, useGetScoutNotesByPlayer } from 'lib/scout-note/scout-note';
import Pagination from 'modules/common/Pagination';
import { ScoutDisplayType } from 'modules/scoutNotes/interfaces';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import ScoutNotesEmpty from './display/ScoutNotesEmpty';
import ScoutNotes from './ScoutNotes';
import ScoutNotesByPlayer from './ScoutNotesByPlayer';

interface Props {
  display: ScoutDisplayType;
}

function ScoutNotesData({ display }: Props) {
  const { project } = useActiveProject();

  const [searchParams] = useSearchParams();
  const pageSize = searchParams.get('pageSize')!;
  const pageIndex = searchParams.get('pageIndex')!;
  const filters = searchParams.get('filters') ?? undefined;

  const { data: groupedData, isFetching: isFetchingByPlayer } = useGetScoutNotesByPlayer(
    project.id!,
    { filters: filters, page: Number(pageIndex) + 1, per_page: Number(pageSize) ?? 12 },
    {
      query: {
        queryKey: ['project', project.id, 'scout-notes', 'grouped', filters, pageIndex, pageSize],
        staleTime: Infinity
      }
    }
  );

  const { data: scoutNotesData, isFetching: isFetchingNotes } = useGetProjectScoutNotes(
    project.id!,
    { filters: filters, page: Number(pageIndex) + 1, per_page: Number(pageSize) ?? 12 },
    {
      query: {
        queryKey: ['project', project.id, 'scout-notes', filters, pageIndex, pageSize],
        staleTime: Infinity
      }
    }
  );

  const { rowCount, renderComponent, isEmpty } = useMemo(() => {
    switch (display) {
      case 'list':
      case 'grid':
        return {
          isEmpty: !isFetchingNotes && scoutNotesData?.objects?.length === 0,
          rowCount: scoutNotesData?.row_count ?? 0,
          renderComponent: (
            <ScoutNotes display={display} data={scoutNotesData?.objects ?? []} loading={isFetchingNotes} />
          )
        };
      case 'player':
        return {
          isEmpty: !isFetchingByPlayer && groupedData?.objects?.length === 0,
          rowCount: groupedData?.row_count ?? 0,
          renderComponent: <ScoutNotesByPlayer data={groupedData?.objects ?? []} loading={isFetchingByPlayer} />
        };
      default: {
        // Add check to ensure we captured all cases
        const _exhaustiveCheck: never = display;
        return _exhaustiveCheck;
      }
    }
  }, [groupedData, isFetchingByPlayer, scoutNotesData, isFetchingNotes, display]);

  return (
    <>
      {isEmpty ? <ScoutNotesEmpty /> : renderComponent}
      <Pagination rowCount={rowCount} />
    </>
  );
}

export default ScoutNotesData;
