import DashboardAdmin from 'modules/admin/components/DashboardAdmin';
import DashboardHeader from 'modules/dashboard/components/DashboardHeader';

export function AdminPage() {
  return (
    <div className="flex w-full flex-col items-center">
      <DashboardHeader />
      <DashboardAdmin />
    </div>
  );
}
