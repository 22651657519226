import { useQueryClient } from '@tanstack/react-query';
import useAuth from 'contexts/auth/authContext';
import { useGetDatasource } from 'lib/datasource/datasource';
import { DatasourceSchema, ProjectConfigurationNestedSchema, ProjectConfigurationOptionSchema } from 'lib/model';
import {
  useGetProjectProjectIdConfigurationProjectConfigurationId,
  useUpdateProjectConfigurationPriorityOrder
} from 'lib/project-configuration/project-configuration';
import { useGetProjectProjectId } from 'lib/project/project';
import Button from 'modules/common/Button';
import Divider from 'modules/common/Divider';
import DataSourcePriorityOrder from 'modules/dataSources/components/DataSourcePriorityOrder';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import Edit from 'assets/edit.svg?react';
import useActiveProject from 'contexts/project/projectContext';
import { AxiosError } from 'axios';

const PriorityOrderCard = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  // STATES
  const [editing, setEditing] = useState(false);
  const [dataSourceOrder, setDataSourceOrder] = useState<DatasourceSchema[]>([]);

  // QUERIES
  const { project } = useActiveProject();

  const { data: projectConfiguration, isFetching: isProjectConfigurationFetching } =
    useGetProjectProjectIdConfigurationProjectConfigurationId<ProjectConfigurationNestedSchema>(
      project!.id!,
      project!.project_configuration_latest!,
      { nested: true },
      {
        query: {
          queryKey: ['project', project.id, 'configuration', project.project_configuration_latest],
          staleTime: Infinity,
          enabled: !!project && !!project.project_configuration_latest
        }
      }
    );

  const { data: dataSources, isPending: isDataSourcesPending } = useGetDatasource(undefined, {
    query: { queryKey: ['dataSources'], staleTime: Infinity }
  });

  const { mutate: updatePriorityOrder, isPending: isUpdatingPriorityOrder } =
    useUpdateProjectConfigurationPriorityOrder({
      mutation: {
        onMutate(variables) {
          const loadingToastId = toast.info('Saving changes...', { autoClose: false });
          const prevConfig = projectConfiguration;

          // Optimistic update
          queryClient.setQueryData(
            ['project', project.id, 'configuration', project.project_configuration_latest],
            (prev: ProjectConfigurationNestedSchema) => {
              return {
                ...prev,
                project_configuration_options: prev.project_configuration_options!.map((option) => {
                  if (option.ssm_parameter_name === 'priority_order') {
                    return { ...option, value: variables.data.priority_order };
                  }
                  return option;
                })
              };
            }
          );

          return { loadingToastId, prev: prevConfig };
        },
        onSuccess: (data, variables, context) => {
          toast.dismiss(context?.loadingToastId);
          toast.success('Priority order has been successfully updated');
          setEditing(false);
        },
        onError: (err, variables, context) => {
          toast.dismiss(context?.loadingToastId);
          let errorMessage = 'Failed to update priority order. Please try again later.';

          if (err instanceof AxiosError) {
            errorMessage = err.response?.data?.error || errorMessage;
          }
          // Rollback to the previous state
          queryClient.setQueryData(
            ['project', project.id, 'configuration', project.project_configuration_latest],
            context?.prev
          );
          setDataSourceOrder(defaultOrder);
          toast.error(errorMessage);
        }
      }
    });

  const defaultOrder = useMemo(() => {
    if (isProjectConfigurationFetching || isDataSourcesPending) {
      return [];
    }

    const priorityOrderOption = projectConfiguration!.project_configuration_options!.find(
      (option) => option.ssm_parameter_name === 'priority_order'
    ) as ProjectConfigurationOptionSchema;

    if (!priorityOrderOption || !dataSources?.objects) {
      return [];
    }

    const priorityOrder = priorityOrderOption.value!.split(',').map((name) => name.trim());

    const sortedDataSources = dataSources.objects
      .filter((dataSource) => priorityOrder.includes(dataSource.name!.toLowerCase()))
      .sort((a, b) => priorityOrder.indexOf(a.name!.toLowerCase()) - priorityOrder.indexOf(b.name!.toLowerCase()));

    setDataSourceOrder(sortedDataSources);
    return sortedDataSources;
  }, [projectConfiguration, dataSources]);

  if (isProjectConfigurationFetching || isDataSourcesPending) {
    return (
      <div className="flex flex-col items-center justify-center gap-4 rounded-xl bg-white p-4">
        <div className="grid h-36 w-full grid-cols-1 gap-4">
          <div className="animate-pulse rounded-md bg-gray-50" />
          <div className="animate-pulse rounded-md bg-gray-50" />
          <div className="animate-pulse rounded-md bg-gray-50" />
        </div>
      </div>
    );
  }

  function saveChanges() {
    updatePriorityOrder({
      projectId: project!.id!,
      projectConfigurationId: project!.project_configuration_latest!,
      data: { priority_order: dataSourceOrder.map((dataSource) => dataSource.name!.toLowerCase()).join(', ') }
    });
  }

  function handleEdit() {
    setEditing(true);
  }

  function cancelEditing() {
    setDataSourceOrder(defaultOrder);
    setEditing(false);
  }

  return (
    <div className="flex flex-col gap-4 rounded-xl bg-white p-4">
      <header className="flex items-center justify-between pt-2">
        <span className="text-sm font-semibold">Priority Order</span>
        {!editing && (
          <button className="flex items-center gap-2 text-xs font-semibold text-brand-800" onClick={handleEdit}>
            <span>Edit</span>
            <Edit className="size-4 fill-brand-800" />
          </button>
        )}
      </header>
      <Divider />
      <DataSourcePriorityOrder
        dataSourceOrder={dataSourceOrder}
        setDataSourceOrder={setDataSourceOrder}
        editing={editing}
        disabled={isUpdatingPriorityOrder}
      />
      {editing && (
        <div className="mt-2 flex gap-2">
          <Button variant="secondary" onClick={cancelEditing}>
            Cancel
          </Button>
          <Button onClick={saveChanges}>Save</Button>
        </div>
      )}
    </div>
  );
};

export default PriorityOrderCard;
