import Back from 'assets/arrow-left.svg?react';
import useAuth from 'contexts/auth/authContext';
import { ScoutNote, UserAccountGetSchema } from 'lib/model';
import Button from 'modules/common/Button';
import CircleImage from 'modules/common/CircleImage';
import DropdownMenuItem from 'modules/common/Dropdown/DropdownMenuItem';
import DropdownWrapper from 'modules/common/Dropdown/DropdownWrapper';
import DeleteNoteDialog from 'modules/scoutNotes/components/DeleteNoteDialog';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  scoutNote: ScoutNote;
}

export const ScoutNoteHeaderSkeleton = () => {
  return (
    <div className="flex h-28 w-full gap-1 pb-8 pt-10">
      <div className="h-full w-1/4 animate-pulse rounded-md bg-gray-200" />
      <div className="h-full w-full animate-pulse rounded-md bg-gray-200" />
      <div className="h-full w-1/3 animate-pulse rounded-md bg-gray-200" />
    </div>
  );
};

function getEditPermission(user: UserAccountGetSchema, userRole: string, note: ScoutNote) {
  if (user.is_admin) return true;
  
  switch (userRole) {
    case 'admin':
    case 'owner':
    case 'head_scout':
      return true;
    case 'scout_viewer':
      return false;
    case 'scout':
      return note.user_email === user.email;
    default:
      return false;
  }
}

const ScoutNoteHeader = ({ scoutNote }: Props) => {
  const { user, userRole } = useAuth();
  const canEdit = getEditPermission(user!, userRole!, scoutNote);

  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();
  function handleBack() {
    navigate('/scout-notes');
  }

  function closeDialog() {
    setShowDialog(false);
  }

  // In case of missing data show loading skeleton instead of crashing
  if (!scoutNote) {
    return <ScoutNoteHeaderSkeleton />;
  }

  const owner = scoutNote.user_full_name!;
  const nameData = owner.split(' ');
  const firstName = nameData[0];
  const lastName = nameData[nameData.length - 1];
  return (
    <>
      <DeleteNoteDialog note={scoutNote} open={showDialog} closeDialog={closeDialog} />
      <header className="sticky top-0 z-40 bg-gray-100 pb-8 pt-10">
        <div className="flex flex-wrap items-center justify-between gap-6 max-md:flex-col max-md:items-start">
          <div className="flex items-center gap-2">
            <Back className="size-6 shrink-0 cursor-pointer fill-gray-950" onClick={handleBack} />

            <div className="flex flex-col">
              <div className="text-tiny font-medium text-gray-950">Scout Note</div>
              <span className="text-lg font-semibold text-gray-950">{scoutNote?.note_name}</span>
            </div>
          </div>
          <div className="flex flex-wrap items-center justify-end gap-8 self-end">
            <div className="flex items-center gap-2 px-3 py-2.5">
              <span className="text-tiny font-medium text-gray-500">OWNER:</span>
              <CircleImage image={undefined} text={firstName.charAt(0) + lastName.charAt(0)} size="size-5" />
              <span className="text-xs font-medium">{scoutNote.user_full_name}</span>
            </div>
            {canEdit && (
              <div className="flex flex-wrap items-center gap-4">
                {/* {report?.owner_user === user.id && (
                <Button
                  size="lg"
                  variant="secondary"
                  isFullWidth={false}
                >
                  Transfer
                </Button>
              )} */}
                <Button
                  size="lg"
                  variant="secondary"
                  isFullWidth={false}
                  onClick={() => navigate(`/scout-notes/${scoutNote.id}/edit`)}
                >
                  Edit
                </Button>
                <DropdownWrapper>
                  <DropdownMenuItem
                    onClick={() => {
                      setShowDialog(true);
                    }}
                  >
                    Delete
                  </DropdownMenuItem>
                </DropdownWrapper>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default ScoutNoteHeader;
