/* eslint-disable @typescript-eslint/no-explicit-any */
import { flexRender } from '@tanstack/react-table';
import { BaseTableProps } from './interfaces';

export default function GenericDisplayTable<T,>({ table }: BaseTableProps<T>) {
  return (
    <div className="grid grid-cols-cards overflow-x-auto">
      <table className="w-full border-separate border-spacing-y-2">
        <thead>
          <tr className="h-12">
            {table.getHeaderGroups().flatMap((headerGroup) => {
              return headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className="whitespace-nowrap bg-white"
                    align={(header.column.columnDef.meta as any)?.align ?? 'left'}
                  >
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                );
              });
            })}
          </tr>
        </thead>
        <tbody className="gap-2 bg-transparent">
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id} className="h-12">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      align={(cell.column.columnDef.meta as any)?.align ?? 'left'}
                      className="whitespace-nowrap bg-gray-50 text-sm font-medium text-gray-900 first:rounded-l-md first:pl-3 last:rounded-r-md last:pr-3"
                      key={cell.id}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
