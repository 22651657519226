import { ReportNestedSchema, ReportNestedSchemaReportType } from 'lib/model';
import { SCOUT_REPORT_VARIANT } from 'modules/reports/newReport/components/Dialogs/NewScoutReportDialog/constants';
import { memo } from 'react';
import { LeagueGeneralData, MatchGeneralData, PlayerGeneralData, ScoutPlayerData, TeamGeneralData } from './interfaces';
import LeagueElement from './League/LeagueElement';
import MatchElement from './Match/MatchElement';
import PlayerElement from './Player/PlayerElement';
import PlayerComparisonElement from './PlayerComparison/PlayerComparisonElement';
import ReportInfoSkeleton from './ReportElementSkeleton';
import ScoutElement from './Scout/ScoutElement';
import TeamElement from './Team/TeamElement';
import TeamComparisonElement from './TeamComparison/TeamComparisonElement';

interface ReportTypeElementProps {
  report?: ReportNestedSchema;
}

const ReportTypeElement = memo(function ReportTypeElement({ report }: ReportTypeElementProps) {
  if (!report) {
    return <ReportInfoSkeleton />;
  }

  switch (report.report_type) {
    case ReportNestedSchemaReportType.match:
      return <MatchElement match={report.general_data as MatchGeneralData} />;
    case ReportNestedSchemaReportType.player:
      return <PlayerElement player={report.general_data as PlayerGeneralData} />;
    case ReportNestedSchemaReportType.player_comparison:
      return (
        <PlayerComparisonElement
          player1={report.general_data!.player1 as PlayerGeneralData}
          player2={report.general_data!.player2 as PlayerGeneralData}
        />
      );
    case ReportNestedSchemaReportType.team:
      return <TeamElement team={report.general_data as TeamGeneralData} />;
    case ReportNestedSchemaReportType.team_comparison:
      return (
        <TeamComparisonElement
          team1={report.general_data!.team1 as TeamGeneralData}
          team2={report.general_data!.team2 as TeamGeneralData}
        />
      );
    case ReportNestedSchemaReportType.scout: {
      const variant = report.scout_report_settings?.variant;
      let metrics: string[] = [];
      if (variant === SCOUT_REPORT_VARIANT.similar) {
        metrics = report.scout_report_settings?.similar_metrics ?? [];
      }
      return (
        <>
          {variant === SCOUT_REPORT_VARIANT.similar && (
            <div className="text-sm">
              <b className="font-semibold">Metrics considered for player similarity:</b>{' '}
              {metrics.length > 0 ? metrics.join(', ') : 'All'}
            </div>
          )}
          <ScoutElement
            filteredPlayers={report.general_data!.players_filtered as ScoutPlayerData[]}
            manualPlayers={report.general_data!.players_manual as ScoutPlayerData[]}
          />
        </>
      );
    }
    case ReportNestedSchemaReportType.league:
      return <LeagueElement league={report.general_data as LeagueGeneralData} />;
    default:
      return null;
  }
});

export default ReportTypeElement;
