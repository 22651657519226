import { DefaultConfigurationFormValues } from 'constants/defaultValues';
import { dataSourceRefreshFrequencyOptions } from 'constants/formOptions';
import { CRONTimeOptions } from 'constants/generated';
import { DatasourceConfigurationNestedSchema, DatasourceSchema } from 'lib/model';
import {
  getMinimumStartSeasonFromDataSourceConfig,
  getSelectedAreasFromDataSourceConfig,
  getSelectedLeaguesFromDataSourceConfig
} from 'utils/helpers';

export function extractDatasourceConfigOptions(
  dataSource: DatasourceSchema,
  configuration: DatasourceConfigurationNestedSchema
) {
  return {
    areas: getSelectedAreasFromDataSourceConfig(configuration, dataSource) ?? DefaultConfigurationFormValues.areas,
    leagues:
      getSelectedLeaguesFromDataSourceConfig(configuration, dataSource) ?? DefaultConfigurationFormValues.leagues,
    min_season: getMinimumStartSeasonFromDataSourceConfig(configuration) ?? DefaultConfigurationFormValues.min_season,
    refreshFrequency:
      dataSourceRefreshFrequencyOptions.find((x) => x.id === configuration.frequency!.substring(4).trimStart()) ??
      DefaultConfigurationFormValues.refreshFrequency,
    time:
      CRONTimeOptions.find((x) => x.id === configuration?.frequency!.substring(0, 4).trimEnd()) ??
      DefaultConfigurationFormValues.time
  };
}
