/**
 * Generated by orval v6.30.2 🍺
 * Do not edit manually.
 * Q-ANT web API
 * OpenAPI spec version: 1.0.0
 */
import type {
  InfiniteData,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import {
  default as deleteReportReportIdElementElementIdMutator,
  default as deleteReportReportIdMutator,
  default as deleteReportReportIdScheduleMutator,
  default as deleteReportReportIdUsersMutator,
  default as getPlayerMatchesMutator,
  default as getPlayersSeasonMetricsMutator,
  default as getReportColumnExistsMutator,
  default as getReportElementTemplatesMutator,
  default as getReportFavoriteMutator,
  default as getReportGetAllTeamsMutator,
  default as getReportGetCompetitionsMutator,
  default as getReportGetCountriesMutator,
  default as getReportGetMatchesMutator,
  default as getReportGetPlayerMetricsMutator,
  default as getReportGetPlayerPositionsMutator,
  default as getReportGetPlayerSubpositionsMutator,
  default as getReportGetPlayersMutator,
  default as getReportGetTeamsMutator,
  default as getReportLastEditedMutator,
  default as getReportMutator,
  default as getReportRefreshCacheMutator,
  default as getReportReportIdElementElementIdMutator,
  default as getReportReportIdElementElementIdPublicMutator,
  default as getReportReportIdMutator,
  default as getReportReportIdPublicMutator,
  default as getReportReportIdScheduleMutator,
  default as getReportReportIdUsersMutator,
  default as getReportSearchPlayersMutator,
  default as postReportMutator,
  default as postReportReportIdElementMutator,
  default as postReportReportIdScheduleMutator,
  default as postReportReportIdSendMutator,
  default as postReportReportIdUsersMutator,
  default as putReportReportIdElementElementIdMutator,
  default as putReportReportIdElementElementIdPositionMutator,
  default as putReportReportIdMutator,
  default as putReportReportIdScheduleMutator,
  default as putReportReportIdToggleFavoriteMutator,
  default as putReportReportIdUsersMutator
} from '../../api/axios';
import type {
  DeleteReportReportIdElementElementIdParams,
  GetPlayerMatchesParams,
  GetPlayersSeasonMetricsParams,
  GetReportColumnExistsParams,
  GetReportElementTemplatesParams,
  GetReportGetAllTeams200Item,
  GetReportGetAllTeamsParams,
  GetReportGetCompetitions200Item,
  GetReportGetCompetitionsParams,
  GetReportGetCountriesParams,
  GetReportGetMatchesParams,
  GetReportGetPlayerMetricsParams,
  GetReportGetPlayerPositionsParams,
  GetReportGetPlayerSubpositionsParams,
  GetReportGetPlayersParams,
  GetReportGetTeamsParams,
  GetReportLastEditedParams,
  GetReportParams,
  GetReportReportIdElementElementIdPublicParams,
  GetReportReportIdPublicParams,
  GetReportSearchPlayersParams,
  MatchesQuerySchema,
  MatchesSchema,
  PlayersQuerySchema,
  PostReportBody,
  PostReportReportIdSendParams,
  PutReportReportIdElementElementIdPositionParams,
  ReportAccessCreateSchema,
  ReportAccessDeleteSchema,
  ReportAccessesCreateSchema,
  ReportAccessesSchema,
  ReportEditSchema,
  ReportElementCreateSchema,
  ReportElementEditSchema,
  ReportElementSchema,
  ReportElementTemplatesSchema,
  ReportMetricsSchema,
  ReportNestedSchema,
  ReportSendScheduleCreateSchema,
  ReportSendScheduleSchema,
  ReportsSchema,
  SeasonMetricsSchema,
  SubPositionsSchema,
  TeamsQuerySchema
} from '.././model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Gets all of the reports a user has access to
 */
export const getReport = (
  params?: GetReportParams,
  options?: SecondParameter<typeof getReportMutator>,
  signal?: AbortSignal
) => {
  return getReportMutator<ReportsSchema>({ url: `/report`, method: 'GET', params, signal }, options);
};

export const getGetReportQueryKey = (params?: GetReportParams) => {
  return [`/report`, ...(params ? [params] : [])] as const;
};

export const getGetReportQueryOptions = <TData = Awaited<ReturnType<typeof getReport>>, TError = unknown>(
  params?: GetReportParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReport>>, TError, TData>>;
    request?: SecondParameter<typeof getReportMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReport>>> = ({ signal }) =>
    getReport(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReport>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportQueryResult = NonNullable<Awaited<ReturnType<typeof getReport>>>;
export type GetReportQueryError = unknown;

/**
 * @summary Gets all of the reports a user has access to
 */
export const useGetReport = <TData = Awaited<ReturnType<typeof getReport>>, TError = unknown>(
  params?: GetReportParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReport>>, TError, TData>>;
    request?: SecondParameter<typeof getReportMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Creates a new report
 */
export const postReport = (postReportBody: PostReportBody, options?: SecondParameter<typeof postReportMutator>) => {
  return postReportMutator<ReportNestedSchema>(
    { url: `/report`, method: 'POST', headers: { 'Content-Type': 'application/json' }, data: postReportBody },
    options
  );
};

export const getPostReportMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postReport>>, TError, { data: PostReportBody }, TContext>;
  request?: SecondParameter<typeof postReportMutator>;
}): UseMutationOptions<Awaited<ReturnType<typeof postReport>>, TError, { data: PostReportBody }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postReport>>, { data: PostReportBody }> = (props) => {
    const { data } = props ?? {};

    return postReport(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostReportMutationResult = NonNullable<Awaited<ReturnType<typeof postReport>>>;
export type PostReportMutationBody = PostReportBody;
export type PostReportMutationError = unknown;

/**
 * @summary Creates a new report
 */
export const usePostReport = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postReport>>, TError, { data: PostReportBody }, TContext>;
  request?: SecondParameter<typeof postReportMutator>;
}): UseMutationResult<Awaited<ReturnType<typeof postReport>>, TError, { data: PostReportBody }, TContext> => {
  const mutationOptions = getPostReportMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Checks if a column exists in players table used for scout reports
 */
export const getReportColumnExists = (
  params: GetReportColumnExistsParams,
  options?: SecondParameter<typeof getReportColumnExistsMutator>,
  signal?: AbortSignal
) => {
  return getReportColumnExistsMutator<boolean>(
    { url: `/report/column-exists`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportColumnExistsQueryKey = (params: GetReportColumnExistsParams) => {
  return [`/report/column-exists`, ...(params ? [params] : [])] as const;
};

export const getGetReportColumnExistsQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportColumnExists>>,
  TError = unknown
>(
  params: GetReportColumnExistsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportColumnExists>>, TError, TData>>;
    request?: SecondParameter<typeof getReportColumnExistsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportColumnExistsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportColumnExists>>> = ({ signal }) =>
    getReportColumnExists(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportColumnExists>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportColumnExistsQueryResult = NonNullable<Awaited<ReturnType<typeof getReportColumnExists>>>;
export type GetReportColumnExistsQueryError = unknown;

/**
 * @summary Checks if a column exists in players table used for scout reports
 */
export const useGetReportColumnExists = <TData = Awaited<ReturnType<typeof getReportColumnExists>>, TError = unknown>(
  params: GetReportColumnExistsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportColumnExists>>, TError, TData>>;
    request?: SecondParameter<typeof getReportColumnExistsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportColumnExistsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the report element templates
 */
export const getReportElementTemplates = (
  params: GetReportElementTemplatesParams,
  options?: SecondParameter<typeof getReportElementTemplatesMutator>,
  signal?: AbortSignal
) => {
  return getReportElementTemplatesMutator<ReportElementTemplatesSchema>(
    { url: `/report/element-templates`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportElementTemplatesQueryKey = (params: GetReportElementTemplatesParams) => {
  return [`/report/element-templates`, ...(params ? [params] : [])] as const;
};

export const getGetReportElementTemplatesQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportElementTemplates>>,
  TError = unknown
>(
  params: GetReportElementTemplatesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportElementTemplates>>, TError, TData>>;
    request?: SecondParameter<typeof getReportElementTemplatesMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportElementTemplatesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportElementTemplates>>> = ({ signal }) =>
    getReportElementTemplates(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportElementTemplates>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportElementTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getReportElementTemplates>>>;
export type GetReportElementTemplatesQueryError = unknown;

/**
 * @summary Gets all of the report element templates
 */
export const useGetReportElementTemplates = <
  TData = Awaited<ReturnType<typeof getReportElementTemplates>>,
  TError = unknown
>(
  params: GetReportElementTemplatesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportElementTemplates>>, TError, TData>>;
    request?: SecondParameter<typeof getReportElementTemplatesMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportElementTemplatesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the favorite reports
 */
export const getReportFavorite = (options?: SecondParameter<typeof getReportFavoriteMutator>, signal?: AbortSignal) => {
  return getReportFavoriteMutator<ReportsSchema>({ url: `/report/favorite`, method: 'GET', signal }, options);
};

export const getGetReportFavoriteQueryKey = () => {
  return [`/report/favorite`] as const;
};

export const getGetReportFavoriteQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportFavorite>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportFavorite>>, TError, TData>>;
  request?: SecondParameter<typeof getReportFavoriteMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportFavoriteQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportFavorite>>> = ({ signal }) =>
    getReportFavorite(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportFavorite>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportFavoriteQueryResult = NonNullable<Awaited<ReturnType<typeof getReportFavorite>>>;
export type GetReportFavoriteQueryError = unknown;

/**
 * @summary Gets all of the favorite reports
 */
export const useGetReportFavorite = <
  TData = Awaited<ReturnType<typeof getReportFavorite>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportFavorite>>, TError, TData>>;
  request?: SecondParameter<typeof getReportFavoriteMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportFavoriteQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the teams
 */
export const getReportGetAllTeams = (
  params?: GetReportGetAllTeamsParams,
  options?: SecondParameter<typeof getReportGetAllTeamsMutator>,
  signal?: AbortSignal
) => {
  return getReportGetAllTeamsMutator<GetReportGetAllTeams200Item[]>(
    { url: `/report/get-all-teams`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportGetAllTeamsQueryKey = (params?: GetReportGetAllTeamsParams) => {
  return [`/report/get-all-teams`, ...(params ? [params] : [])] as const;
};

export const getGetReportGetAllTeamsQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportGetAllTeams>>,
  TError = unknown
>(
  params?: GetReportGetAllTeamsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetAllTeams>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetAllTeamsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportGetAllTeamsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportGetAllTeams>>> = ({ signal }) =>
    getReportGetAllTeams(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportGetAllTeams>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportGetAllTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getReportGetAllTeams>>>;
export type GetReportGetAllTeamsQueryError = unknown;

/**
 * @summary Gets all of the teams
 */
export const useGetReportGetAllTeams = <TData = Awaited<ReturnType<typeof getReportGetAllTeams>>, TError = unknown>(
  params?: GetReportGetAllTeamsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetAllTeams>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetAllTeamsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportGetAllTeamsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the competitions
 */
export const getReportGetCompetitions = (
  params?: GetReportGetCompetitionsParams,
  options?: SecondParameter<typeof getReportGetCompetitionsMutator>,
  signal?: AbortSignal
) => {
  return getReportGetCompetitionsMutator<GetReportGetCompetitions200Item[]>(
    { url: `/report/get-competitions`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportGetCompetitionsQueryKey = (params?: GetReportGetCompetitionsParams) => {
  return [`/report/get-competitions`, ...(params ? [params] : [])] as const;
};

export const getGetReportGetCompetitionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportGetCompetitions>>,
  TError = unknown
>(
  params?: GetReportGetCompetitionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetCompetitions>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetCompetitionsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportGetCompetitionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportGetCompetitions>>> = ({ signal }) =>
    getReportGetCompetitions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportGetCompetitions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportGetCompetitionsQueryResult = NonNullable<Awaited<ReturnType<typeof getReportGetCompetitions>>>;
export type GetReportGetCompetitionsQueryError = unknown;

/**
 * @summary Gets all of the competitions
 */
export const useGetReportGetCompetitions = <
  TData = Awaited<ReturnType<typeof getReportGetCompetitions>>,
  TError = unknown
>(
  params?: GetReportGetCompetitionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetCompetitions>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetCompetitionsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportGetCompetitionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the countries
 */
export const getReportGetCountries = (
  params?: GetReportGetCountriesParams,
  options?: SecondParameter<typeof getReportGetCountriesMutator>,
  signal?: AbortSignal
) => {
  return getReportGetCountriesMutator<string[]>(
    { url: `/report/get-countries`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportGetCountriesQueryKey = (params?: GetReportGetCountriesParams) => {
  return [`/report/get-countries`, ...(params ? [params] : [])] as const;
};

export const getGetReportGetCountriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportGetCountries>>,
  TError = unknown
>(
  params?: GetReportGetCountriesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetCountries>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetCountriesMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportGetCountriesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportGetCountries>>> = ({ signal }) =>
    getReportGetCountries(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportGetCountries>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportGetCountriesQueryResult = NonNullable<Awaited<ReturnType<typeof getReportGetCountries>>>;
export type GetReportGetCountriesQueryError = unknown;

/**
 * @summary Gets all of the countries
 */
export const useGetReportGetCountries = <TData = Awaited<ReturnType<typeof getReportGetCountries>>, TError = unknown>(
  params?: GetReportGetCountriesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetCountries>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetCountriesMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportGetCountriesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the matches
 */
export const getReportGetMatches = (
  params?: GetReportGetMatchesParams,
  options?: SecondParameter<typeof getReportGetMatchesMutator>,
  signal?: AbortSignal
) => {
  return getReportGetMatchesMutator<MatchesQuerySchema>(
    { url: `/report/get-matches`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportGetMatchesQueryKey = (params?: GetReportGetMatchesParams) => {
  return [`/report/get-matches`, ...(params ? [params] : [])] as const;
};

export const getGetReportGetMatchesInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof getReportGetMatches>>, GetReportGetMatchesParams['cursor']>,
  TError = unknown
>(
  params?: GetReportGetMatchesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getReportGetMatches>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getReportGetMatches>>,
        QueryKey,
        GetReportGetMatchesParams['cursor']
      >
    >;
    request?: SecondParameter<typeof getReportGetMatchesMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportGetMatchesQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReportGetMatches>>,
    QueryKey,
    GetReportGetMatchesParams['cursor']
  > = ({ signal, pageParam }) =>
    getReportGetMatches({ ...params, cursor: pageParam || params?.['cursor'] }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getReportGetMatches>>,
    TError,
    TData,
    Awaited<ReturnType<typeof getReportGetMatches>>,
    QueryKey,
    GetReportGetMatchesParams['cursor']
  > & { queryKey: QueryKey };
};

export type GetReportGetMatchesInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getReportGetMatches>>>;
export type GetReportGetMatchesInfiniteQueryError = unknown;

/**
 * @summary Gets all of the matches
 */
export const useGetReportGetMatchesInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof getReportGetMatches>>, GetReportGetMatchesParams['cursor']>,
  TError = unknown
>(
  params?: GetReportGetMatchesParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getReportGetMatches>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getReportGetMatches>>,
        QueryKey,
        GetReportGetMatchesParams['cursor']
      >
    >;
    request?: SecondParameter<typeof getReportGetMatchesMutator>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportGetMatchesInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetReportGetMatchesQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportGetMatches>>,
  TError = unknown
>(
  params?: GetReportGetMatchesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetMatches>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetMatchesMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportGetMatchesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportGetMatches>>> = ({ signal }) =>
    getReportGetMatches(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportGetMatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportGetMatchesQueryResult = NonNullable<Awaited<ReturnType<typeof getReportGetMatches>>>;
export type GetReportGetMatchesQueryError = unknown;

/**
 * @summary Gets all of the matches
 */
export const useGetReportGetMatches = <TData = Awaited<ReturnType<typeof getReportGetMatches>>, TError = unknown>(
  params?: GetReportGetMatchesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetMatches>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetMatchesMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportGetMatchesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the matches
 */
export const getPlayerMatches = (
  params?: GetPlayerMatchesParams,
  options?: SecondParameter<typeof getPlayerMatchesMutator>,
  signal?: AbortSignal
) => {
  return getPlayerMatchesMutator<MatchesSchema>(
    { url: `/report/get-player-matches`, method: 'GET', params, signal },
    options
  );
};

export const getGetPlayerMatchesQueryKey = (params?: GetPlayerMatchesParams) => {
  return [`/report/get-player-matches`, ...(params ? [params] : [])] as const;
};

export const getGetPlayerMatchesQueryOptions = <TData = Awaited<ReturnType<typeof getPlayerMatches>>, TError = unknown>(
  params?: GetPlayerMatchesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayerMatches>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayerMatchesMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayerMatchesQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlayerMatches>>> = ({ signal }) =>
    getPlayerMatches(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlayerMatches>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayerMatchesQueryResult = NonNullable<Awaited<ReturnType<typeof getPlayerMatches>>>;
export type GetPlayerMatchesQueryError = unknown;

/**
 * @summary Gets all of the matches
 */
export const useGetPlayerMatches = <TData = Awaited<ReturnType<typeof getPlayerMatches>>, TError = unknown>(
  params?: GetPlayerMatchesParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayerMatches>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayerMatchesMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlayerMatchesQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the player metrics
 */
export const getReportGetPlayerMetrics = (
  params?: GetReportGetPlayerMetricsParams,
  options?: SecondParameter<typeof getReportGetPlayerMetricsMutator>,
  signal?: AbortSignal
) => {
  return getReportGetPlayerMetricsMutator<ReportMetricsSchema>(
    { url: `/report/get-player-metrics`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportGetPlayerMetricsQueryKey = (params?: GetReportGetPlayerMetricsParams) => {
  return [`/report/get-player-metrics`, ...(params ? [params] : [])] as const;
};

export const getGetReportGetPlayerMetricsQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportGetPlayerMetrics>>,
  TError = unknown
>(
  params?: GetReportGetPlayerMetricsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetPlayerMetrics>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetPlayerMetricsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportGetPlayerMetricsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportGetPlayerMetrics>>> = ({ signal }) =>
    getReportGetPlayerMetrics(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportGetPlayerMetrics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportGetPlayerMetricsQueryResult = NonNullable<Awaited<ReturnType<typeof getReportGetPlayerMetrics>>>;
export type GetReportGetPlayerMetricsQueryError = unknown;

/**
 * @summary Gets all of the player metrics
 */
export const useGetReportGetPlayerMetrics = <
  TData = Awaited<ReturnType<typeof getReportGetPlayerMetrics>>,
  TError = unknown
>(
  params?: GetReportGetPlayerMetricsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetPlayerMetrics>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetPlayerMetricsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportGetPlayerMetricsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the player positions
 */
export const getReportGetPlayerPositions = (
  params?: GetReportGetPlayerPositionsParams,
  options?: SecondParameter<typeof getReportGetPlayerPositionsMutator>,
  signal?: AbortSignal
) => {
  return getReportGetPlayerPositionsMutator<string[]>(
    { url: `/report/get-player-positions`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportGetPlayerPositionsQueryKey = (params?: GetReportGetPlayerPositionsParams) => {
  return [`/report/get-player-positions`, ...(params ? [params] : [])] as const;
};

export const getGetReportGetPlayerPositionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportGetPlayerPositions>>,
  TError = unknown
>(
  params?: GetReportGetPlayerPositionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetPlayerPositions>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetPlayerPositionsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportGetPlayerPositionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportGetPlayerPositions>>> = ({ signal }) =>
    getReportGetPlayerPositions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportGetPlayerPositions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportGetPlayerPositionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReportGetPlayerPositions>>
>;
export type GetReportGetPlayerPositionsQueryError = unknown;

/**
 * @summary Gets all of the player positions
 */
export const useGetReportGetPlayerPositions = <
  TData = Awaited<ReturnType<typeof getReportGetPlayerPositions>>,
  TError = unknown
>(
  params?: GetReportGetPlayerPositionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetPlayerPositions>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetPlayerPositionsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportGetPlayerPositionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the player season metrics
 */
export const getPlayersSeasonMetrics = (
  params?: GetPlayersSeasonMetricsParams,
  options?: SecondParameter<typeof getPlayersSeasonMetricsMutator>,
  signal?: AbortSignal
) => {
  return getPlayersSeasonMetricsMutator<SeasonMetricsSchema>(
    { url: `/report/get-player-season-metrics`, method: 'GET', params, signal },
    options
  );
};

export const getGetPlayersSeasonMetricsQueryKey = (params?: GetPlayersSeasonMetricsParams) => {
  return [`/report/get-player-season-metrics`, ...(params ? [params] : [])] as const;
};

export const getGetPlayersSeasonMetricsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPlayersSeasonMetrics>>,
  TError = unknown
>(
  params?: GetPlayersSeasonMetricsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayersSeasonMetrics>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayersSeasonMetricsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPlayersSeasonMetricsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlayersSeasonMetrics>>> = ({ signal }) =>
    getPlayersSeasonMetrics(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getPlayersSeasonMetrics>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetPlayersSeasonMetricsQueryResult = NonNullable<Awaited<ReturnType<typeof getPlayersSeasonMetrics>>>;
export type GetPlayersSeasonMetricsQueryError = unknown;

/**
 * @summary Gets all of the player season metrics
 */
export const useGetPlayersSeasonMetrics = <
  TData = Awaited<ReturnType<typeof getPlayersSeasonMetrics>>,
  TError = unknown
>(
  params?: GetPlayersSeasonMetricsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getPlayersSeasonMetrics>>, TError, TData>>;
    request?: SecondParameter<typeof getPlayersSeasonMetricsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPlayersSeasonMetricsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the player sub positions
 */
export const getReportGetPlayerSubpositions = (
  params?: GetReportGetPlayerSubpositionsParams,
  options?: SecondParameter<typeof getReportGetPlayerSubpositionsMutator>,
  signal?: AbortSignal
) => {
  return getReportGetPlayerSubpositionsMutator<SubPositionsSchema>(
    { url: `/report/get-player-subpositions`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportGetPlayerSubpositionsQueryKey = (params?: GetReportGetPlayerSubpositionsParams) => {
  return [`/report/get-player-subpositions`, ...(params ? [params] : [])] as const;
};

export const getGetReportGetPlayerSubpositionsQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportGetPlayerSubpositions>>,
  TError = unknown
>(
  params?: GetReportGetPlayerSubpositionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetPlayerSubpositions>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetPlayerSubpositionsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportGetPlayerSubpositionsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportGetPlayerSubpositions>>> = ({ signal }) =>
    getReportGetPlayerSubpositions(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportGetPlayerSubpositions>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportGetPlayerSubpositionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReportGetPlayerSubpositions>>
>;
export type GetReportGetPlayerSubpositionsQueryError = unknown;

/**
 * @summary Gets all of the player sub positions
 */
export const useGetReportGetPlayerSubpositions = <
  TData = Awaited<ReturnType<typeof getReportGetPlayerSubpositions>>,
  TError = unknown
>(
  params?: GetReportGetPlayerSubpositionsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetPlayerSubpositions>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetPlayerSubpositionsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportGetPlayerSubpositionsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the players
 */
export const getReportGetPlayers = (
  params?: GetReportGetPlayersParams,
  options?: SecondParameter<typeof getReportGetPlayersMutator>,
  signal?: AbortSignal
) => {
  return getReportGetPlayersMutator<PlayersQuerySchema>(
    { url: `/report/get-players`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportGetPlayersQueryKey = (params?: GetReportGetPlayersParams) => {
  return [`/report/get-players`, ...(params ? [params] : [])] as const;
};

export const getGetReportGetPlayersInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof getReportGetPlayers>>, GetReportGetPlayersParams['cursor']>,
  TError = unknown
>(
  params?: GetReportGetPlayersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getReportGetPlayers>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getReportGetPlayers>>,
        QueryKey,
        GetReportGetPlayersParams['cursor']
      >
    >;
    request?: SecondParameter<typeof getReportGetPlayersMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportGetPlayersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReportGetPlayers>>,
    QueryKey,
    GetReportGetPlayersParams['cursor']
  > = ({ signal, pageParam }) =>
    getReportGetPlayers({ ...params, cursor: pageParam || params?.['cursor'] }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getReportGetPlayers>>,
    TError,
    TData,
    Awaited<ReturnType<typeof getReportGetPlayers>>,
    QueryKey,
    GetReportGetPlayersParams['cursor']
  > & { queryKey: QueryKey };
};

export type GetReportGetPlayersInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getReportGetPlayers>>>;
export type GetReportGetPlayersInfiniteQueryError = unknown;

/**
 * @summary Gets all of the players
 */
export const useGetReportGetPlayersInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof getReportGetPlayers>>, GetReportGetPlayersParams['cursor']>,
  TError = unknown
>(
  params?: GetReportGetPlayersParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getReportGetPlayers>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getReportGetPlayers>>,
        QueryKey,
        GetReportGetPlayersParams['cursor']
      >
    >;
    request?: SecondParameter<typeof getReportGetPlayersMutator>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportGetPlayersInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetReportGetPlayersQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportGetPlayers>>,
  TError = unknown
>(
  params?: GetReportGetPlayersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetPlayers>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetPlayersMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportGetPlayersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportGetPlayers>>> = ({ signal }) =>
    getReportGetPlayers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportGetPlayers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportGetPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof getReportGetPlayers>>>;
export type GetReportGetPlayersQueryError = unknown;

/**
 * @summary Gets all of the players
 */
export const useGetReportGetPlayers = <TData = Awaited<ReturnType<typeof getReportGetPlayers>>, TError = unknown>(
  params?: GetReportGetPlayersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetPlayers>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetPlayersMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportGetPlayersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets all of the teams
 */
export const getReportGetTeams = (
  params?: GetReportGetTeamsParams,
  options?: SecondParameter<typeof getReportGetTeamsMutator>,
  signal?: AbortSignal
) => {
  return getReportGetTeamsMutator<TeamsQuerySchema>(
    { url: `/report/get-teams`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportGetTeamsQueryKey = (params?: GetReportGetTeamsParams) => {
  return [`/report/get-teams`, ...(params ? [params] : [])] as const;
};

export const getGetReportGetTeamsInfiniteQueryOptions = <
  TData = InfiniteData<Awaited<ReturnType<typeof getReportGetTeams>>, GetReportGetTeamsParams['cursor']>,
  TError = unknown
>(
  params?: GetReportGetTeamsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getReportGetTeams>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getReportGetTeams>>,
        QueryKey,
        GetReportGetTeamsParams['cursor']
      >
    >;
    request?: SecondParameter<typeof getReportGetTeamsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportGetTeamsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getReportGetTeams>>,
    QueryKey,
    GetReportGetTeamsParams['cursor']
  > = ({ signal, pageParam }) =>
    getReportGetTeams({ ...params, cursor: pageParam || params?.['cursor'] }, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseInfiniteQueryOptions<
    Awaited<ReturnType<typeof getReportGetTeams>>,
    TError,
    TData,
    Awaited<ReturnType<typeof getReportGetTeams>>,
    QueryKey,
    GetReportGetTeamsParams['cursor']
  > & { queryKey: QueryKey };
};

export type GetReportGetTeamsInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof getReportGetTeams>>>;
export type GetReportGetTeamsInfiniteQueryError = unknown;

/**
 * @summary Gets all of the teams
 */
export const useGetReportGetTeamsInfinite = <
  TData = InfiniteData<Awaited<ReturnType<typeof getReportGetTeams>>, GetReportGetTeamsParams['cursor']>,
  TError = unknown
>(
  params?: GetReportGetTeamsParams,
  options?: {
    query?: Partial<
      UseInfiniteQueryOptions<
        Awaited<ReturnType<typeof getReportGetTeams>>,
        TError,
        TData,
        Awaited<ReturnType<typeof getReportGetTeams>>,
        QueryKey,
        GetReportGetTeamsParams['cursor']
      >
    >;
    request?: SecondParameter<typeof getReportGetTeamsMutator>;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportGetTeamsInfiniteQueryOptions(params, options);

  const query = useInfiniteQuery(queryOptions) as UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getGetReportGetTeamsQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportGetTeams>>,
  TError = unknown
>(
  params?: GetReportGetTeamsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetTeams>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetTeamsMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportGetTeamsQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportGetTeams>>> = ({ signal }) =>
    getReportGetTeams(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportGetTeams>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportGetTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getReportGetTeams>>>;
export type GetReportGetTeamsQueryError = unknown;

/**
 * @summary Gets all of the teams
 */
export const useGetReportGetTeams = <TData = Awaited<ReturnType<typeof getReportGetTeams>>, TError = unknown>(
  params?: GetReportGetTeamsParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportGetTeams>>, TError, TData>>;
    request?: SecondParameter<typeof getReportGetTeamsMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportGetTeamsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets last edited reports based on the number specified, or last 3 reports if not specified
 */
export const getReportLastEdited = (
  params?: GetReportLastEditedParams,
  options?: SecondParameter<typeof getReportLastEditedMutator>,
  signal?: AbortSignal
) => {
  return getReportLastEditedMutator<ReportsSchema>(
    { url: `/report/last-edited`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportLastEditedQueryKey = (params?: GetReportLastEditedParams) => {
  return [`/report/last-edited`, ...(params ? [params] : [])] as const;
};

export const getGetReportLastEditedQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportLastEdited>>,
  TError = unknown
>(
  params?: GetReportLastEditedParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportLastEdited>>, TError, TData>>;
    request?: SecondParameter<typeof getReportLastEditedMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportLastEditedQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportLastEdited>>> = ({ signal }) =>
    getReportLastEdited(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportLastEdited>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportLastEditedQueryResult = NonNullable<Awaited<ReturnType<typeof getReportLastEdited>>>;
export type GetReportLastEditedQueryError = unknown;

/**
 * @summary Gets last edited reports based on the number specified, or last 3 reports if not specified
 */
export const useGetReportLastEdited = <TData = Awaited<ReturnType<typeof getReportLastEdited>>, TError = unknown>(
  params?: GetReportLastEditedParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportLastEdited>>, TError, TData>>;
    request?: SecondParameter<typeof getReportLastEditedMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportLastEditedQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Refreshes all reports cache data
 */
export const getReportRefreshCache = (
  options?: SecondParameter<typeof getReportRefreshCacheMutator>,
  signal?: AbortSignal
) => {
  return getReportRefreshCacheMutator<void>({ url: `/report/refresh-cache`, method: 'GET', signal }, options);
};

export const getGetReportRefreshCacheQueryKey = () => {
  return [`/report/refresh-cache`] as const;
};

export const getGetReportRefreshCacheQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportRefreshCache>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportRefreshCache>>, TError, TData>>;
  request?: SecondParameter<typeof getReportRefreshCacheMutator>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportRefreshCacheQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportRefreshCache>>> = ({ signal }) =>
    getReportRefreshCache(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportRefreshCache>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportRefreshCacheQueryResult = NonNullable<Awaited<ReturnType<typeof getReportRefreshCache>>>;
export type GetReportRefreshCacheQueryError = unknown;

/**
 * @summary Refreshes all reports cache data
 */
export const useGetReportRefreshCache = <
  TData = Awaited<ReturnType<typeof getReportRefreshCache>>,
  TError = unknown
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportRefreshCache>>, TError, TData>>;
  request?: SecondParameter<typeof getReportRefreshCacheMutator>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportRefreshCacheQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Search players based on the given criteria
 */
export const getReportSearchPlayers = (
  params: GetReportSearchPlayersParams,
  options?: SecondParameter<typeof getReportSearchPlayersMutator>,
  signal?: AbortSignal
) => {
  return getReportSearchPlayersMutator<PlayersQuerySchema>(
    { url: `/report/search-players`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportSearchPlayersQueryKey = (params: GetReportSearchPlayersParams) => {
  return [`/report/search-players`, ...(params ? [params] : [])] as const;
};

export const getGetReportSearchPlayersQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportSearchPlayers>>,
  TError = unknown
>(
  params: GetReportSearchPlayersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportSearchPlayers>>, TError, TData>>;
    request?: SecondParameter<typeof getReportSearchPlayersMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportSearchPlayersQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportSearchPlayers>>> = ({ signal }) =>
    getReportSearchPlayers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportSearchPlayers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportSearchPlayersQueryResult = NonNullable<Awaited<ReturnType<typeof getReportSearchPlayers>>>;
export type GetReportSearchPlayersQueryError = unknown;

/**
 * @summary Search players based on the given criteria
 */
export const useGetReportSearchPlayers = <TData = Awaited<ReturnType<typeof getReportSearchPlayers>>, TError = unknown>(
  params: GetReportSearchPlayersParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportSearchPlayers>>, TError, TData>>;
    request?: SecondParameter<typeof getReportSearchPlayersMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportSearchPlayersQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Deletes an existing report
 */
export const deleteReportReportId = (
  reportId: string,
  options?: SecondParameter<typeof deleteReportReportIdMutator>
) => {
  return deleteReportReportIdMutator<void>(
    { url: `/report/${encodeURIComponent(String(reportId))}`, method: 'DELETE' },
    options
  );
};

export const getDeleteReportReportIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReportReportId>>,
    TError,
    { reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportReportIdMutator>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteReportReportId>>, TError, { reportId: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteReportReportId>>, { reportId: string }> = (
    props
  ) => {
    const { reportId } = props ?? {};

    return deleteReportReportId(reportId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReportReportIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteReportReportId>>>;

export type DeleteReportReportIdMutationError = unknown;

/**
 * @summary Deletes an existing report
 */
export const useDeleteReportReportId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReportReportId>>,
    TError,
    { reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportReportIdMutator>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteReportReportId>>, TError, { reportId: string }, TContext> => {
  const mutationOptions = getDeleteReportReportIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets a report with all of its elements
 */
export const getReportReportId = (
  reportId: string,
  options?: SecondParameter<typeof getReportReportIdMutator>,
  signal?: AbortSignal
) => {
  return getReportReportIdMutator<ReportNestedSchema>(
    { url: `/report/${encodeURIComponent(String(reportId))}`, method: 'GET', signal },
    options
  );
};

export const getGetReportReportIdQueryKey = (reportId: string) => {
  return [`/report/${reportId}`] as const;
};

export const getGetReportReportIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportReportId>>,
  TError = unknown
>(
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportReportId>>, TError, TData>>;
    request?: SecondParameter<typeof getReportReportIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportReportIdQueryKey(reportId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportReportId>>> = ({ signal }) =>
    getReportReportId(reportId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!reportId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportReportId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportReportIdQueryResult = NonNullable<Awaited<ReturnType<typeof getReportReportId>>>;
export type GetReportReportIdQueryError = unknown;

/**
 * @summary Gets a report with all of its elements
 */
export const useGetReportReportId = <TData = Awaited<ReturnType<typeof getReportReportId>>, TError = unknown>(
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportReportId>>, TError, TData>>;
    request?: SecondParameter<typeof getReportReportIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportReportIdQueryOptions(reportId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Modifies an existing report
 */
export const putReportReportId = (
  reportId: string,
  reportEditSchema: ReportEditSchema,
  options?: SecondParameter<typeof putReportReportIdMutator>
) => {
  return putReportReportIdMutator<ReportNestedSchema>(
    {
      url: `/report/${encodeURIComponent(String(reportId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: reportEditSchema
    },
    options
  );
};

export const getPutReportReportIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putReportReportId>>,
    TError,
    { reportId: string; data: ReportEditSchema },
    TContext
  >;
  request?: SecondParameter<typeof putReportReportIdMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putReportReportId>>,
  TError,
  { reportId: string; data: ReportEditSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putReportReportId>>,
    { reportId: string; data: ReportEditSchema }
  > = (props) => {
    const { reportId, data } = props ?? {};

    return putReportReportId(reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutReportReportIdMutationResult = NonNullable<Awaited<ReturnType<typeof putReportReportId>>>;
export type PutReportReportIdMutationBody = ReportEditSchema;
export type PutReportReportIdMutationError = unknown;

/**
 * @summary Modifies an existing report
 */
export const usePutReportReportId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putReportReportId>>,
    TError,
    { reportId: string; data: ReportEditSchema },
    TContext
  >;
  request?: SecondParameter<typeof putReportReportIdMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putReportReportId>>,
  TError,
  { reportId: string; data: ReportEditSchema },
  TContext
> => {
  const mutationOptions = getPutReportReportIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Adds a report element to an existing report
 */
export const postReportReportIdElement = (
  reportId: string,
  reportElementCreateSchema: ReportElementCreateSchema,
  options?: SecondParameter<typeof postReportReportIdElementMutator>
) => {
  return postReportReportIdElementMutator<ReportElementSchema>(
    {
      url: `/report/${encodeURIComponent(String(reportId))}/element`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: reportElementCreateSchema
    },
    options
  );
};

export const getPostReportReportIdElementMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postReportReportIdElement>>,
    TError,
    { reportId: string; data: ReportElementCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postReportReportIdElementMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postReportReportIdElement>>,
  TError,
  { reportId: string; data: ReportElementCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postReportReportIdElement>>,
    { reportId: string; data: ReportElementCreateSchema }
  > = (props) => {
    const { reportId, data } = props ?? {};

    return postReportReportIdElement(reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostReportReportIdElementMutationResult = NonNullable<
  Awaited<ReturnType<typeof postReportReportIdElement>>
>;
export type PostReportReportIdElementMutationBody = ReportElementCreateSchema;
export type PostReportReportIdElementMutationError = unknown;

/**
 * @summary Adds a report element to an existing report
 */
export const usePostReportReportIdElement = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postReportReportIdElement>>,
    TError,
    { reportId: string; data: ReportElementCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postReportReportIdElementMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postReportReportIdElement>>,
  TError,
  { reportId: string; data: ReportElementCreateSchema },
  TContext
> => {
  const mutationOptions = getPostReportReportIdElementMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Deletes a report element
 */
export const deleteReportReportIdElementElementId = (
  reportId: string,
  elementId: string,
  params?: DeleteReportReportIdElementElementIdParams,
  options?: SecondParameter<typeof deleteReportReportIdElementElementIdMutator>
) => {
  return deleteReportReportIdElementElementIdMutator<void>(
    {
      url: `/report/${encodeURIComponent(String(reportId))}/element/${encodeURIComponent(String(elementId))}`,
      method: 'DELETE',
      params
    },
    options
  );
};

export const getDeleteReportReportIdElementElementIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReportReportIdElementElementId>>,
    TError,
    { reportId: string; elementId: string; params?: DeleteReportReportIdElementElementIdParams },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportReportIdElementElementIdMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReportReportIdElementElementId>>,
  TError,
  { reportId: string; elementId: string; params?: DeleteReportReportIdElementElementIdParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReportReportIdElementElementId>>,
    { reportId: string; elementId: string; params?: DeleteReportReportIdElementElementIdParams }
  > = (props) => {
    const { reportId, elementId, params } = props ?? {};

    return deleteReportReportIdElementElementId(reportId, elementId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReportReportIdElementElementIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReportReportIdElementElementId>>
>;

export type DeleteReportReportIdElementElementIdMutationError = unknown;

/**
 * @summary Deletes a report element
 */
export const useDeleteReportReportIdElementElementId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReportReportIdElementElementId>>,
    TError,
    { reportId: string; elementId: string; params?: DeleteReportReportIdElementElementIdParams },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportReportIdElementElementIdMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReportReportIdElementElementId>>,
  TError,
  { reportId: string; elementId: string; params?: DeleteReportReportIdElementElementIdParams },
  TContext
> => {
  const mutationOptions = getDeleteReportReportIdElementElementIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets a report element
 */
export const getReportReportIdElementElementId = (
  reportId: string,
  elementId: string,
  options?: SecondParameter<typeof getReportReportIdElementElementIdMutator>,
  signal?: AbortSignal
) => {
  return getReportReportIdElementElementIdMutator<ReportElementSchema>(
    {
      url: `/report/${encodeURIComponent(String(reportId))}/element/${encodeURIComponent(String(elementId))}`,
      method: 'GET',
      signal
    },
    options
  );
};

export const getGetReportReportIdElementElementIdQueryKey = (reportId: string, elementId: string) => {
  return [`/report/${reportId}/element/${elementId}`] as const;
};

export const getGetReportReportIdElementElementIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportReportIdElementElementId>>,
  TError = unknown
>(
  reportId: string,
  elementId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportReportIdElementElementId>>, TError, TData>>;
    request?: SecondParameter<typeof getReportReportIdElementElementIdMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportReportIdElementElementIdQueryKey(reportId, elementId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportReportIdElementElementId>>> = ({ signal }) =>
    getReportReportIdElementElementId(reportId, elementId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(reportId && elementId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportReportIdElementElementId>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportReportIdElementElementIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReportReportIdElementElementId>>
>;
export type GetReportReportIdElementElementIdQueryError = unknown;

/**
 * @summary Gets a report element
 */
export const useGetReportReportIdElementElementId = <
  TData = Awaited<ReturnType<typeof getReportReportIdElementElementId>>,
  TError = unknown
>(
  reportId: string,
  elementId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportReportIdElementElementId>>, TError, TData>>;
    request?: SecondParameter<typeof getReportReportIdElementElementIdMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportReportIdElementElementIdQueryOptions(reportId, elementId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Modifies an existing report element
 */
export const putReportReportIdElementElementId = (
  reportId: string,
  elementId: string,
  reportElementEditSchema: ReportElementEditSchema,
  options?: SecondParameter<typeof putReportReportIdElementElementIdMutator>
) => {
  return putReportReportIdElementElementIdMutator<ReportElementSchema>(
    {
      url: `/report/${encodeURIComponent(String(reportId))}/element/${encodeURIComponent(String(elementId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: reportElementEditSchema
    },
    options
  );
};

export const getPutReportReportIdElementElementIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putReportReportIdElementElementId>>,
    TError,
    { reportId: string; elementId: string; data: ReportElementEditSchema },
    TContext
  >;
  request?: SecondParameter<typeof putReportReportIdElementElementIdMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putReportReportIdElementElementId>>,
  TError,
  { reportId: string; elementId: string; data: ReportElementEditSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putReportReportIdElementElementId>>,
    { reportId: string; elementId: string; data: ReportElementEditSchema }
  > = (props) => {
    const { reportId, elementId, data } = props ?? {};

    return putReportReportIdElementElementId(reportId, elementId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutReportReportIdElementElementIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putReportReportIdElementElementId>>
>;
export type PutReportReportIdElementElementIdMutationBody = ReportElementEditSchema;
export type PutReportReportIdElementElementIdMutationError = unknown;

/**
 * @summary Modifies an existing report element
 */
export const usePutReportReportIdElementElementId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putReportReportIdElementElementId>>,
    TError,
    { reportId: string; elementId: string; data: ReportElementEditSchema },
    TContext
  >;
  request?: SecondParameter<typeof putReportReportIdElementElementIdMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putReportReportIdElementElementId>>,
  TError,
  { reportId: string; elementId: string; data: ReportElementEditSchema },
  TContext
> => {
  const mutationOptions = getPutReportReportIdElementElementIdMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Changes the position of a report element
 */
export const putReportReportIdElementElementIdPosition = (
  reportId: string,
  elementId: string,
  params: PutReportReportIdElementElementIdPositionParams,
  options?: SecondParameter<typeof putReportReportIdElementElementIdPositionMutator>
) => {
  return putReportReportIdElementElementIdPositionMutator<void>(
    {
      url: `/report/${encodeURIComponent(String(reportId))}/element/${encodeURIComponent(String(elementId))}/position`,
      method: 'PUT',
      params
    },
    options
  );
};

export const getPutReportReportIdElementElementIdPositionMutationOptions = <
  TError = unknown,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putReportReportIdElementElementIdPosition>>,
    TError,
    { reportId: string; elementId: string; params: PutReportReportIdElementElementIdPositionParams },
    TContext
  >;
  request?: SecondParameter<typeof putReportReportIdElementElementIdPositionMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putReportReportIdElementElementIdPosition>>,
  TError,
  { reportId: string; elementId: string; params: PutReportReportIdElementElementIdPositionParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putReportReportIdElementElementIdPosition>>,
    { reportId: string; elementId: string; params: PutReportReportIdElementElementIdPositionParams }
  > = (props) => {
    const { reportId, elementId, params } = props ?? {};

    return putReportReportIdElementElementIdPosition(reportId, elementId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutReportReportIdElementElementIdPositionMutationResult = NonNullable<
  Awaited<ReturnType<typeof putReportReportIdElementElementIdPosition>>
>;

export type PutReportReportIdElementElementIdPositionMutationError = unknown;

/**
 * @summary Changes the position of a report element
 */
export const usePutReportReportIdElementElementIdPosition = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putReportReportIdElementElementIdPosition>>,
    TError,
    { reportId: string; elementId: string; params: PutReportReportIdElementElementIdPositionParams },
    TContext
  >;
  request?: SecondParameter<typeof putReportReportIdElementElementIdPositionMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putReportReportIdElementElementIdPosition>>,
  TError,
  { reportId: string; elementId: string; params: PutReportReportIdElementElementIdPositionParams },
  TContext
> => {
  const mutationOptions = getPutReportReportIdElementElementIdPositionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets a report element using token (public access)
 */
export const getReportReportIdElementElementIdPublic = (
  reportId: string,
  elementId: string,
  params: GetReportReportIdElementElementIdPublicParams,
  options?: SecondParameter<typeof getReportReportIdElementElementIdPublicMutator>,
  signal?: AbortSignal
) => {
  return getReportReportIdElementElementIdPublicMutator<ReportElementSchema>(
    {
      url: `/report/${encodeURIComponent(String(reportId))}/element/${encodeURIComponent(String(elementId))}/public`,
      method: 'GET',
      params,
      signal
    },
    options
  );
};

export const getGetReportReportIdElementElementIdPublicQueryKey = (
  reportId: string,
  elementId: string,
  params: GetReportReportIdElementElementIdPublicParams
) => {
  return [`/report/${reportId}/element/${elementId}/public`, ...(params ? [params] : [])] as const;
};

export const getGetReportReportIdElementElementIdPublicQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportReportIdElementElementIdPublic>>,
  TError = unknown
>(
  reportId: string,
  elementId: string,
  params: GetReportReportIdElementElementIdPublicParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getReportReportIdElementElementIdPublic>>, TError, TData>
    >;
    request?: SecondParameter<typeof getReportReportIdElementElementIdPublicMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetReportReportIdElementElementIdPublicQueryKey(reportId, elementId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportReportIdElementElementIdPublic>>> = ({ signal }) =>
    getReportReportIdElementElementIdPublic(reportId, elementId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!(reportId && elementId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportReportIdElementElementIdPublic>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportReportIdElementElementIdPublicQueryResult = NonNullable<
  Awaited<ReturnType<typeof getReportReportIdElementElementIdPublic>>
>;
export type GetReportReportIdElementElementIdPublicQueryError = unknown;

/**
 * @summary Gets a report element using token (public access)
 */
export const useGetReportReportIdElementElementIdPublic = <
  TData = Awaited<ReturnType<typeof getReportReportIdElementElementIdPublic>>,
  TError = unknown
>(
  reportId: string,
  elementId: string,
  params: GetReportReportIdElementElementIdPublicParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getReportReportIdElementElementIdPublic>>, TError, TData>
    >;
    request?: SecondParameter<typeof getReportReportIdElementElementIdPublicMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportReportIdElementElementIdPublicQueryOptions(reportId, elementId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Gets a report with all of its elements using token (public access)
 */
export const getReportReportIdPublic = (
  reportId: string,
  params: GetReportReportIdPublicParams,
  options?: SecondParameter<typeof getReportReportIdPublicMutator>,
  signal?: AbortSignal
) => {
  return getReportReportIdPublicMutator<ReportNestedSchema>(
    { url: `/report/${encodeURIComponent(String(reportId))}/public`, method: 'GET', params, signal },
    options
  );
};

export const getGetReportReportIdPublicQueryKey = (reportId: string, params: GetReportReportIdPublicParams) => {
  return [`/report/${reportId}/public`, ...(params ? [params] : [])] as const;
};

export const getGetReportReportIdPublicQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportReportIdPublic>>,
  TError = unknown
>(
  reportId: string,
  params: GetReportReportIdPublicParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportReportIdPublic>>, TError, TData>>;
    request?: SecondParameter<typeof getReportReportIdPublicMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportReportIdPublicQueryKey(reportId, params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportReportIdPublic>>> = ({ signal }) =>
    getReportReportIdPublic(reportId, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!reportId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportReportIdPublic>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportReportIdPublicQueryResult = NonNullable<Awaited<ReturnType<typeof getReportReportIdPublic>>>;
export type GetReportReportIdPublicQueryError = unknown;

/**
 * @summary Gets a report with all of its elements using token (public access)
 */
export const useGetReportReportIdPublic = <
  TData = Awaited<ReturnType<typeof getReportReportIdPublic>>,
  TError = unknown
>(
  reportId: string,
  params: GetReportReportIdPublicParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportReportIdPublic>>, TError, TData>>;
    request?: SecondParameter<typeof getReportReportIdPublicMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportReportIdPublicQueryOptions(reportId, params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Deletes a report schedule
 */
export const deleteReportReportIdSchedule = (
  reportId: string,
  options?: SecondParameter<typeof deleteReportReportIdScheduleMutator>
) => {
  return deleteReportReportIdScheduleMutator<void>(
    { url: `/report/${encodeURIComponent(String(reportId))}/schedule`, method: 'DELETE' },
    options
  );
};

export const getDeleteReportReportIdScheduleMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReportReportIdSchedule>>,
    TError,
    { reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportReportIdScheduleMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReportReportIdSchedule>>,
  TError,
  { reportId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteReportReportIdSchedule>>, { reportId: string }> = (
    props
  ) => {
    const { reportId } = props ?? {};

    return deleteReportReportIdSchedule(reportId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReportReportIdScheduleMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReportReportIdSchedule>>
>;

export type DeleteReportReportIdScheduleMutationError = unknown;

/**
 * @summary Deletes a report schedule
 */
export const useDeleteReportReportIdSchedule = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReportReportIdSchedule>>,
    TError,
    { reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportReportIdScheduleMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReportReportIdSchedule>>,
  TError,
  { reportId: string },
  TContext
> => {
  const mutationOptions = getDeleteReportReportIdScheduleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets the schedule of a report
 */
export const getReportReportIdSchedule = (
  reportId: string,
  options?: SecondParameter<typeof getReportReportIdScheduleMutator>,
  signal?: AbortSignal
) => {
  return getReportReportIdScheduleMutator<ReportSendScheduleSchema>(
    { url: `/report/${encodeURIComponent(String(reportId))}/schedule`, method: 'GET', signal },
    options
  );
};

export const getGetReportReportIdScheduleQueryKey = (reportId: string) => {
  return [`/report/${reportId}/schedule`] as const;
};

export const getGetReportReportIdScheduleQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportReportIdSchedule>>,
  TError = unknown
>(
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportReportIdSchedule>>, TError, TData>>;
    request?: SecondParameter<typeof getReportReportIdScheduleMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportReportIdScheduleQueryKey(reportId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportReportIdSchedule>>> = ({ signal }) =>
    getReportReportIdSchedule(reportId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!reportId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportReportIdSchedule>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportReportIdScheduleQueryResult = NonNullable<Awaited<ReturnType<typeof getReportReportIdSchedule>>>;
export type GetReportReportIdScheduleQueryError = unknown;

/**
 * @summary Gets the schedule of a report
 */
export const useGetReportReportIdSchedule = <
  TData = Awaited<ReturnType<typeof getReportReportIdSchedule>>,
  TError = unknown
>(
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportReportIdSchedule>>, TError, TData>>;
    request?: SecondParameter<typeof getReportReportIdScheduleMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportReportIdScheduleQueryOptions(reportId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Creates a report scheule to send the report to multiple users
 */
export const postReportReportIdSchedule = (
  reportId: string,
  reportSendScheduleCreateSchema: ReportSendScheduleCreateSchema,
  options?: SecondParameter<typeof postReportReportIdScheduleMutator>
) => {
  return postReportReportIdScheduleMutator<void>(
    {
      url: `/report/${encodeURIComponent(String(reportId))}/schedule`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: reportSendScheduleCreateSchema
    },
    options
  );
};

export const getPostReportReportIdScheduleMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postReportReportIdSchedule>>,
    TError,
    { reportId: string; data: ReportSendScheduleCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postReportReportIdScheduleMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postReportReportIdSchedule>>,
  TError,
  { reportId: string; data: ReportSendScheduleCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postReportReportIdSchedule>>,
    { reportId: string; data: ReportSendScheduleCreateSchema }
  > = (props) => {
    const { reportId, data } = props ?? {};

    return postReportReportIdSchedule(reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostReportReportIdScheduleMutationResult = NonNullable<
  Awaited<ReturnType<typeof postReportReportIdSchedule>>
>;
export type PostReportReportIdScheduleMutationBody = ReportSendScheduleCreateSchema;
export type PostReportReportIdScheduleMutationError = unknown;

/**
 * @summary Creates a report scheule to send the report to multiple users
 */
export const usePostReportReportIdSchedule = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postReportReportIdSchedule>>,
    TError,
    { reportId: string; data: ReportSendScheduleCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postReportReportIdScheduleMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postReportReportIdSchedule>>,
  TError,
  { reportId: string; data: ReportSendScheduleCreateSchema },
  TContext
> => {
  const mutationOptions = getPostReportReportIdScheduleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Updates a report schedule
 */
export const putReportReportIdSchedule = (
  reportId: string,
  reportSendScheduleCreateSchema: ReportSendScheduleCreateSchema,
  options?: SecondParameter<typeof putReportReportIdScheduleMutator>
) => {
  return putReportReportIdScheduleMutator<void>(
    {
      url: `/report/${encodeURIComponent(String(reportId))}/schedule`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: reportSendScheduleCreateSchema
    },
    options
  );
};

export const getPutReportReportIdScheduleMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putReportReportIdSchedule>>,
    TError,
    { reportId: string; data: ReportSendScheduleCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putReportReportIdScheduleMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putReportReportIdSchedule>>,
  TError,
  { reportId: string; data: ReportSendScheduleCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putReportReportIdSchedule>>,
    { reportId: string; data: ReportSendScheduleCreateSchema }
  > = (props) => {
    const { reportId, data } = props ?? {};

    return putReportReportIdSchedule(reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutReportReportIdScheduleMutationResult = NonNullable<
  Awaited<ReturnType<typeof putReportReportIdSchedule>>
>;
export type PutReportReportIdScheduleMutationBody = ReportSendScheduleCreateSchema;
export type PutReportReportIdScheduleMutationError = unknown;

/**
 * @summary Updates a report schedule
 */
export const usePutReportReportIdSchedule = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putReportReportIdSchedule>>,
    TError,
    { reportId: string; data: ReportSendScheduleCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putReportReportIdScheduleMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putReportReportIdSchedule>>,
  TError,
  { reportId: string; data: ReportSendScheduleCreateSchema },
  TContext
> => {
  const mutationOptions = getPutReportReportIdScheduleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Sends a report to multiple users immediately
 */
export const postReportReportIdSend = (
  reportId: string,
  params?: PostReportReportIdSendParams,
  options?: SecondParameter<typeof postReportReportIdSendMutator>
) => {
  return postReportReportIdSendMutator<void>(
    { url: `/report/${encodeURIComponent(String(reportId))}/send`, method: 'POST', params },
    options
  );
};

export const getPostReportReportIdSendMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postReportReportIdSend>>,
    TError,
    { reportId: string; params?: PostReportReportIdSendParams },
    TContext
  >;
  request?: SecondParameter<typeof postReportReportIdSendMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postReportReportIdSend>>,
  TError,
  { reportId: string; params?: PostReportReportIdSendParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postReportReportIdSend>>,
    { reportId: string; params?: PostReportReportIdSendParams }
  > = (props) => {
    const { reportId, params } = props ?? {};

    return postReportReportIdSend(reportId, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostReportReportIdSendMutationResult = NonNullable<Awaited<ReturnType<typeof postReportReportIdSend>>>;

export type PostReportReportIdSendMutationError = unknown;

/**
 * @summary Sends a report to multiple users immediately
 */
export const usePostReportReportIdSend = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postReportReportIdSend>>,
    TError,
    { reportId: string; params?: PostReportReportIdSendParams },
    TContext
  >;
  request?: SecondParameter<typeof postReportReportIdSendMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postReportReportIdSend>>,
  TError,
  { reportId: string; params?: PostReportReportIdSendParams },
  TContext
> => {
  const mutationOptions = getPostReportReportIdSendMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Toggles the favorite status of a report
 */
export const putReportReportIdToggleFavorite = (
  reportId: string,
  options?: SecondParameter<typeof putReportReportIdToggleFavoriteMutator>
) => {
  return putReportReportIdToggleFavoriteMutator<void>(
    { url: `/report/${encodeURIComponent(String(reportId))}/toggle-favorite`, method: 'PUT' },
    options
  );
};

export const getPutReportReportIdToggleFavoriteMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putReportReportIdToggleFavorite>>,
    TError,
    { reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof putReportReportIdToggleFavoriteMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putReportReportIdToggleFavorite>>,
  TError,
  { reportId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putReportReportIdToggleFavorite>>,
    { reportId: string }
  > = (props) => {
    const { reportId } = props ?? {};

    return putReportReportIdToggleFavorite(reportId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutReportReportIdToggleFavoriteMutationResult = NonNullable<
  Awaited<ReturnType<typeof putReportReportIdToggleFavorite>>
>;

export type PutReportReportIdToggleFavoriteMutationError = unknown;

/**
 * @summary Toggles the favorite status of a report
 */
export const usePutReportReportIdToggleFavorite = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putReportReportIdToggleFavorite>>,
    TError,
    { reportId: string },
    TContext
  >;
  request?: SecondParameter<typeof putReportReportIdToggleFavoriteMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putReportReportIdToggleFavorite>>,
  TError,
  { reportId: string },
  TContext
> => {
  const mutationOptions = getPutReportReportIdToggleFavoriteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Revokes access to a report
 */
export const deleteReportReportIdUsers = (
  reportId: string,
  reportAccessDeleteSchema: ReportAccessDeleteSchema,
  options?: SecondParameter<typeof deleteReportReportIdUsersMutator>
) => {
  return deleteReportReportIdUsersMutator<void>(
    {
      url: `/report/${encodeURIComponent(String(reportId))}/users`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: reportAccessDeleteSchema
    },
    options
  );
};

export const getDeleteReportReportIdUsersMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReportReportIdUsers>>,
    TError,
    { reportId: string; data: ReportAccessDeleteSchema },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportReportIdUsersMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteReportReportIdUsers>>,
  TError,
  { reportId: string; data: ReportAccessDeleteSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteReportReportIdUsers>>,
    { reportId: string; data: ReportAccessDeleteSchema }
  > = (props) => {
    const { reportId, data } = props ?? {};

    return deleteReportReportIdUsers(reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteReportReportIdUsersMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReportReportIdUsers>>
>;
export type DeleteReportReportIdUsersMutationBody = ReportAccessDeleteSchema;
export type DeleteReportReportIdUsersMutationError = unknown;

/**
 * @summary Revokes access to a report
 */
export const useDeleteReportReportIdUsers = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteReportReportIdUsers>>,
    TError,
    { reportId: string; data: ReportAccessDeleteSchema },
    TContext
  >;
  request?: SecondParameter<typeof deleteReportReportIdUsersMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteReportReportIdUsers>>,
  TError,
  { reportId: string; data: ReportAccessDeleteSchema },
  TContext
> => {
  const mutationOptions = getDeleteReportReportIdUsersMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Gets the users that have access to a report
 */
export const getReportReportIdUsers = (
  reportId: string,
  options?: SecondParameter<typeof getReportReportIdUsersMutator>,
  signal?: AbortSignal
) => {
  return getReportReportIdUsersMutator<ReportAccessesSchema>(
    { url: `/report/${encodeURIComponent(String(reportId))}/users`, method: 'GET', signal },
    options
  );
};

export const getGetReportReportIdUsersQueryKey = (reportId: string) => {
  return [`/report/${reportId}/users`] as const;
};

export const getGetReportReportIdUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getReportReportIdUsers>>,
  TError = unknown
>(
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportReportIdUsers>>, TError, TData>>;
    request?: SecondParameter<typeof getReportReportIdUsersMutator>;
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetReportReportIdUsersQueryKey(reportId);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getReportReportIdUsers>>> = ({ signal }) =>
    getReportReportIdUsers(reportId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!reportId, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getReportReportIdUsers>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetReportReportIdUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getReportReportIdUsers>>>;
export type GetReportReportIdUsersQueryError = unknown;

/**
 * @summary Gets the users that have access to a report
 */
export const useGetReportReportIdUsers = <TData = Awaited<ReturnType<typeof getReportReportIdUsers>>, TError = unknown>(
  reportId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getReportReportIdUsers>>, TError, TData>>;
    request?: SecondParameter<typeof getReportReportIdUsersMutator>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetReportReportIdUsersQueryOptions(reportId, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * @summary Grants access to a report for multiple users
 */
export const postReportReportIdUsers = (
  reportId: string,
  reportAccessesCreateSchema: ReportAccessesCreateSchema,
  options?: SecondParameter<typeof postReportReportIdUsersMutator>
) => {
  return postReportReportIdUsersMutator<void>(
    {
      url: `/report/${encodeURIComponent(String(reportId))}/users`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: reportAccessesCreateSchema
    },
    options
  );
};

export const getPostReportReportIdUsersMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postReportReportIdUsers>>,
    TError,
    { reportId: string; data: ReportAccessesCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postReportReportIdUsersMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postReportReportIdUsers>>,
  TError,
  { reportId: string; data: ReportAccessesCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postReportReportIdUsers>>,
    { reportId: string; data: ReportAccessesCreateSchema }
  > = (props) => {
    const { reportId, data } = props ?? {};

    return postReportReportIdUsers(reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostReportReportIdUsersMutationResult = NonNullable<Awaited<ReturnType<typeof postReportReportIdUsers>>>;
export type PostReportReportIdUsersMutationBody = ReportAccessesCreateSchema;
export type PostReportReportIdUsersMutationError = unknown;

/**
 * @summary Grants access to a report for multiple users
 */
export const usePostReportReportIdUsers = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postReportReportIdUsers>>,
    TError,
    { reportId: string; data: ReportAccessesCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof postReportReportIdUsersMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postReportReportIdUsers>>,
  TError,
  { reportId: string; data: ReportAccessesCreateSchema },
  TContext
> => {
  const mutationOptions = getPostReportReportIdUsersMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Updates the access to a report
 */
export const putReportReportIdUsers = (
  reportId: string,
  reportAccessCreateSchema: ReportAccessCreateSchema,
  options?: SecondParameter<typeof putReportReportIdUsersMutator>
) => {
  return putReportReportIdUsersMutator<void>(
    {
      url: `/report/${encodeURIComponent(String(reportId))}/users`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: reportAccessCreateSchema
    },
    options
  );
};

export const getPutReportReportIdUsersMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putReportReportIdUsers>>,
    TError,
    { reportId: string; data: ReportAccessCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putReportReportIdUsersMutator>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putReportReportIdUsers>>,
  TError,
  { reportId: string; data: ReportAccessCreateSchema },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putReportReportIdUsers>>,
    { reportId: string; data: ReportAccessCreateSchema }
  > = (props) => {
    const { reportId, data } = props ?? {};

    return putReportReportIdUsers(reportId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutReportReportIdUsersMutationResult = NonNullable<Awaited<ReturnType<typeof putReportReportIdUsers>>>;
export type PutReportReportIdUsersMutationBody = ReportAccessCreateSchema;
export type PutReportReportIdUsersMutationError = unknown;

/**
 * @summary Updates the access to a report
 */
export const usePutReportReportIdUsers = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putReportReportIdUsers>>,
    TError,
    { reportId: string; data: ReportAccessCreateSchema },
    TContext
  >;
  request?: SecondParameter<typeof putReportReportIdUsersMutator>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putReportReportIdUsers>>,
  TError,
  { reportId: string; data: ReportAccessCreateSchema },
  TContext
> => {
  const mutationOptions = getPutReportReportIdUsersMutationOptions(options);

  return useMutation(mutationOptions);
};
