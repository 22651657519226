import { Group } from '@visx/group';
import { useParentSize } from '@visx/responsive';
import { scaleLinear } from '@visx/scale';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo, useCallback, useRef } from 'react';
import { twJoin } from 'tailwind-merge';
import { isLightColor } from 'utils/helpers';
import { MatchInfo } from 'utils/interfaces';
import { getReportGeneralColor } from '../../../../helpers';
import { ChartEntityTooltip } from '../../charts/ChartEntityTooltip';
import ChartTooltip from '../ChartTooltip';
import SvgJersey from '../ScatterChart/SvgJersey';
import { PositionMapPoint } from './interfaces';

interface PlayerPositionChartProps {
  home: PositionMapPoint[];
  away: PositionMapPoint[];
  match: MatchInfo;
  report: ReportNestedSchema;
  element: ReportElementSchema;
}

const PlayerPositionChart = memo(function PlayerPositionChart({
  home,
  away,
  report,
  element,
  match
}: PlayerPositionChartProps) {
  const { width, height, parentRef } = useParentSize({ debounceTime: 150 });
  const getX = (point: PositionMapPoint) => point.coordinates[0];
  const getY = (point: PositionMapPoint) => point.coordinates[1];

  const xScale = scaleLinear<number>({
    domain: [-52.5, 52.5],
    range: [0, width],
    round: true
  });

  const yScale = scaleLinear<number>({
    domain: [-34, 34],
    range: [height, 0],
    round: true
  });

  const xValue = useCallback(
    (point: PositionMapPoint) => {
      return xScale(getX(point)) ?? 0;
    },
    [xScale]
  );

  const yValue = useCallback(
    (point: PositionMapPoint) => {
      return yScale(getY(point)) ?? 0;
    },
    [yScale]
  );

  const tooltipTimeout = useRef(0);
  const { showTooltip, hideTooltip, tooltipData, tooltipOpen, tooltipLeft, tooltipTop } = useTooltip<PositionMapPoint>({
    tooltipOpen: false
  });
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: false,
    debounce: 300,
    scroll: true
  });

  const handleMouseLeave = useCallback(
    function handleMouseLeave() {
      tooltipTimeout.current = window.setTimeout(() => {
        hideTooltip();
      }, 300);
    },
    [hideTooltip]
  );

  const handleMouseEnter = useCallback(
    function handleMouseEnter(point: PositionMapPoint) {
      return () => {
        if (tooltipTimeout.current) clearTimeout(tooltipTimeout.current);
        showTooltip({
          tooltipData: point,
          tooltipLeft: xValue(point) - 10,
          tooltipTop: yValue(point) - 40
        });
      };
    },
    [showTooltip, xValue, yValue]
  );

  return (
    <div
      ref={parentRef}
      className="relative aspect-football-field-horizontal h-auto w-full bg-football-field-horizontal bg-contain"
    >
      {element.report_element_template_name === 'avg-positions-event-data' && (
        <>
          <span
            className={twJoin(
              'absolute z-10 w-fit rounded-badge bg-white px-3 py-1.5 text-tiny font-bold',
              'left-[8%] top-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-90'
            )}
          >
            {match.home_team_name}
          </span>
          <span
            className={twJoin(
              'absolute z-10 w-fit rounded-badge bg-white px-3 py-1.5 text-tiny font-bold',
              'right-[8%] top-1/2 -translate-y-1/2 translate-x-1/2 rotate-90'
            )}
          >
            {match.away_team_name}
          </span>
        </>
      )}
      <svg className="size-full" ref={containerRef}>
        <Group left={0} top={0}>
          {home.map((player) => (
            <SvgJersey
              left={xValue(player)}
              top={yValue(player)}
              color={player.team_color ?? getReportGeneralColor(report, 0, 'home')}
              blackNumber={isLightColor(player.team_color ?? getReportGeneralColor(report, 0, 'home'))}
              number={player.shirt_number}
              key={`point-${player.player_id}`}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={handleMouseEnter(player)}
            />
          ))}
          {away.map((player) => (
            <SvgJersey
              left={xValue(player)}
              top={yValue(player)}
              color={player.team_color ?? getReportGeneralColor(report, 0, 'away')}
              blackNumber={isLightColor(player.team_color ?? getReportGeneralColor(report, 0, 'away'))}
              number={player.shirt_number}
              key={`point-${player.player_id}`}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={handleMouseEnter(player)}
            />
          ))}
        </Group>
      </svg>
      <ChartTooltip open={tooltipOpen} left={tooltipLeft} top={tooltipTop} Tooltip={TooltipInPortal}>
        <ChartEntityTooltip entity={tooltipData} chart="position" />
      </ChartTooltip>
    </div>
  );
});

export default PlayerPositionChart;
