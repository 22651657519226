import { ScoutMetric, ScoutNote, ScoutNoteCreateSchema, ScoutNoteCreateSchemaType } from 'lib/model';
import { convertStringToInputDate } from 'utils/helpers';
import { playerToAutocompleteOption, scoutReferenceToValueOption, stringToValueOption } from 'utils/mappings';
import { ScoutNoteFormRating, ScoutNoteFormValues } from './interfaces';

export function loadScoutNoteFormValues(scoutNote: ScoutNote): Partial<ScoutNoteFormValues> {
  const ratings = scoutNote.ratings?.reduce(
    (acc, rating) => {
      acc[rating.scout_metric!] = {
        scout_metric: rating.scout_metric,
        rating: rating.rating,
        formatted_text: rating.formatted_text,
        plain_text: rating.plain_text
      };

      return acc;
    },
    {} as { [key: string]: ScoutNoteFormRating }
  );

  const formValues: Partial<ScoutNoteFormValues> = {
    player: scoutNote.player && playerToAutocompleteOption(scoutNote.player),
    type: stringToValueOption(scoutNote.type!),
    reference: scoutNote.reference && scoutReferenceToValueOption(scoutNote.reference),
    ratings: ratings,
    note_date: convertStringToInputDate(scoutNote.note_date),
    note_name: scoutNote.note_name,

    estimated_value: scoutNote.player_value_estimate
  };

  return formValues;
}

export function parseScoutNoteFormValues(formData: ScoutNoteFormValues): ScoutNoteCreateSchema {
  const estimatedValueString = formData.estimated_value as string;
  const estimatedValue = estimatedValueString !== '' ? parseFloat(estimatedValueString) : undefined;

  return {
    player_id: formData.player!.id as number,
    player_value_estimate: estimatedValue,
    note_name: formData.note_name,
    type: formData.type.id as ScoutNoteCreateSchemaType,
    reference: formData.reference?.id as number,
    note_date: formData.note_date,
    ratings: Object.values(formData.ratings).map((rating) => ({
      scout_metric_id: rating.scout_metric,
      rating: rating.rating,
      formatted_text: rating.formatted_text,
      plain_text: rating.plain_text
    }))
  };
}

export function sortScoutMetrics(a: ScoutMetric, b: ScoutMetric): number {
  // Return overall metric last
  return a.name === 'Overall' ? 1 : -1;
}
