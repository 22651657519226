import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { useLexicalEditable } from '@lexical/react/useLexicalEditable';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  placeholderClassName?: string;
  placeholder: string;
};

export default function LexicalContentEditable({ className, placeholder, placeholderClassName }: Props): JSX.Element {
  const [editor] = useLexicalComposerContext();
  const isEditable = useLexicalEditable();

  function focusEditor() {
    editor.focus();
  }

  return (
    <ContentEditable
      className={
        className ??
        twMerge(
          'block max-h-48 min-h-12 overflow-y-auto rounded-xl border border-gray-400 p-4',
          isEditable ? 'bg-white' : 'bg-gray-50'
        )
      }
      aria-placeholder={placeholder}
      placeholder={(isEditable) =>
        isEditable ? (
          <div className={placeholderClassName ?? 'absolute left-4 top-4'} onClick={focusEditor}>
            {placeholder}
          </div>
        ) : null
      }
    />
  );
}
