import useAuth from 'contexts/auth/authContext';
import useActiveProject from 'contexts/project/projectContext';
import { PlayerScoutNotes } from 'lib/model';
import { useCreateProjectScoutNote } from 'lib/scout-note/scout-note';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { queryClient } from 'router';
import { parseErrorMessage } from 'utils/error_handlers';
import { playerToAutocompleteOption } from 'utils/mappings';
import { CREATE_SCOUT_NOTE_ROLES } from '../constants';
import { parseScoutNoteFormValues } from '../helpers';
import { ScoutNoteFormValues } from '../interfaces';
import ScoutNoteForm from './ScoutNoteForm';

const NewScoutNotePage = () => {
  const { user, userRole } = useAuth();
  const { project } = useActiveProject();
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate } = useCreateProjectScoutNote({
    mutation: {
      onMutate: async (body) => {
        const loadingToastId = toast.info('Saving changes...', { autoClose: false });
        // TODO: Optimistic update
        return { loadingToastId };
      },
      onError(error, variables, context) {
        toast.dismiss(context?.loadingToastId);
        const errorMessage = parseErrorMessage(error, 'Failed to create scout note. Please try again later.');
        toast.error(errorMessage);
        // TODO: Rollback optimistic update
      },
      onSuccess(data, variables, context) {
        toast.dismiss(context?.loadingToastId);
        toast.success('Scout note created successfully!');
        // Redirect to scout notes page or scout note?
        queryClient.invalidateQueries({ queryKey: ['project', project.id, 'scout-notes'], exact: false });
        queryClient.setQueryData(['project', project.id, 'scout-note', data.id], data);
        navigate(`/scout-notes/${data.id}`);
      }
    }
  });

  function onSubmit(formData: ScoutNoteFormValues) {
    const data = parseScoutNoteFormValues(formData);
    mutate({ projectId: project.id!, data });
  }

  if (!user.is_admin && !CREATE_SCOUT_NOTE_ROLES.includes(userRole!)) {
    toast.warning('You do not have permission to create a scout note', {
      toastId: 'new-scout-note-access-permission'
    });
    Navigate({ to: `/scout-notes` });
  }

  const defaultValues: Partial<ScoutNoteFormValues> = {};
  if (location.state) {
    defaultValues.player = playerToAutocompleteOption(location.state.player as Required<PlayerScoutNotes>);
  }

  return (
    <div className="mt-10 flex w-full max-w-4xl justify-center">
      <ScoutNoteForm onSubmit={onSubmit} defaultValues={defaultValues} />
    </div>
  );
};

export default NewScoutNotePage;
