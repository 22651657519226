import { LineupPlayer, LineupTeam } from '../../interfaces';

export function createTeamGrid(team: LineupTeam) {
  const playerGridPlayers = team.players.filter((player) => !!player.player_grid);

  if (playerGridPlayers.length === 11) {
    playerGridPlayers.sort((a, b) => a.player_grid!.localeCompare(b.player_grid!));

    const gridObj = playerGridPlayers.reduce((acc: Record<string, LineupPlayer[]>, player) => {
      const [row, _] = player.player_grid!.split(':') as [string, string];
      if (!acc[row]) {
        acc[row] = [];
      }
      acc[row].push(player);
      return acc;
    }, {});

    return Object.values(gridObj);
  }

  if (!!team.formation) {
    const formation = team.formation.split(' ')[0];
    const rows = [1, ...formation.split('-').map((x) => Number(x))];
    const grid = rows.map((_) => [] as LineupPlayer[]);

    if (team.players.length < 11) {
      return null; // not enough players
    }

    let goalKeeper: LineupPlayer | undefined;
    let otherPlayers: LineupPlayer[] = [];
    const startingPlayers = team.players.filter((player) => player.player_started);
    if (startingPlayers.length === 11) {
      goalKeeper = startingPlayers.find((player) => player.sub_position_acronym === 'GK');
      otherPlayers = startingPlayers.filter((player) => player.player_id !== goalKeeper?.player_id);
    } else {
      goalKeeper = team.players.find((player) => player.sub_position_acronym === 'GK');
      otherPlayers = team.players.filter((player) => player.player_id !== goalKeeper?.player_id);
    }

    if (goalKeeper) {
      grid[0].push(goalKeeper);
    }
    let offset = 0;
    for (let i = 1; i < rows.length; i++) {
      for (let j = 0; j < rows[i]; j++) {
        grid[i].push(otherPlayers[offset + j]);
      }
      offset += rows[i];
    }
    return grid;
  }

  return null;
}
