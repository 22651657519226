import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import { isLightColor } from 'utils/helpers';
import { getDefaultColor } from '../../../../helpers';
import Jersey from '../../Jersey';
import { ReportTypeHeader } from '../interfaces';

interface PlayerHeaderProps extends ReportTypeHeader {
  player: { team_color?: string; shirt_number?: number | string; player_name?: string };
  index?: number;
  highlight?: boolean;
}

function PlayerHeader({
  player,
  textSize = 'sm',
  iconSize = 'md',
  narrower = false,
  index = 0,
  highlight
}: PlayerHeaderProps) {
  const color = player.team_color ?? getDefaultColor(index);
  const isLight = isLightColor(color);
  return (
    <div
      className={twJoin(
        'flex flex-col items-center gap-3 rounded-xl px-3',
        narrower ? 'py-3' : 'py-6',
        highlight && `border-b-8 border-brand-800`
      )}
      style={{ backgroundColor: color }}
    >
      <Jersey color="white" shirt_number={player.shirt_number} size={iconSize} shadow outline={isLight} />
      <span
        className={twJoin(
          'text-center font-semibold',
          textSize === 'tiny' && 'text-tiny',
          textSize === 'sm' && 'text-sm',
          textSize === 'lg' && 'text-lg',
          isLight ? 'text-black' : 'text-white'
        )}
      >
        {player.player_name}
      </span>
    </div>
  );
}

export default memo(PlayerHeader);
