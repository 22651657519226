import { SubscriptionGetSchema } from 'lib/model';
import { useGetProjectProjectIdSubscription } from 'lib/subscription/subscription';
import { useMemo } from 'react';

interface SubscriptionStatus {
  expired: boolean;
  expirationDate: Date | null;
  expirationString: string;
}

export function useProjectSubscriptionExpiration(projectId: string) {
  const { data: projectSubscription, isPending } = useGetProjectProjectIdSubscription<SubscriptionGetSchema>(
    projectId!,
    {},
    {
      query: {
        queryKey: ['project', projectId, 'subscription'],
        staleTime: Infinity,
        enabled: !!projectId,
        refetchOnWindowFocus: false
      }
    }
  );

  const subscriptionStatus: SubscriptionStatus = useMemo(() => {
    if (!projectSubscription) {
      return { expirationDate: null, expirationString: 'N/A', expired: false };
    } else {
      if (!projectSubscription.id) {
        // SUBSCRIPTION EXPIRED
        return { expirationDate: null, expirationString: 'EXPIRED', expired: true };
      } else if (!projectSubscription.subscription_end) {
        // NO EXPIRATION DATE
        return { expirationDate: null, expirationString: 'N/A', expired: false };
      }

      const date = new Date(projectSubscription.subscription_end);
      const currentDate = new Date();
      const expired = date && currentDate > date;
      return {
        expirationDate: date,
        expirationString: expired ? 'EXPIRED' : date.toLocaleDateString(),
        expired: expired
      };
    }
  }, [projectSubscription]);

  return { ...subscriptionStatus, isPending };
}
