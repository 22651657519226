import { SelectInputVariantsProps } from './interfaces';
import SelectStateInput from './variants/SelectInputState';
import SelectFormInput from './variants/SelectInputForm';

function SelectInput(props: SelectInputVariantsProps) {
  switch (props.variant) {
    case 'form': {
      return <SelectFormInput {...props} />;
    }
    case 'state': {
      return <SelectStateInput {...props} />;
    }
    default:
      return null;
  }
}

export default SelectInput;
