import StatusEnum from 'constants/enums/status';
import SyncStatusEnum from 'constants/enums/syncStatus';
import { useGetDatasource } from 'lib/datasource/datasource';
import { ProjectConfigurationNestedSchema, ProjectDeploymentSchema } from 'lib/model';
import { useGetProjectProjectIdConfigurationProjectConfigurationId } from 'lib/project-configuration/project-configuration';
import {
  getProjectProjectIdDeploymentLatest,
  useGetProjectProjectIdDeploymentLatest
} from 'lib/project-deployment/project-deployment';
import { useGetProjectProjectId } from 'lib/project/project';
import { useMemo } from 'react';
import { getDeploymentStatus } from '../../../util';
import StatusIcon from '../../StatusIcon';
import { ProjectTableCell } from 'modules/admin/interfaces';

const StatusCell = ({ project }: ProjectTableCell) => {
  const { isPending: isProjectPending, data: projectData } = useGetProjectProjectId(project.id!, {
    query: {
      queryKey: ['project', project.id],
      enabled: !!project.id,
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  });
  const { data: dataSources } = useGetDatasource(undefined, {
    query: {
      queryKey: ['dataSources'],
      staleTime: Infinity
    }
  });
  // Project configuration
  const projectConfiguration =
    useGetProjectProjectIdConfigurationProjectConfigurationId<ProjectConfigurationNestedSchema>(
      project.id!,
      projectData?.project_configuration_latest ?? '',
      {},
      {
        query: {
          queryKey: ['project', project.id, 'configuration', projectData?.project_configuration_latest],
          staleTime: Infinity,
          enabled: !!project.id && !!projectData?.project_configuration_latest,
          refetchOnWindowFocus: false
        }
      }
    );

  const connectedDatasources = useMemo(() => {
    if (!dataSources?.objects || !projectConfiguration?.data?.datasource_configurations) {
      return [];
    }

    const projectDatasources = projectConfiguration.data.datasource_configurations;

    const connectedDatasources = dataSources.objects
      .filter((dataSource) =>
        projectDatasources.some((projectDataSource) => projectDataSource.datasource === dataSource.id)
      )
      .map((dataSource) => {
        const configuration = projectDatasources.find(
          (projectDataSource) => projectDataSource.datasource === dataSource.id
        )!;

        const syncDate = configuration.datasource_configuration_status?.stopped_at;
        return { ...dataSource, configuration: configuration, syncDate: syncDate };
      });

    return connectedDatasources ?? [];
  }, [dataSources, projectConfiguration]);

  // Project deployment data
  const { data: deployment } = useGetProjectProjectIdDeploymentLatest(project.id!, {
    query: {
      queryKey: ['project', project.id, 'deployment', 'latest'],
      staleTime: Infinity,
      queryFn: ({ signal }) => {
        return getProjectProjectIdDeploymentLatest(project.id!, {}, signal).catch((err) => {
          if (err.status === 404) {
            return {
              exit_status: StatusEnum.UNKNOWN
            } as ProjectDeploymentSchema;
          } else {
            throw err;
          }
        });
      }
    }
  });
  const deploymentStatus = useMemo(() => {
    if (!deployment) {
      return StatusEnum.UNKNOWN;
    }
    return getDeploymentStatus(deployment);
  }, [deployment]);

  const status = useMemo(() => {
    const statuses =
      connectedDatasources.map((dataSource) => dataSource.configuration.datasource_configuration_status!.status!) ?? [];

    switch (true) {
      case statuses.includes(SyncStatusEnum.FAILED) || deploymentStatus === StatusEnum.ERROR:
        return StatusEnum.ERROR;
      case statuses.includes(SyncStatusEnum.IN_PROGRESS) ||
        statuses.includes(SyncStatusEnum.DEPLOYING) ||
        deploymentStatus === StatusEnum.RUNNING:
        return StatusEnum.RUNNING;
      case statuses.includes(SyncStatusEnum.INACTIVE) || deploymentStatus === StatusEnum.UNKNOWN:
        return StatusEnum.UNKNOWN;
      case statuses.includes(SyncStatusEnum.SUCCESSFUL):
        return StatusEnum.SUCCESS;
      default:
        return StatusEnum.UNKNOWN;
    }
  }, [connectedDatasources, deploymentStatus]);

  return (
    <div className="flex justify-center mx-2">
      <StatusIcon status={status} />
    </div>
  );
};

export default StatusCell;
