import { ScoutNoteDisplayProps } from 'modules/scoutNotes/interfaces';
import ScoutNoteCard, { ScoutNoteCardSkeleton } from './ScoutNoteCard';

const DEFAULT_CARDS = 9;
const ScoutNotesGrid = ({ data, loading }: ScoutNoteDisplayProps) => {
  if (loading || !data) {
    return (
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {Array.from({ length: DEFAULT_CARDS }).map((_, index) => (
          <ScoutNoteCardSkeleton key={index} />
        ))}
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      {data.map((note) => (
        <ScoutNoteCard key={note.id} note={note} />
      ))}
    </div>
  );
};

export default ScoutNotesGrid;
