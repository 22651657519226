/* eslint-disable react/jsx-no-bind */
import { Menu, MenuButton, MenuItem, MenuItems, Tab, TabGroup, TabList, Transition } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef, Row, Table, createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import Info from 'assets/info.svg?react';
import Loader from 'assets/loading.svg?react';
import MenuContext from 'assets/menu-context.svg?react';
import Plus from 'assets/plus.svg?react';
import User from 'assets/user.svg?react';
import { AxiosError } from 'axios';
import { defaultPageIndex } from 'constants/tableDefaults';
import useAuth from 'contexts/auth/authContext';
import useActiveProject from 'contexts/project/projectContext';
import { ProjectRoleSchemaName, ProjectRolesSchema, ProjectUsersSchema, UserAccountRemoveSchema } from 'lib/model';
import { usePutProjectProjectIdUser } from 'lib/project-user/project-user';
import { usePostProjectProjectIdResendEmail, usePostProjectProjectIdRevokeEmail } from 'lib/project/project';
import { useGetReport } from 'lib/report/report';
import { usePutUserDeactivateUsers } from 'lib/user-account/user-account';
import { getLastLoginDateString } from 'modules/admin/util';
import Button from 'modules/common/Button';
import CircleImage from 'modules/common/CircleImage';
import PaginationWithCount from 'modules/common/PaginationWithCount';
import GenericTable from 'modules/common/Table/GenericTable';
import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { twJoin, twMerge } from 'tailwind-merge';
import { getUserInitials, snakeCaseToWords, stopPropagation } from 'utils/helpers';
import { UserRow } from 'utils/interfaces';
import { projectUserToUserRow } from 'utils/mappings';
import { defaultPageSize } from '../../../constants/tableDefaults';
import AccessLevelDialog from './dialogs/AccessLevelDialog';
import DeleteUsersDialog from './dialogs/DeleteUsersDialog';
import EditAccessLevelDialog from './dialogs/EditAccessLevelDialog';
import InviteUsersDialog from './dialogs/InviteUsersDialog';
import TransferOwnershipDialog from './dialogs/TransferOwnershipDialog';

function UserManagement() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { user, isOwner, isAdmin } = useAuth();
  if (!isOwner && !isAdmin) {
    throw new Error('403 Forbidden');
  }
  const { project } = useActiveProject();
  const projectUsers = queryClient.getQueryData<ProjectUsersSchema>(['project', project.id, 'users'])?.objects;
  const projectRoles = queryClient.getQueryData<ProjectRolesSchema>(['project', project.id, 'roles'])?.objects;
  const [userRows, setUserRows] = useState(projectUsers!.map((pu) => projectUserToUserRow(pu, projectRoles ?? [])));
  const userName = user?.first_name + ' ' + user?.last_name;
  const [searchParams, setSearchParams] = useSearchParams();
  const [inviteUsersOpen, setInviteUsersOpen] = useState(false);
  const [accessLevelsOpen, setAccessLevelsOpen] = useState(false);
  const [deleteUsersOpen, setDeleteUsersOpen] = useState(false);
  const [editAccessLevelsOpen, setEditAccessLevelsOpen] = useState(false);
  const [transferOwnershipOpen, setTransferOwnershipOpen] = useState(false);

  function goToTierPlans() {
    navigate('/settings/tier-plans');
  }

  const filterUsers = useCallback(
    function filterUsers(role: string) {
      setUserRows(
        projectUsers!
          .filter((u) => (role !== 'all' ? u.project_role_id === role : true))
          .map(
            (pu) =>
              ({
                id: pu.user_account_id,
                name: (pu.first_name ?? '-') + ' ' + (pu.last_name ?? ''),
                email: pu.email,
                invite_sent: pu.invite_timestamp ? pu.invite_timestamp!.split('T')[0] : '-',
                access_level: snakeCaseToWords(projectRoles!.find((x) => x.id === pu.project_role_id)!.name!),
                access_level_id: pu.project_role_id,
                initials: getUserInitials(pu),
                image: pu.user_image_path
              }) as UserRow
          )
      );
    },
    [projectRoles, projectUsers]
  );

  function inviteUsers() {
    setInviteUsersOpen(true);
  }

  function openAccessLevels() {
    setAccessLevelsOpen(true);
  }
  function editAccessLevels() {
    setEditAccessLevelsOpen(true);
  }
  function openDeleteUsers() {
    setDeleteUsersOpen(true);
  }
  function transferOwnership() {
    setTransferOwnershipOpen(true);
  }

  const refreshUsers = useCallback(
    function refreshUsers() {
      queryClient.invalidateQueries({ queryKey: ['project', project.id, 'users'] }).then(() => {
        setUserRows(
          queryClient.getQueryData<ProjectUsersSchema>(['project', project.id, 'users'])!.objects!.map(
            (pu) =>
              ({
                id: pu.user_account_id,
                name: (pu.first_name ?? '-') + ' ' + (pu.last_name ?? ''),
                email: pu.email,
                invite_sent: pu.invite_timestamp ? pu.invite_timestamp!.split('T')[0] : '-',
                access_level: snakeCaseToWords(projectRoles!.find((x) => x.id === pu.project_role_id)!.name!),
                access_level_id: pu.project_role_id,
                initials: getUserInitials(pu),
                image: pu.user_image_path
              }) as UserRow
          )
        );
      });
    },
    [projectRoles, queryClient]
  );

  const { mutate: changeAccessLevels } = usePutProjectProjectIdUser();
  function setNewAccessLevels(users: UserRow[]) {
    changeAccessLevels(
      {
        projectId: project!.id!,
        data: {
          objects: users.map((u) => ({ project_role_id: u.access_level_id!, user_id: u.id }))
        }
      },
      {
        onSuccess: () => {
          toast.success('User access levels changed succesfully.');
          setEditAccessLevelsOpen(false);
          refreshUsers();
        }
      }
    );
  }

  const { mutate: deleteUsers } = usePutUserDeactivateUsers();

  function deleteSelectedUsers(users: UserAccountRemoveSchema[]) {
    // TODO Add users to be deleted to the dialog
    deleteUsers(
      {
        data: users,
        params: { project_id: project!.id }
      },
      {
        onSuccess: () => {
          toast.success('Users successfully deleted.');
          queryClient.invalidateQueries({ queryKey: ['project', project.id, 'users'] });
          queryClient.invalidateQueries({ queryKey: ['reports'] });
          setDeleteUsersOpen(false);
          refreshUsers();
        },
        onError: (error: AxiosError) => {
          const data = error.response?.data as { error: string };
          if (data.error) {
            toast.error(data.error);
          }
        }
      }
    );
  }

  const { mutate: resendInvitation } = usePostProjectProjectIdResendEmail();
  const handleResendInvitation = useCallback(
    function handleResendInvitation(email: string) {
      resendInvitation(
        { projectId: project!.id!, email: email },
        {
          onSuccess: () => {
            toast.success('Invitation resent successfully.');
            refreshUsers();
          }
        }
      );
    },
    [project, resendInvitation, refreshUsers]
  );

  const { mutate: revokeInvitation } = usePostProjectProjectIdRevokeEmail();
  const handleRevokeInvitation = useCallback(
    function handleRevokeInvitation(email: string) {
      revokeInvitation(
        { projectId: project!.id!, email: email },
        {
          onSuccess: () => {
            toast.success('Invitation revoked successfully.');
            refreshUsers();
          }
        }
      );
    },
    [project, revokeInvitation, refreshUsers]
  );

  const editAccessLevelFromTable = useCallback(function editAccessLevelFromTable(
    row: Row<UserRow>,
    table: Table<UserRow>
  ) {
    table.setRowSelection(() => ({ [row.index]: true }));
    editAccessLevels();
  }, []);

  const removeUserFromTable = useCallback(function removeUserFromTable(row: Row<UserRow>, table: Table<UserRow>) {
    table.setRowSelection(() => ({ [row.index]: true }));
    openDeleteUsers();
  }, []);

  const columnHelper = createColumnHelper<UserRow>();
  const columns = useMemo<ColumnDef<UserRow>[]>(
    () => [
      columnHelper.display({
        id: 'select-col',
        header: ({ table }) => (
          <input
            type="checkbox"
            className={twJoin(
              'ml-6 mr-3 cursor-pointer text-brand-800 focus:ring-0',
              !table.getIsSomeRowsSelected() && 'hidden'
            )}
            checked={table.getIsAllRowsSelected()}
            ref={(input) => {
              if (input) {
                input.indeterminate = table.getIsSomeRowsSelected();
              }
            }}
            onChange={() => table.resetRowSelection(true)}
          />
        ),
        cell: ({ row }) => (
          <input
            type="checkbox"
            className="ml-6 mr-3 cursor-pointer text-brand-800 focus:ring-0"
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        )
      }),
      columnHelper.accessor('name', {
        id: 'name',
        cell: (info) => (
          <div className="mx-3 ml-6 flex items-center gap-2">
            <CircleImage image={info.row.original.image} text={info.row.original.initials!} size="size-5" />
            <span className={twMerge('text-xs font-medium', !info.row.original.id && 'text-gray-500')}>
              {info.renderValue()}
            </span>
          </div>
        ),
        header: () => (
          <span className="mx-3 ml-6 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">USER</span>
        ),
        meta: 'w-[20%]'
      }),
      columnHelper.accessor('email', {
        id: 'email',
        cell: (info) => (
          <span className={twMerge('mx-3 ml-6 text-xs font-medium', !info.row.original.id && 'text-gray-500')}>
            {info.renderValue()}
          </span>
        ),
        header: () => (
          <span className="mx-3 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">EMAIL</span>
        ),
        meta: 'w-[50%]'
      }),
      columnHelper.accessor('invite_sent', {
        cell: (info) => {
          const data = info.row.original;
          let date = '-';
          if (data.invite_sent && data.invite_sent !== '-') {
            date = new Date(data.invite_sent).toLocaleDateString();
          }

          return <span className={twMerge('mx-3 ml-6 text-xs font-medium', !data.id && 'text-gray-500')}>{date}</span>;
        },
        header: () => (
          <span className="mx-3 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">INVITE SENT</span>
        ),
        meta: 'w-[10%]'
      }),
      columnHelper.accessor('last_login', {
        id: 'last_login',
        cell: ({ row, getValue }) => {
          let lastLogin = getValue();
          if (lastLogin && lastLogin !== '-') {
            lastLogin = getLastLoginDateString(lastLogin);
          }

          return (
            <span className={twMerge('mx-3 ml-6 text-xs font-medium', !row.original.id && 'text-gray-500')}>
              {lastLogin}
            </span>
          );
        },
        header: () => (
          <span className="mx-3 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">LAST LOGIN</span>
        )
      }),
      columnHelper.accessor('access_level', {
        id: 'access_level',
        cell: (info) => (
          <span
            className={twMerge(
              'mx-3 flex items-center justify-end gap-1 text-xs font-medium',
              !info.row.original.id && 'text-gray-500'
            )}
          >
            {info.row.original.id ? info.renderValue() : 'Invitation sent'}
          </span>
        ),
        header: () => (
          <span className="mx-3 flex justify-end gap-2 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">
            ACCESS LEVEL <Info className="size-4 cursor-pointer fill-brand-800" onClick={openAccessLevels} />
          </span>
        ),
        meta: 'text-end w-[15%]'
      }),
      columnHelper.display({
        id: 'action',
        cell: ({ row, table }) => (
          <Menu as={'div'} className="relative mx-3 mr-6 flex h-6 items-center text-left">
            <MenuButton onClick={stopPropagation} className="rounded-md p-1 hover:bg-gray-200">
              <MenuContext width={24} height={24} className="fill-gray-700" />
            </MenuButton>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems
                anchor="bottom end"
                className="absolute z-50 mt-2 size-fit rounded-md bg-white py-3 shadow-card focus:outline-none"
              >
                {row.original.id ? (
                  <>
                    <MenuItem>
                      <button
                        className="flex w-full px-4 py-2 text-sm ui-active:bg-gray-50"
                        onClick={() => editAccessLevelFromTable(row, table)}
                      >
                        Edit access level
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <button
                        className="flex w-full px-4 py-2 text-sm ui-active:bg-gray-50"
                        onClick={() => removeUserFromTable(row, table)}
                      >
                        Remove from Q-ant
                      </button>
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem>
                      <button
                        className="flex w-full px-4 py-2 text-sm ui-active:bg-gray-50"
                        onClick={() => handleRevokeInvitation(row.original.email!)}
                      >
                        Revoke invitation
                      </button>
                    </MenuItem>
                    <MenuItem>
                      <button
                        className="flex w-full px-4 py-2 text-sm ui-active:bg-gray-50"
                        onClick={() => handleResendInvitation(row.original.email!)}
                      >
                        Resend invitation
                      </button>
                    </MenuItem>
                  </>
                )}
              </MenuItems>
            </Transition>
          </Menu>
        )
      })
    ],
    [columnHelper, editAccessLevelFromTable, removeUserFromTable, handleResendInvitation, handleRevokeInvitation]
  );

  const pagination = {
    pageIndex: Number(searchParams.get('pageIndex') ?? defaultPageIndex),
    pageSize: Number(searchParams.get('pageSize') ?? defaultPageSize)
  };

  const table = useReactTable({
    data: userRows,
    columns,
    rowCount: userRows.length,
    state: {
      pagination
    },
    onPaginationChange: (updater) => {
      let newState;
      if (updater instanceof Function) {
        newState = updater(pagination);
      } else {
        newState = updater;
      }
      searchParams.set('pageIndex', String(newState.pageIndex));
      searchParams.set('pageSize', String(newState.pageSize));
      setSearchParams(searchParams);
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    renderFallbackValue: '-'
  });

  const { data: reports, isFetching: reportsFetching } = useGetReport(
    { sharing_type: 'my_reports' },
    { query: { queryKey: ['currentUserReports'], refetchOnWindowFocus: false } }
  );

  const hasReports = Boolean(reports?.objects && reports.objects.length > 0);

  return (
    <section className="flex flex-col justify-around gap-6">
      <InviteUsersDialog open={inviteUsersOpen} setOpen={setInviteUsersOpen} refreshUsers={refreshUsers} />

      <AccessLevelDialog open={accessLevelsOpen} setOpen={setAccessLevelsOpen} />

      <EditAccessLevelDialog
        table={table}
        open={editAccessLevelsOpen}
        setOpen={setEditAccessLevelsOpen}
        projectRoles={projectRoles!}
        setNewAccessLevels={setNewAccessLevels}
      />

      <DeleteUsersDialog
        table={table}
        deleteUsers={deleteSelectedUsers}
        open={deleteUsersOpen}
        setOpen={setDeleteUsersOpen}
      />

      <TransferOwnershipDialog
        hasReports={hasReports}
        open={transferOwnershipOpen}
        setOpen={setTransferOwnershipOpen}
      />

      <TabGroup className="flex h-16 items-center bg-brand-50 px-4" defaultIndex={0}>
        <TabList className="flex gap-2">
          <Tab
            className="px-2 py-5 text-xs font-semibold uppercase text-gray-600 data-[selected]:border-b-2 data-[selected]:border-brand-800 data-[selected]:text-brand-800"
            onClick={() => filterUsers('all')}
          >
            All
          </Tab>
          {projectRoles!
            .toReversed()
            .filter((x) => x.name !== ProjectRoleSchemaName.owner)
            .map((role) => (
              <Tab
                key={role.id}
                className="px-2 py-5 text-xs font-semibold uppercase text-gray-600 data-[selected]:border-b-2 data-[selected]:border-brand-800 data-[selected]:text-brand-800"
                onClick={() => filterUsers(role.id!)}
              >
                {snakeCaseToWords(role.name!)}
              </Tab>
            ))}
        </TabList>
      </TabGroup>

      <div className="flex flex-wrap justify-between gap-2 overflow-x-auto px-6">
        <span className="my-auto text-sm font-medium">Users</span>
        <div className="flex justify-between gap-4">
          <Button
            variant="secondary"
            size="sm"
            disabled={table.getSelectedRowModel().flatRows.length < 1}
            onClick={openDeleteUsers}
          >
            Remove from Q-ant
          </Button>
          <Button
            variant="secondary"
            size="sm"
            disabled={table.getSelectedRowModel().flatRows.length < 1}
            onClick={editAccessLevels}
          >
            Edit Access Level
          </Button>
          <Button variant="secondary" size="sm" onClick={goToTierPlans}>
            Buy more seats
          </Button>
          <Button variant="primary" size="sm" onClick={inviteUsers}>
            <Plus className="size-4 fill-white" /> Invite Users
          </Button>
        </div>
      </div>
      {isOwner && (
        <div className="mx-6 flex h-14 items-center justify-between rounded-md border border-gray-300 pl-3 pr-4">
          <div className="flex w-[28%] gap-3">
            <CircleImage
              image={user.user_image_path}
              text={user!.first_name!.charAt(0) + user!.last_name!.charAt(0)}
              size="size-5"
            />
            <span className="text-xs font-medium">{userName}</span>
          </div>
          <span className="w-4/5 text-sm font-medium">{user?.email}</span>
          <div className="mr-3 flex w-[15%] justify-end text-sm font-medium">
            <span>{snakeCaseToWords(user!.user_projects![0].project_role_name!)}</span>
          </div>
          {reportsFetching ? (
            <Loader aria-hidden="true" className="size-5 animate-spin fill-gray-700" />
          ) : (
            <Menu as={'div'} className="relative flex h-6 items-center">
              <MenuButton onClick={stopPropagation} className="rounded-md p-1 hover:bg-gray-200">
                <MenuContext width={24} height={24} className="fill-gray-700" />
              </MenuButton>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems
                  anchor="bottom end"
                  className="absolute z-50 mt-2 size-fit rounded-md bg-white py-3 shadow-card focus:outline-none"
                >
                  <MenuItem>
                    <button className="flex w-full px-4 py-2 text-sm ui-active:bg-gray-50" onClick={transferOwnership}>
                      Transfer ownership
                    </button>
                  </MenuItem>
                </MenuItems>
              </Transition>
            </Menu>
          )}
        </div>
      )}
      {userRows.length > 0 ? (
        <>
          <GenericTable table={table} />
          <PaginationWithCount rowCount={userRows.length} />
        </>
      ) : (
        <div className="mx-6 mb-6 flex items-center justify-center rounded-xl bg-gray-50 py-16">
          <div className="flex flex-col items-center gap-3">
            <User className="size-16 fill-gray-300" />
            <span className="text-sm font-medium text-gray-500">No users</span>
          </div>
        </div>
      )}
    </section>
  );
}

export default memo(UserManagement);
