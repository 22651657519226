import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import Loader from 'assets/loading.svg?react';
import MenuContext from 'assets/menu-context.svg?react';
import { DatasourceConfigurationNestedSchema, DatasourceSchema } from 'lib/model';
import { Fragment, memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeactivateDataSourceDialog from './DeactivateDialog';
import RemoveDataSourceDialog from './RemoveDataSourceDialog';
import ResyncDataSourceDialog from './ResyncDataSourceDialog';

interface ConnectedDataSourceDropdownProps {
  dataSource: DatasourceSchema;
  dataSourceConfiguration: DatasourceConfigurationNestedSchema;
}

const DataSourceConfigurationDropdown = memo(function ConnectedDataSourceDropdown({
  dataSource,
  dataSourceConfiguration
}: ConnectedDataSourceDropdownProps) {
  // NOTE: Should we replace booleans with enum state for better readability
  // Requires refactoring DialogProps interface
  const [showDeactivate, setShowDeactivate] = useState<boolean>(false);
  const [showRemove, setShowRemove] = useState<boolean>(false);
  const [showReSync, setShowReSync] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function handleEdit() {
    navigate('/data-sources/edit');
  }

  function handleDeactivate() {
    setShowDeactivate(true);
  }

  function handleRemove() {
    setShowRemove(true);
  }

  function handleReSync() {
    setShowReSync(true);
  }

  function setLoadingStatus(status: boolean) {
    setLoading(status);
  }

  return (
    <>
      <RemoveDataSourceDialog
        dataSource={dataSource}
        dataSourceConfiguration={dataSourceConfiguration}
        open={showRemove}
        setOpen={setShowRemove}
      />
      <DeactivateDataSourceDialog
        dataSource={dataSource}
        dataSourceConfiguration={dataSourceConfiguration}
        open={showDeactivate}
        setOpen={setShowDeactivate}
        setLoadingStatus={setLoadingStatus}
      />
      <ResyncDataSourceDialog
        dataSource={dataSource}
        dataSourceConfiguration={dataSourceConfiguration}
        open={showReSync}
        setOpen={setShowReSync}
      />
      <Menu as={'div'} className="flex items-center justify-center text-left">
        <MenuButton className="inline p-1" disabled={loading}>
          {loading ? (
            <Loader className="size-7 animate-spin fill-brand-800" />
          ) : (
            <MenuContext className="size-6 fill-gray-700" />
          )}
        </MenuButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems
            anchor="left start"
            className="z-10 w-40 origin-top-right rounded-md bg-white py-3 shadow-card focus:outline-none"
          >
            {dataSourceConfiguration.is_active ? (
              <>
                <MenuItem>
                  <button className="flex w-full px-4 py-2 text-sm ui-active:bg-gray-50" onClick={handleEdit}>
                    Edit
                  </button>
                </MenuItem>
                <MenuItem>
                  <button className="flex w-full px-4 py-2 text-sm ui-active:bg-gray-50" onClick={handleDeactivate}>
                    Deactivate
                  </button>
                </MenuItem>
                <MenuItem>
                  <button className="flex w-full px-4 py-1.5 text-sm ui-active:bg-gray-50" onClick={handleRemove}>
                    Remove
                  </button>
                </MenuItem>
                <MenuItem>
                  <button
                    className="flex w-full px-4 py-1.5 text-left text-sm ui-active:bg-gray-50"
                    onClick={handleReSync}
                  >
                    Re-sync
                  </button>
                </MenuItem>
              </>
            ) : (
              <MenuItem>
                <button className="flex w-full px-4 py-1.5 text-sm ui-active:bg-gray-50" onClick={handleRemove}>
                  Remove
                </button>
              </MenuItem>
            )}
          </MenuItems>
        </Transition>
      </Menu>
    </>
  );
});

export default DataSourceConfigurationDropdown;
