import Spinner from 'modules/common/Spinner';
import { twMerge } from 'tailwind-merge';

interface Props {
  currentCost: number;
  previousCost: number;
  loading?: boolean;
}
function AwsCosts({ currentCost, previousCost, loading }: Props) {
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {!isNaN(currentCost) ? (
        <div
          className={twMerge(
            'flex justify-between gap-2',
            currentCost > previousCost && 'text-orange-400',
            currentCost > previousCost * 2 && 'text-red-600'
          )}
        >
          Current: <b className="font-semibold">{currentCost.toFixed(2)} $</b>
        </div>
      ) : (
        'N/A'
      )}
      {!isNaN(previousCost) ? (
        <div className="flex justify-between gap-2 text-gray-500">
          Prev: <b className="font-semibold">{previousCost.toFixed(2)} $</b>
        </div>
      ) : (
        'N/A'
      )}
    </>
  );
}

export default AwsCosts;
