import { useIsFetching } from '@tanstack/react-query';
import { CellContext } from '@tanstack/react-table';
import { ProjectAwsCost } from 'lib/model';
import { AdminProjectSchema } from 'modules/admin/interfaces';
import LoadingCell from 'modules/common/Table/cells/LoadingCell';
import AwsCosts from '../../AwsCosts';

function AwsCostCell({ getValue }: CellContext<AdminProjectSchema, ProjectAwsCost | undefined>) {
  const isFetchingCost = useIsFetching({ queryKey: ['admin', 'projects', 'aws-cost'], exact: true });
  if (isFetchingCost) return <LoadingCell />;

  const value = getValue();
  if (!value) {
    <div className="mx-2 flex flex-col text-xs">UNKNOWN</div>;
  } else {
    const awsCost = value.aws_cost!;
    const currentMonthCost = Number(awsCost.current_month_cost_USD);
    const lastMonthCosts = Number(awsCost.past_month_cost_USD);

    return (
      <div className="mx-2 flex flex-col text-xs">
        <AwsCosts currentCost={currentMonthCost} previousCost={lastMonthCosts} />
      </div>
    );
  }
}

export default AwsCostCell;
