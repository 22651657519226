import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef, createColumnHelper, getCoreRowModel, Table, useReactTable } from '@tanstack/react-table';
import { ClientDataSchemaObjectsItem, DataTablesSchema, ProjectDataColumn, ProjectSchema } from 'lib/model';
import { usePostProjectProjectIdDataTableDataTableIdUserCorrection } from 'lib/user-correction/user-correction';
import Button from 'modules/common/Button';
import DialogBase from 'modules/common/Dialog/DialogBase';
import DialogContent from 'modules/common/Dialog/DialogContent';
import DialogFooter from 'modules/common/Dialog/DialogFooter';
import GenericDisplayTable from 'modules/common/Table/GenericDisplayTable';
import { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DialogProps } from 'utils/interfaces';
import OptionalNotes from './OptionalNotes';
import useActiveProject from 'contexts/project/projectContext';

interface DuplicatesFormValues {
  note: string | undefined;
}

interface DuplicatesDialogProps extends DialogProps {
  table: Table<ClientDataSchemaObjectsItem>;
  dataTableColumns: ProjectDataColumn[];
}

const columnHelper = createColumnHelper<ClientDataSchemaObjectsItem>();

const DuplicatesDialog = memo(function DuplicatesDialog({
  table,
  open,
  setOpen,
  dataTableColumns
}: DuplicatesDialogProps) {
  const { dataTableId } = useParams();
  const queryClient = useQueryClient();

  const { project } = useActiveProject();
  const dataTables = queryClient.getQueryData<DataTablesSchema>(['project', project.id, 'data-tables'])!;
  const dataTable = dataTables.objects!.find((dt) => dt.id! === dataTableId)!;

  const duplicates = table.getSelectedRowModel().flatRows.map((row) => row.original);
  const idColumns = dataTableColumns.filter((x) => x.identity_column);
  const idKey = duplicates.map((dup) =>
    Object.fromEntries(
      Object.entries(dup).filter(([key]) => idColumns.find((x) => x.column_name! === key) !== undefined)
    )
  );
  const columns = useMemo<ColumnDef<ClientDataSchemaObjectsItem>[]>(
    () =>
      dataTableColumns
        .filter((x) => x.visible)
        .map((col) =>
          columnHelper.accessor(col.column_name!, {
            id: col.column_name!,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            cell: (info) => <span className="px-3 text-xs font-medium">{info.renderValue() as any}</span>,
            header: () => (
              <span className="whitespace-nowrap px-3 text-tiny font-medium uppercase text-gray-500">
                {col.display_name}
              </span>
            )
          })
        ),
    [dataTableColumns]
  );

  const { mutate: createUserCorrection } = usePostProjectProjectIdDataTableDataTableIdUserCorrection();
  const { handleSubmit, register } = useForm<DuplicatesFormValues>();

  const reportDuplicateData = useCallback(
    function reportDuplicateData(data: DuplicatesFormValues) {
      createUserCorrection({
        projectId: project.id!,
        dataTableId: dataTableId!,
        data: {
          objects: [
            {
              comment: data.note || undefined,
              correction_type: 'duplicates',
              suggestion: {
                id: idKey
              }
            }
          ]
        }
      });
      table.resetRowSelection();
      toast.success('Report “duplicate data” sent successfully');
      setOpen(false);
    },
    [createUserCorrection, dataTableId, project.id, setOpen, table, idKey]
  );

  const handleCancel = useCallback(
    function handleCancel() {
      setOpen(false);
    },
    [setOpen]
  );

  const displayTable = useReactTable({
    data: duplicates,
    columns,
    getCoreRowModel: getCoreRowModel(),
    renderFallbackValue: '-'
  });

  return (
    <DialogBase title="Report Duplicates" onCancel={handleCancel} open={open} wider>
      <DialogContent>
        {/* TODO: add data table subcategory when available */}
        <div className="flex flex-col gap-6">
          <span className="text-xs font-semibold uppercase">{dataTable.name}</span>
          <div className="flex flex-col gap-3">
            <span className="text-sm">
              <span className="font-bold">{`${duplicates.length} items`}</span> selected
            </span>
            <GenericDisplayTable table={displayTable} />
          </div>
        </div>
        <form id="duplicates-form" onSubmit={handleSubmit(reportDuplicateData)}>
          <OptionalNotes register={register} />
        </form>
        <p className="text-sm">
          You're about to report potential incorrect values on this data table.
          <br />
          Our team will carefully review the data and resolve the issue promptly. Thank you for your valuable
          assistance.
        </p>
      </DialogContent>
      <DialogFooter>
        <Button variant="secondary" size="xl" onClick={handleCancel}>
          <span>Cancel</span>
        </Button>
        <Button size="xl" isSubmitButton form="duplicates-form">
          <span>Send Report</span>
        </Button>
      </DialogFooter>
    </DialogBase>
  );
});

export default DuplicatesDialog;
